import React from 'react';

import { Box } from '@mui/material';

interface ITabPanel {
  children: React.ReactNode;
  value: number | string;
  index: number;
}

export default function TabPanel({ value, index, children }: ITabPanel) {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      sx={{
        width: '80%',
        backgroundColor: '#fafafa',
        position: 'relative',
        left: '20%',
      }}
    >
      {value === index && (
        <Box sx={{ p: 2, maxWidth: '60rem' }}>{children}</Box>
      )}
    </Box>
  );
}
