import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  FormHelperText,
} from '@mui/material';

import colors from '../../assets/colors';
import { IQuestion } from '../../entities/SAQ';
import { getAnswer } from '../../pages/SAQOverview/utils';
import { useSAQ } from '../../stores/useSAQ';
import { changeBoolean } from '../../utils/onChangeFunctions';
import UploadButtonLayout from '../UploadButtonLayout';
import UploadedDocumentsSAQ from '../UploadedDocumentsSAQ';
import UploadNewDocumentsModal from '../UploadNewDocumentsModal';

interface IQuestionYesOrNo {
  children: React.ReactNode;
  translation?: string[];
  questionIndex: number;
  showUpload: boolean;
  isDocumentRequired: boolean;
  isRequired: boolean;
  showError: boolean;
  question: IQuestion;
  currentSection: number;
  noDivider?: boolean;
}

function QuestionYesOrNo({
  children,
  translation,
  questionIndex,
  showUpload,
  isDocumentRequired,
  isRequired,
  showError,
  question,
  currentSection,
  noDivider,
}: IQuestionYesOrNo) {
  const { saq, setSAQ } = useSAQ();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const { t } = useTranslation();

  const value = question.answers[0]?.boolean_answer;
  const questionId = question.id;
  const isSub = question.is_sub;

  const hasError =
    isRequired &&
    !getAnswer(saq.sections[currentSection].questions[questionIndex]) &&
    showError;
  const hasDocumentError =
    showUpload &&
    isDocumentRequired &&
    showError &&
    !saq.sections[currentSection].questions[questionIndex].documents?.length;

  const changeAnswer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tempSaq = changeBoolean(
      questionIndex,
      questionId,
      e.target.value,
      saq,
      currentSection,
    );
    setSAQ(tempSaq);
  };

  return (
    <>
      <Box>
        <Typography
          variant="question"
          fontWeight={isSub ? 300 : 600}
          py={1}
          className={hasError ? 'error' : ''}
        >
          {children}
          {isRequired && '*'}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <RadioGroup
            name={`yes-no-group${questionId}`}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              color: hasError ? colors.error : 'inherit',
            }}
            onChange={changeAnswer}
          >
            <FormControlLabel
              value="true"
              control={
                <Radio
                  required={isRequired}
                  className={hasError ? 'error' : ''}
                />
              }
              label={translation && translation[0]}
              checked={value}
            />
            <FormControlLabel
              value="false"
              control={
                <Radio
                  required={isRequired}
                  className={hasError ? 'error' : ''}
                />
              }
              label={translation && translation[1]}
              checked={value === false}
            />
          </RadioGroup>
          {showUpload && (
            <UploadButtonLayout
              label={t('certificate') + (isDocumentRequired ? '*' : '')}
              onClick={() => setShowUploadModal(true)}
              error={hasDocumentError}
            />
          )}
        </Box>
        {hasError && <FormHelperText error>{t('required')}</FormHelperText>}
        {hasDocumentError && (
          <FormHelperText error>{t('requiredDocument')}</FormHelperText>
        )}
        {showUpload && (
          <UploadedDocumentsSAQ
            currentSection={currentSection}
            questionIndex={questionIndex}
          />
        )}
      </Box>

      {!noDivider && <Divider sx={{ margin: 2 }} />}

      <UploadNewDocumentsModal
        open={showUploadModal}
        setOpen={setShowUploadModal}
        questionId={questionId}
        currentSection={currentSection}
      />
    </>
  );
}

export default QuestionYesOrNo;
