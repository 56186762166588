import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';

import colors from '../../assets/colors';
import { IQuestion, ISAQSections } from '../../entities/SAQ';
import { useSaveAnswers } from '../../services/saq';
import { useSAQ } from '../../stores/useSAQ';
import QuestionSwitch from '../QuestionSwitch';

interface IQuestionWithComment {
  section: ISAQSections;
  currentSection: number;
  question: IQuestion;
  questionIndex: number;
  notAnswered: boolean;
}

export default function QuestionWithComment({
  section,
  currentSection,
  question,
  questionIndex,
  notAnswered,
}: IQuestionWithComment) {
  const { t } = useTranslation();
  const { saq, setSAQ } = useSAQ();
  const { mutate: mutateAnswers } = useSaveAnswers({
    onSuccess: () => {
      const tempSAQ = { ...saq };
      const comment =
        tempSAQ.sections[currentSection].questions[questionIndex].comments[0];
      if (comment.fake) {
        tempSAQ.sections[currentSection].questions[questionIndex].comments = [];
      } else {
        comment.resolved = true;
      }
      setSAQ(tempSAQ);
    },
  });

  const saveAnswer = () => {
    mutateAnswers({
      answers: [question.answers[0]],
    });
  };

  return (
    <Box border={`1px solid ${colors.error}`}>
      <Box p={2} sx={{ backgroundColor: colors.greyRow }}>
        <Typography variant="subtitle1" color={colors.error}>
          {question.comments[0]?.comment}
        </Typography>
      </Box>
      <Box p={2}>
        <QuestionSwitch
          question={question}
          questionIndex={questionIndex}
          section={section}
          doCheck={false}
          currentSection={currentSection}
          noDivider
        />
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            variant="outlined"
            onClick={saveAnswer}
            disabled={notAnswered}
          >
            {t('done')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
