import { ITranslate } from '..';

const FR_FR: ITranslate = {
  profile: 'Profil',
  productList: 'Liste de produits',
  home: 'Home',
  notifications: 'Avis',
  Documents: 'Documents',
  Suppliers: 'Fournisseurs',
  products: 'Des produits',
  settings: 'Réglages',
  logout: 'Log Out',
  start: 'Démarrer',
  continue: 'Continuer',
  review: 'Révision',
  view: 'Visualiser',
  defaultSAQText: `Démarrer un nouveau Questionnaire d'Évaluation du Fournisseur.`,
  incompleteSAQText: `Complétez votre Questionnaire d'Évaluation du Fournisseur.`,
  pendingSAQText: `Une ou plusieurs questions attendent d'être examinées.`,
  awaitingApprovalSAQText: `Votre {{ saqCategoryName }} SAQ est en attente d'approbation.`,
  categoryHasBeen: 'Votre {{ saqCategoryName }} SAQ a été',
  approved: 'APPROUVÉE',
  rejected: 'REJETÉE',
  next: 'Suivant',
  back: 'Précédent',
  tryAgain: 'Réessayer',
  save: 'Sauvegarder',
  progressSaved: 'Progression enregistrée',
  submit: 'Soumettre',
  section: 'Section',
  of: 'de',
  listEmpty: 'Liste vide',
  categoryText1:
    'Veuillez répondre aux questions ci-dessous et soumettre votre candidature',
  categoryText2: 'Sélectionnez le type de produit que vous vendez',
  categoryText3: 'Type de fournisseur',
  completeFormText:
    'Veuillez remplir le formulaire de spécifications du produit',
  certificate: 'télécharger',
  fileNotAccepted:
    "Le type de document que vous avez téléchargé n'est pas pris en charge. Veuillez réessayer et assurez-vous que le type de document fait partie de la liste prise en charge.",
  fileTooBig:
    'La taille du document que vous avez téléchargé est trop grande. Veuillez réessayer et assurez-vous que la taille du document est acceptable.',
  errorTitle: "Quelque chose s'est mal passé",
  supplierDetails: 'Détails du fournisseur',
  technicalContact: 'Contact de l’entreprise',
  productRecallWithdrawalContact: 'Contact pour le rappel/retrait de produits',
  supplierName: 'Nom de l’entreprise',
  siteAddress: 'Adresse de l’entreprise',
  email: 'Email de l’entreprise',
  sitePhoneNumber: 'Numéro de téléphone du site',
  name: 'Nom',
  contactNumber: 'Numéro de téléphone',
  outOfHoursContactNumber:
    'Numéro et nom de la personne à contacter en dehors des heures de travail',
  required: 'Ce champ est requis',
  requiredDocument: 'Le document est requis',
  unansweredQuestions: 'Vous avez des questions sans réponse',
  reviewAnswers: 'Veuillez vérifier vos réponses avant de soumettre la demande',
  details: 'détails',
  questions: 'des questions',
  edit: 'Éditer',
  tryLater: 'Veuillez réessayer plus tard',
  uploadNewDocument: 'Télécharger un nouveau document',
  uploadModalInfo:
    'Veuillez sélectionner le type de document que vous souhaitez télécharger avec les informations supplémentaires.',
  documentName: 'Nom du document',
  documentType: 'Type de document',
  uploadedDate: 'Date de téléchargement',
  expiryDate: "Date d'expiration",
  noExpiryDate: "Pas de date d'expiration",
  cancel: 'annuler',
  supplierAssessmentQuestionnaire:
    "Questionnaire d'auto évaluation fournisseur",
  saq: 'SAQ',
  uploadedDocumentation: 'Documentation téléchargée',
  notBiggerThan: "Assurez-vous que le fichier n'est pas plus grand que",
  formatsAre: 'Les formats acceptés sont',
  completeSAQ: 'Complétez votre SAQ',
  continueSAQInfo:
    'Veuillez continuer/soumettre pour remplir le questionnaire.',
  completedSAQ: 'SAQ complété',
  completedSAQInfo:
    'Ce SAQ est terminé. Le statut est {{status}} et il a commencé le {{date_started}} vous pouvez revoir vos réponses ci-dessous mais vous ne pouvez apporter aucune modification',
  position: 'Nom et poste occupé',
  add: 'Ajouter',
  edited: 'Édité',
  reviewText: 'Vérifiez votre SAQ',
  reviewComments:
    'Veuillez vérifier les questions marquées en rouge. Une fois terminé, veuillez soumettre la SAQ.',
  done: 'Fini',
  submitted: 'Votre SAQ a été mis à jour et soumis pour approbation',
  ok: 'Ok',
  completeYourQuestionnaire: `Complétez votre SAQ`,
  incompleteText: `Vous devez encore terminer et/ou soumettre votre SAQ.`,
  rejectedText: 'Cette SAQ a été rejetée le {{date_started}}',
  approvedText:
    'Cette SAQ a été approuvée le {{date_started}}. Vous pouvez vérifier vos réponses ci-dessous',
  awaitingApproval: `En attente d'approbation.`,
  awaitingApprovalText:
    'Cette SAQ a été soumise. Vous pouvez vérifier vos réponses ci-dessous, mais vous ne pouvez apporter aucune modification.',
};

export default FR_FR;
