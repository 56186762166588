import { useTranslation } from 'react-i18next';

import * as yup from 'yup';

const numberRegex = /^[0-9+\-\s()]*$/;

export const suppliersDetailsValidationSchema = yup.object().shape({
  // Supplier Details
  general_name: yup
    .string()
    .max(60, 'Max length is 60 characters.')
    .required('Required'),
  general_address: yup
    .string()
    .max(150, 'Max length is 150 characters.')
    .required('Required'),
  general_email: yup
    .string()
    .max(320, 'Max length is 320 characters.')
    .email('Please enter a valid email address.')
    .required('Required'),
  general_phone_number: yup
    .string()
    .max(60, 'Max length is 60 characters.')
    .matches(numberRegex, 'Only numbers allowed')
    .required('Required'),
  // Technical Contact
  technical_name: yup
    .string()
    .max(60, 'Max length is 60 characters.')
    .required('Required'),
  technical_phone_number: yup
    .string()
    .max(60, 'Max length is 60 characters.')
    .matches(numberRegex, 'Only numbers allowed')
    .required('Required'),
  technical_email: yup
    .string()
    .max(320, 'Max length is 320 characters.')
    .email('Please enter a valid email address.')
    .required('Required'),
  // Product Recall/Withdrawal Contact
  product_recall_name: yup
    .string()
    .max(60, 'Max length is 60 characters.')
    .required('Required'),
  product_recall_phone_number: yup
    .string()
    .max(60, 'Max length is 60 characters.')
    .matches(numberRegex, 'Only numbers allowed')
    .required('Required'),
  product_recall_email: yup
    .string()
    .max(320, 'Max length is 320 characters.')
    .email('Please enter a valid email address.')
    .required('Required'),
  product_recall_ooh_number: yup
    .string()
    .max(60, 'Max length is 60 characters.')
    .matches(numberRegex, 'Only numbers allowed')
    .required('Required'),
});

export const useGetSections = () => {
  const { t } = useTranslation();

  const sections = [
    {
      title: t('supplierDetails'),
      questions: [
        {
          id: 'sd1',
          name: 'general_name',
          label: t('supplierName'),
        },
        {
          id: 'sd2',
          name: 'general_address',
          label: t('siteAddress'),
        },
        {
          id: 'sd3',
          name: 'general_email',
          label: t('email'),
        },
        {
          id: 'sd4',
          name: 'general_phone_number',
          label: t('sitePhoneNumber'),
        },
      ],
    },
    {
      title: t('technicalContact'),
      questions: [
        {
          id: 'tc1',
          name: 'technical_name',
          label: t('name'),
        },
        {
          id: 'tc2',
          name: 'technical_phone_number',
          label: t('contactNumber'),
        },
        {
          id: 'tc3',
          name: 'technical_email',
          label: t('email'),
        },
      ],
    },
    {
      title: t('productRecallWithdrawalContact'),
      questions: [
        {
          id: 'pdwc1',
          name: 'product_recall_name',
          label: t('name'),
        },
        {
          id: 'pdwc2',
          name: 'product_recall_phone_number',
          label: t('contactNumber'),
        },
        {
          id: 'pdwc3',
          name: 'product_recall_email',
          label: t('email'),
        },
        {
          id: 'pdwc4',
          name: 'product_recall_ooh_number',
          label: t('outOfHoursContactNumber'),
        },
      ],
    },
  ];
  return sections;
};
