import jwtDecode from 'jwt-decode';
import moment from 'moment';

import { IAuth } from '../../entities/Auth';

type IToken = {
  exp: number;
};

export const checkAuth = (auth: IAuth) => {
  if (auth !== null && auth.access_token) {
    const token = jwtDecode<IToken>(auth.access_token);
    const exp = moment(token.exp * 1000);
    if (exp.isAfter(moment())) {
      return true;
    }
  }
  return false;
};
