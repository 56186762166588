import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { ICategories } from '../entities/Categories';

interface ITypesState {
  types: ICategories;
  setTypes: (data: ICategories) => void;
}

const storeName = 'supplierTypesStore';
const initialTypes: ICategories = [
  {
    id: -1,
    translations: [{ name: '' }],
  },
];

export const useSupplierTypes = create<ITypesState>()(
  devtools(
    (set) => ({
      types: initialTypes,
      setTypes: (types) => {
        set((state) => ({ ...state, types }));
      },
    }),
    { name: storeName },
  ),
);
