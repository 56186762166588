import React from 'react';

import { Box } from '@mui/material';

import colors from '../../assets/colors';
import Feedback from '../Feedback';
import Header from '../Header';

interface IContainer {
  children: React.ReactNode;
  paddingTop?: number;
  paddingBottom?: number | string;
}

function Container({ children, paddingTop, paddingBottom }: IContainer) {
  const rightData = paddingTop ? paddingTop + 8 : 8;

  return (
    <>
      <Header />
      <Box
        sx={{
          backgroundColor: colors.background,
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'center',
          paddingTop: rightData,
          paddingBottom:
            paddingBottom || paddingBottom === 0 ? paddingBottom : 4,
        }}
      >
        {children}
        <Feedback />
      </Box>
    </>
  );
}

export default Container;
