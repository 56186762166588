import React, { ReactElement } from 'react';

import type {} from '@mui/x-data-grid/themeAugmentation';

import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';

import colors from './colors';

// TODO: move the modules to @types
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    gradient: true;
    outlined: true;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true;
    subquestion: true;
    pagetitle: true;
    question: true;
    pagenumber: true;
  }
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    container: true;
  }
}

type ITheme = {
  children: React.ReactNode;
};

let theme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'title' },
          style: {
            fontSize: '24px',
            fontWeight: '500',
            display: 'block',
            margin: '0.5em 0',
          },
        },
        {
          props: { variant: 'question' },
          style: {
            fontSize: '18px',
            display: 'block',
            fontWeight: '600',
            marginBlockStart: '0.5em',
            marginBlockEnd: '0.5em',
            marginTop: '8px',
            maxWidth: '90%',
          },
        },
        {
          props: { variant: 'subquestion' },
          style: {
            fontSize: '16px',
            display: 'block',
            marginBlockStart: '0.5em',
            marginBlockEnd: '0.5em',
          },
        },
        {
          props: { variant: 'pagetitle' },
          style: {
            fontSize: '32px',
            fontWeight: '500',
            display: 'block',
            marginBlockStart: '0.5em',
            marginBlockEnd: '0.5em',
          },
        },
        {
          props: { variant: 'pagenumber' },
          style: {
            fontSize: '12px',
            color: colors.text,
            display: 'block',
            marginBlockStart: '0.5em',
            marginBlockEnd: '0.5em',
          },
        },
      ],
      styleOverrides: {
        root: {
          '&.error': {
            color: colors.error,
          },
        },
      },
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: 'container' },
          style: {
            padding: 20,
            marginTop: 20,
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'gradient' },
          style: {
            background: `transparent linear-gradient(270deg, ${colors.lightButtonBlue} 0%, ${colors.darkButtonBlue} 100%) 0% 0% no-repeat padding-box`,
            boxShadow: `0px 2px 8px ${colors.shadow}`,
            borderRadius: 25,
            color: 'white',
            paddingLeft: 24,
            paddingRight: 24,
          },
        },
        {
          props: { variant: 'gradient', color: 'secondary' },
          style: {
            background: 'white',
            boxShadow: `0px 2px 8px ${colors.shadow}`,
            borderRadius: 25,
            color: '#555',
            paddingLeft: 24,
            paddingRight: 24,
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            background: 'white',
            boxShadow: 'none',
            color: '#2c2c2c',
            borderRadius: 25,
            border: '1px solid #cecece',
            maxHeight: 36,
            minHeight: 36,
            minWidth: 'min-content',
            fontWeight: 600,
            textTransform: 'capitalize',
            paddingLeft: 24,
            paddingRight: 24,
          },
        },
        {
          props: { variant: 'outlined', color: 'error' },
          style: {
            background: 'white',
            boxShadow: 'none',
            color: '#f44336',
            borderRadius: 25,
            border: '1px solid #f44336',
            maxHeight: 36,
            minHeight: 36,
            minWidth: 'min-content',
            fontWeight: 600,
            textTransform: 'capitalize',
            paddingLeft: 24,
            paddingRight: 24,
          },
        },
        {
          props: { variant: 'gradient', color: 'error' },
          style: {
            background: colors.error,
            boxShadow: `0px 2px 8px ${colors.shadow}`,
            borderRadius: 15,
            color: 'black',
          },
        },
        {
          props: { variant: 'gradient', size: 'small' },
          style: {
            minWidth: '64px',
            fontSize: '0.8125rem',
          },
        },
        {
          props: { variant: 'gradient', size: 'medium' },
          style: {
            minWidth: '64px',
            fontSize: '0.875rem',
          },
        },
        {
          props: { variant: 'gradient', size: 'large' },
          style: {
            minWidth: '64px',
            fontSize: '0.9375rem',
          },
        },
      ],
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.error': {
            color: colors.error,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.error': {
            color: colors.error,
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
        },
        columnSeparator: {
          display: 'none',
        },
        row: {
          '&:nth-child(even)': {
            backgroundColor: colors.greyRow,
          },
        },
        cell: {
          border: 'none',
        },
      },
    },
  },
  palette: {
    primary: {
      main: colors.mainBlue,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.white,
      contrastText: colors.black,
    },
    error: {
      main: colors.error,
      contrastText: colors.white,
    },
    text: {
      primary: 'rgb(0, 0, 0)',
      secondary: 'rgba(0, 0, 0, 0.622)',
      disabled: 'rgba(0, 0, 0, 0.258)',
    },
  },
});

theme = responsiveFontSizes(theme);

const AppTheme = ({ children }: ITheme): ReactElement => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default AppTheme;
