import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import QuestionWithComment from '../../../components/QuestionWithComment';
import { PATHS } from '../../../config/paths';
import { ESAQStatuses, IQuestion, ISAQSections } from '../../../entities/SAQ';
import { useAuth } from '../../../stores/useAuth';
import { useSAQ } from '../../../stores/useSAQ';
import { checkRequiredIf, getAnswer } from '../utils';
import Section from './Section';
import SectionLine from './SectionLine';

export default function Questions() {
  const { saq, setSAQ } = useSAQ();
  const { auth } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const checkAnswer = (question: IQuestion, section: ISAQSections) => {
    const answer = getAnswer(question);
    const isRequired =
      question.is_required || checkRequiredIf(question, section);
    const fieldRequired = isRequired && !answer;
    const documentRequired =
      checkRequiredIf(question, section, 'document') &&
      !question.documents?.length;

    return fieldRequired || documentRequired;
  };

  const changeUnansweredRequiredQuestions = () => {
    const sections = saq.sections.map((section) => {
      const questions = section.questions.map((question) => {
        if (
          checkAnswer(question, section) &&
          !question.comments.length &&
          question.question_type !== 'HEADING'
        ) {
          const tempQuestion = { ...question };
          tempQuestion.comments = [
            { comment: '', resolved: false, fake: true },
          ];
          return tempQuestion;
        }
        if (
          !checkRequiredIf(question, section) &&
          question.comments.length &&
          question.comments[0].fake
        ) {
          const tempQuestion = { ...question };
          tempQuestion.comments = [];
          return tempQuestion;
        }
        return question;
      });
      return { ...section, questions };
    });
    setSAQ({ ...saq, sections });
  };

  const displayQuestions = (section: ISAQSections, currentSection: number) => {
    if (
      saq.status === ESAQStatuses.PENDING &&
      !auth.user?.is_admin &&
      (saq.sections.some((section) =>
        section.questions.some(
          (question) =>
            checkAnswer(question, section) &&
            !question.comments.length &&
            question.question_type !== 'HEADING',
        ),
      ) ||
        saq.sections.some((section) =>
          section.questions.some(
            (question) =>
              !checkRequiredIf(question, section) &&
              question.comments.length &&
              question.comments[0].fake,
          ),
        ))
    ) {
      changeUnansweredRequiredQuestions();
    }
    return section.questions.map((question, questionIndex) => {
      const notAnswered = checkAnswer(question, section);
      if (
        saq.status === ESAQStatuses.PENDING &&
        !auth.user?.is_admin &&
        question.comments.length &&
        !question.comments[0].resolved &&
        (!question.required_if || checkRequiredIf(question, section))
      ) {
        return (
          <QuestionWithComment
            key={question.id}
            section={section}
            currentSection={currentSection}
            question={question}
            questionIndex={questionIndex}
            notAnswered={notAnswered}
          />
        );
      }
      return (
        <SectionLine key={question.id} question={question} section={section} />
      );
    });
  };

  const displaySections = () => {
    return saq.sections.map((section, index) => (
      <Section
        key={section.id}
        title={section.translations[0].title}
        header={t('questions')}
        onEdit={() => navigate(PATHS.SAQ, { state: { index, doCheck: true } })}
        alternate
        disableSectionEditButton={saq.status !== ESAQStatuses.INCOMPLETE}
      >
        {displayQuestions(section, index)}
      </Section>
    ));
  };

  return <>{displaySections()}</>;
}
