import { ITranslate } from '..';

const EN_GB: ITranslate = {
  profile: 'Profile',
  productList: 'Product List',
  home: 'Home',
  notifications: 'Notifications',
  Documents: 'Documents',
  Suppliers: 'Suppliers',
  products: 'Products',
  settings: 'Settings',
  logout: 'Log Out',
  start: 'Start',
  continue: 'Continue',
  review: 'Review',
  view: 'View',
  defaultSAQText: 'Start new Supplier Assessment Questionnaire',
  incompleteSAQText: 'Complete your Supplier Assessment Questionnaire',
  pendingSAQText: 'One or more questions are awaiting to be reviewed',
  awaitingApprovalSAQText:
    'Your {{ saqCategoryName }} SAQ is awaiting for approval',
  categoryHasBeen: 'Your {{ saqCategoryName }} SAQ has been',
  approved: 'APPROVED',
  rejected: 'REJECTED',
  next: 'Next',
  back: 'Back',
  tryAgain: 'Try Again',
  save: 'Save',
  progressSaved: 'Progress Saved',
  submit: 'Submit',
  section: 'Section',
  of: 'of',
  listEmpty: 'List Empty',
  categoryText1:
    'Please answer the questions below and submit your application',
  categoryText2: 'Select the type of product you are selling',
  categoryText3: 'Supplier Type',
  completeFormText: 'Please complete the Product Specification Form',
  certificate: 'upload',
  fileNotAccepted:
    'The type of document you have upload is not supported. Please try again and make sure the document type is one from the list supported.',
  fileTooBig:
    'The size of document you have upload is too big. Please try again and make sure the document size acceptable.',
  errorTitle: 'Something went wrong',
  supplierDetails: 'Supplier Details',
  technicalContact: 'Technical Contact',
  productRecallWithdrawalContact: 'Product Recall/Withdrawal Contact',
  supplierName: 'Supplier Name',
  siteAddress: 'Site Address',
  email: 'Email',
  sitePhoneNumber: 'Site Telephone Number',
  name: 'Name',
  contactNumber: 'Contact Number',
  outOfHoursContactNumber: 'Out of hours contact number',
  required: 'This field is required',
  requiredDocument: 'Document is required',
  unansweredQuestions: 'You have unanswered questions',
  reviewAnswers: 'Please review your answers before submitting the application',
  details: 'details',
  questions: 'questions',
  edit: 'edit',
  tryLater: 'Please try again later',
  uploadNewDocument: 'Upload new document',
  uploadModalInfo:
    'Please select the type of document you want to upload with the additional informations.',
  documentName: 'Document Name',
  documentType: 'Document Type',
  uploadedDate: 'Uploaded Date',
  expiryDate: 'Expiry Date',
  noExpiryDate: 'No expiry date',
  cancel: 'cancel',
  supplierAssessmentQuestionnaire: 'Supplier Assessment Questionnaire',
  saq: 'SAQ',
  uploadedDocumentation: 'Uploaded Documentation',
  notBiggerThan: 'Make sure the file is not bigger than',
  formatsAre: 'Formats accepted are',
  completeSAQ: 'Complete your SAQ',
  continueSAQInfo: 'Please continue/submit to complete the questionnaire.',
  completedSAQ: 'Completed SAQ',
  completedSAQInfo:
    'This SAQ is completed. The status is {{status}} and it has been started on {{date_started}} you can review your answers below but you can not make any changes',
  position: 'Position',
  add: 'Add',
  edited: 'Edited',
  reviewText: 'Review your Questionnaire',
  reviewComments:
    'Please review the questions in red, Once completed, please submit the Questionnaire.',
  done: 'Done',
  submitted: 'Your SAQ has been updated and submitted for approval',
  ok: 'Ok',
  completeYourQuestionnaire: `Complete your Questionnaire`,
  incompleteText: `You still need to finish and/or submit your Questionnaire.`,
  rejectedText: 'This Questionnaire has been rejected on {{date_started}}',
  approvedText:
    'This Questionnaire has been approved on {{date_started}}. You can review your answers below but you cannot make any changes.',
  awaitingApproval: 'Awaiting Approval',
  awaitingApprovalText:
    'This Questionnaire has been submitted. You can review your answers below but you cannot make any changes.',
};

export default EN_GB;
