import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import colors from '../../../assets/colors';
import SAQAdminDocuments from '../../../components/SAQAdminDocuments';
import { IQuestion, ISAQSections } from '../../../entities/SAQ';
import { useAuth } from '../../../stores/useAuth';
import Comment from '../../Admin/Suppliers/SAQ/components/Comment';
import { checkRequiredIf, getAnswer } from '../utils';

interface ISectionLine {
  question: IQuestion;
  section: ISAQSections;
}

export default function SectionLine({ question, section }: ISectionLine) {
  const { t } = useTranslation();
  const { auth } = useAuth();

  const showDocuments = () => {
    if (auth.user?.is_admin) {
      if (!question.documents.length) {
        return null;
      }
      return <SAQAdminDocuments question={question} />;
    }
    return question.documents?.map((document: any) => (
      <Typography key={document.id}>{document.document_name}</Typography>
    ));
  };

  const showCommentIcon = (id: number) => {
    if (auth.user?.is_admin) {
      return <Comment questionId={id} />;
    }
    return null;
  };

  const showEdited = () => {
    return (
      <Box
        width={10 * 5}
        px={1}
        color={colors.darkGrey}
        textTransform="uppercase"
        sx={{ float: 'right' }}
      >
        {!auth.user?.is_admin && question.comments[0]?.resolved
          ? t('edited')
          : null}
      </Box>
    );
  };

  const showComment = () => {
    if (!question.comments?.length || question.comments[0]?.resolved) {
      return null;
    }
    return (
      <Box
        p={2}
        mt={1}
        width="90%"
        sx={{
          backgroundColor: colors.greyRow,
          border: '1px solid',
          borderColor: colors.borderGrey,
          marginTop: 3,
          wordWrap: 'break-word',
          wordBreak: 'break-word',
        }}
      >
        <Typography variant="caption">Comment</Typography>
        <Typography variant="body2" color="error">
          {question.comments[0].comment}
        </Typography>
      </Box>
    );
  };

  const showAnswer = () => {
    const answer = getAnswer(question);
    const type = question.question_type;
    const attr = { sx: {} };
    const isRequired =
      question.is_required || checkRequiredIf(question, section);
    const fieldRequired = isRequired && !answer;
    const documentRequired =
      checkRequiredIf(question, section, 'document') &&
      !question.documents?.length;
    if ((fieldRequired || documentRequired) && type !== 'HEADING') {
      attr.sx = {
        '&:nth-child(even)': {
          backgroundColor: 'rgba(235, 92, 52, 0.0) !important',
          borderLeft: '3px solid rgb(235, 92, 52)',
        },
        '&:nth-child(odd)': {
          backgroundColor: 'rgba(235, 92, 52, 0.0) !important',
          borderLeft: '3px solid rgb(235, 92, 52)',
        },
      };
    }

    if (
      !isRequired &&
      typeof question.required_if === 'object' &&
      type !== 'HEADING'
    ) {
      return null;
    }

    if (
      type === 'TEXT' ||
      type === 'MULTIPLE_CHOICE' ||
      type === 'PRODUCT_LIST'
    ) {
      return (
        <Box>
          <Box
            p={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            {...attr}
          >
            <Box paddingRight={12} width="100%">
              <Typography variant="question" marginRight={2}>
                {question.translations[0].title}
              </Typography>
              <Typography variant="subquestion">{answer}</Typography>
              {showComment()}
              {showDocuments()}
              {fieldRequired && (
                <Typography variant="body2" sx={{ color: colors.error }}>
                  {t('required')}
                </Typography>
              )}
              {documentRequired && (
                <Typography>{t('requiredDocument')}</Typography>
              )}
            </Box>
            {showEdited()}
            {showCommentIcon(question.id)}
          </Box>
        </Box>
      );
    }

    return (
      <Box>
        <Box
          p={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          {...attr}
        >
          <Box width="100%">
            <Box width="100%">
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography variant="question" marginRight={2}>
                  {question.translations[0].title}
                </Typography>
                <Typography variant="subquestion">{answer}</Typography>
              </Box>
              {showEdited()}
            </Box>
            {showComment()}
            {showDocuments()}
            {fieldRequired && type !== 'HEADING' && (
              <Typography variant="body2" sx={{ color: colors.error }}>
                {t('required')}
              </Typography>
            )}
            {documentRequired && type !== 'HEADING' && (
              <Typography variant="body2" sx={{ color: colors.error }}>
                {t('requiredDocument')}
              </Typography>
            )}
          </Box>
          {type !== 'HEADING' && showCommentIcon(question.id)}
        </Box>
      </Box>
    );
  };

  return showAnswer();
}
