import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import background from '../../assets/images/back_home.jpg';
import { PATHS } from '../../config/paths';
import { useAuth } from '../../stores/useAuth';
import Container from '../Container';

const styles = {
  c_home: {
    height: 'calc(100vh - 64px)',
    width: '100vw',
    backgroundRepeat: 'cover',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    zIndex: -2,
    overflow: 'hidden',
    position: 'absolute',
    left: 0,
    backgroundImage: `url(${background})`,
  },
};

function HomeScreen() {
  const { auth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.user?.is_admin) {
      navigate(PATHS.HOME);
    }
  }, [auth]);

  return (
    <Container paddingBottom={0}>
      <Box sx={styles.c_home} />
    </Container>
  );
}

export default React.memo(HomeScreen);
