import React from 'react';

import { Box, Button, Typography } from '@mui/material';

interface IUploadedDocument {
  name: string;
  remove: () => void;
}

export default function UploadedDocument({ name, remove }: IUploadedDocument) {
  return (
    <Box display="flex" alignItems="center">
      <Typography>{name}</Typography>
      <Button onClick={remove}>Remove</Button>
    </Box>
  );
}
