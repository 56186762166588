export interface ISupplierDetails {
  general_name: string;
  general_address: string;
  general_phone_number: string;
  general_email: string;
  technical_name: string;
  technical_phone_number: string;
  technical_email: string;
  product_recall_name: string;
  product_recall_phone_number: string;
  product_recall_ooh_number: string;
  product_recall_email: string;
  id?: number;
}

export const InitialStateSupplierDetails = {
  general_name: '',
  general_address: '',
  general_phone_number: '',
  general_email: '',
  technical_name: '',
  technical_phone_number: '',
  technical_email: '',
  product_recall_name: '',
  product_recall_phone_number: '',
  product_recall_ooh_number: '',
  product_recall_email: '',
  id: undefined,
};

export interface IErrorSupplierDetails {
  general_name: string[];
  general_address: string[];
  general_phone_number: string[];
  general_email: string[];
  technical_name: string[];
  technical_phone_number: string[];
  technical_email: string[];
  product_recall_name: string[];
  product_recall_phone_number: string[];
  product_recall_ooh_number: string[];
  product_recall_email: string[];
  id?: number;
}

export const InitialErrorDetails = {
  general_name: [''],
  general_address: [''],
  general_phone_number: [''],
  general_email: [''],
  technical_name: [''],
  technical_phone_number: [''],
  technical_email: [''],
  product_recall_name: [''],
  product_recall_phone_number: [''],
  product_recall_ooh_number: [''],
  product_recall_email: [''],
};
