import React from 'react';

const IconSuppliers = (props) => (
  <svg viewBox="0 0 55 56.7" {...props}>
    <path
      className="icon-products_svg__st0"
      fill="#999"
      d="M23.2 30.2c0-.9-.6-1.5-1.5-1.5-.8 0-1.7.8-1.7 1.5 0 1 .8 1.6 1.6 1.7h.2c.3 0 .6-.1.8-.3.4-.3.6-.8.6-1.4zM23.1 32c-.3-.1-.6 0-.8.1-.3.2-.5.6-.5 1 .1.5.3 1 1.1 1 .7 0 1.1-.4 1.1-1.1 0-.7-.6-.9-.9-1zM22.5 23.4c-1.4 0-2.5 1.1-2.5 2.5s1 2.3 2.5 2.4h.2c.6 0 1.2-.3 1.7-.8.4-.5.6-1.1.5-1.6-.2-1.5-1.1-2.5-2.4-2.5z"
    />
    <path
      className="icon-products_svg__st0"
      fill="#999"
      d="M38.7 10.5c-2.5-.4-4.3-.4-5.8 0-2.7.7-4.6 2.6-5.1 5.2-.1-.2-.1-.4-.2-.6l-.3-.5c-.4-.6-.7-1.2-1.2-1.8-.1-.1-.6-.3-.8-.2l-.3.1v.1c-.1.2-.1.4-.1.7 0 .3.2.5.3.6h.1c.8 1 1 1.7 1.2 2.8-.4.1-1.2.2-1.4.2-5.6.8-9.9 6.4-9.1 12.1.8 5.8 5.2 9.8 10.9 9.8.7 0 1.4-.1 2.1-.2 2.8-.5 5.3-2.2 7-4.8 1.7-2.5 2.2-5.7 1.5-8.5-1.1-4.7-4.7-7.8-9.5-8.5 0-.3-.1-.6-.2-.9h.2c.6-.1 1.2-.2 1.9-.2 1.2-.1 2.4-.2 3.4-.5 2-.5 3.5-1.3 4.4-2.5.3-.4.5-.7.7-1.1.1-.3.3-.5.5-.8l.2-.3-.4-.2zM35.4 26c.5 2.3.1 4.7-1.2 6.8-1.3 2-3.2 3.3-5.4 3.8-.6.1-1.2.2-1.7.2-4.5 0-8-3.1-8.6-7.8-.6-4.4 2.7-8.8 7-9.5.6 0 1.2-.2 1.6-.3.2.1.3.1.5.2h.3c3.7.4 6.5 2.8 7.5 6.6zm-8.5-8.9z"
    />
  </svg>
);

export default IconSuppliers;
