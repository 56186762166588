import React from 'react';

const SvgIconHome = (props) => (
  <svg viewBox="0 0 55 56.7" {...props}>
    <path
      className="icon-home_svg__st0"
      fill="#999"
      d="M672.6 1136.6c-1.6 0-3 1.3-3 2.9s1.4 3 3 3 3-1.4 2.9-2.9c-.1-1.6-1.3-3-2.9-3zM683.4 1136.5c-1.7 0-3 1.4-3 3s1.4 3 3 3 3-1.4 3-3.1c0-1.7-1.4-2.9-3-2.9z"
    />
    <path
      className="icon-home_svg__st0"
      fill="#999"
      d="M672.4 1130.2h.9v1.2c0 1.1.9 2 2 2h6.9c-1.6.3-3 1.3-4 2.7h-.5c-1.5-2.2-3.5-2.7-5.1-2.7-1.2 0-2.3.3-3.3.9v-3.7-.1c.1 0 .2-.1.7-.2m17.2 3.1v1.3c-.6-.5-1.2-.8-1.8-1.1h.3c.4 0 .7-.1 1.2-.2.2.1.3 0 .3 0m.8-15.1c-.7 0-1.3.5-1.4 1.1v12.1c-.4.1-.7.1-.9.1-.4 0-.5-.2-.8-.9-1.6-3.7-3.1-7.4-4.7-11.1-.2-.7-.8-1-1.6-1h-7.3c-1.1 0-1.6.3-1.6 1.6v8.1c-1.6.2-2.4 1-2.4 2.3v6.8c0 .2.2.7.5.7.1 0 .3.1.5.1s.4-.1.6-.4c.8-1.6 2.1-2.4 3.8-2.4s2.9.8 3.7 2.3c.1.3.3.5.7.5h1.9c.5 0 .6-.2.8-.6.8-1.5 2.1-2.3 3.8-2.3 1.7 0 2.9.9 3.7 2.4.5.9.5 1.8.2 3h5.4c.7 0 1.1-.6 1.1-1 0-.7-.3-1.1-.9-1.4-.2-.1-.6-.1-.8-.1h-2.9v-1.1-16.9-.7c-.3-.8-.7-1.2-1.4-1.2zm-15.6 9.9v-7.2h5.5c.1 0 .3.1.3.3 1.4 3.3 2.9 6.6 4.2 9.9v.1h-7.2v-2.3c0-.8-.1-.9-.9-.9-.7.1-1.3.1-1.9.1z"
    />
    <path
      className="icon-home_svg__st1"
      fill="#999"
      d="M38 24.3l-9.1-7.6c-.4-.4-.9-.6-1.4-.6-.5 0-1 .2-1.3.5l-9.4 7.9c-.6.5-.8 1.5-.6 2.2.3.9 1.2 1.4 2 1.4h.1v9.2c0 1.9 1.5 3.4 3.4 3.4h2.8c.6 0 1.2-.5 1.2-1.2v-5.7H29v5.7c0 .6.5 1.2 1.2 1.2h3.1c1.9 0 3.4-1.5 3.4-3.4V28h.2c1.1 0 2-1 2-2 0-.7-.4-1.4-.9-1.7zm-4.7 14h-1.9v-4.9c0-1.1-.9-1.9-1.9-1.9h-4.1c-1.1 0-1.9.9-1.9 1.9v4.9h-1.7c-.6 0-1-.5-1-1v-13l6.7-5.7 6.9 5.7v13c-.1.5-.6 1-1.1 1z"
    />
  </svg>
);

export default SvgIconHome;
