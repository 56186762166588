import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Divider, Typography, Button } from '@mui/material';

import colors from '../../assets/colors';
import { PATHS } from '../../config/paths';
import { ESAQStatuses } from '../../entities/SAQ';
import Paper from '../Paper/index';

interface ProfileCardProps {
  userName?: string;
  userAddress?: string;
  saqStatus: ESAQStatuses;
  saqCategoryName?: string;
}

export function ProfileCard({
  userName,
  userAddress,
  saqStatus = ESAQStatuses.NOT_STARTED,
  saqCategoryName,
}: ProfileCardProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const readyToStartNewSAQ = saqStatus === ESAQStatuses.NOT_STARTED;
  const readyToViewSAQ = [
    ESAQStatuses.AWAITING_APPROVAL,
    ESAQStatuses.APPROVED,
    ESAQStatuses.REJECTED,
    ESAQStatuses.PENDING,
  ].includes(saqStatus);

  const startSAQ = () => {
    navigate(PATHS.CATEGORY);
  };

  const viewSAQ = () => {
    navigate({ pathname: PATHS.DOCUMENT, search: '?tabIndex=1' });
  };

  const displayText = () => {
    switch (saqStatus) {
      case ESAQStatuses.INCOMPLETE:
        return t('incompleteSAQText');
      case ESAQStatuses.PENDING:
        return t('pendingSAQText');
      case ESAQStatuses.AWAITING_APPROVAL:
        return t('awaitingApprovalSAQText', {
          saqCategoryName,
          interpolation: { escapeValue: false },
        });
      case ESAQStatuses.APPROVED:
        return (
          <>
            <Typography noWrap variant="body1">
              {t('categoryHasBeen', {
                saqCategoryName,
                interpolation: { escapeValue: false },
              })}
            </Typography>
            <Typography fontWeight="bold" variant="body1">
              {t('approved')}
            </Typography>
          </>
        );
      case ESAQStatuses.REJECTED:
        return (
          <>
            <Typography noWrap variant="body1">
              {t('categoryHasBeen', {
                saqCategoryName,
                interpolation: { escapeValue: false },
              })}
            </Typography>
            <Typography fontWeight="bold" variant="body1">
              {t('rejected')}
            </Typography>
          </>
        );
      default:
        return t('defaultSAQText');
    }
  };

  const displayButton = () => {
    let label = t('start');
    if (saqStatus === ESAQStatuses.INCOMPLETE) {
      label = t('continue');
    }
    if (readyToViewSAQ) {
      label = t('view');
    }
    if (saqStatus === ESAQStatuses.PENDING) {
      label = t('review');
    }
    return (
      <Button
        variant={
          readyToViewSAQ && saqStatus !== ESAQStatuses.PENDING
            ? 'outlined'
            : 'gradient'
        }
        onClick={readyToViewSAQ ? viewSAQ : startSAQ}
      >
        {label}
      </Button>
    );
  };

  return (
    <Paper>
      <Typography style={{ marginTop: 0 }} variant="title">
        {t('profile')}
      </Typography>
      <Typography variant="body1">{userName}</Typography>
      <Typography variant="body2">{userAddress}</Typography>

      <Box pt={2}>
        <Divider />
      </Box>

      <Box display="flex" flexDirection="column">
        <Typography variant="title">SAQ</Typography>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          p={1.5}
          borderRadius={2}
          bgcolor={
            readyToStartNewSAQ
              ? colors.lightBackgroundBlue
              : colors.lightBackgroundGrey
          }
        >
          <Typography variant="body1">{displayText()}</Typography>
          <Box paddingLeft={5}>{displayButton()}</Box>
        </Box>
        {saqStatus === ESAQStatuses.APPROVED ||
        saqStatus === ESAQStatuses.REJECTED ? (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            p={1.5}
            marginY={2}
            borderRadius={2}
            bgcolor={colors.lightBackgroundBlue}
          >
            <Typography variant="body1">{t('defaultSAQText')}</Typography>
            <Box paddingLeft={5}>
              <Button variant="gradient" onClick={startSAQ}>
                {t('start')}
              </Button>
            </Box>
          </Box>
        ) : null}
      </Box>
    </Paper>
  );
}
