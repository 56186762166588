import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Box, Typography, TextField, Button } from '@mui/material';
import * as yup from 'yup';

import colors from '../../assets/colors';
import LogoNatoora from '../../assets/icons/IconNatoora';
import Container from '../../components/Container';
import LoadingWrapper from '../../components/LoadingWrapper';
import { PATHS } from '../../config/paths';
import { useYupValidationResolver } from '../../hooks/useYupValidationResolver/index';
import { useForgotPassword } from '../../services/auth';

interface IPayload {
  email: string;
}

const forgotPasswordValidationSchema = yup.object().shape({
  email: yup
    .string()
    .matches(
      /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
      'Please enter a valid email address.',
    )
    .required('Required'),
});

export default function ForgotPassword() {
  const { handleSubmit, control } = useForm<IPayload>({
    defaultValues: {
      email: '',
    },
    resolver: useYupValidationResolver(forgotPasswordValidationSchema),
  });
  const navigate = useNavigate();

  const { mutate, isLoading } = useForgotPassword({
    onSettled: () => {
      navigate(PATHS.SUCCESSFUL_SOLICITED);
    },
  });

  return (
    <LoadingWrapper loading={isLoading}>
      <Container paddingTop={10}>
        <Box
          p={2}
          borderRadius={2}
          minWidth="522px"
          border={`1px solid ${colors.disabledGrey}`}
          bgcolor={colors.white}
        >
          <LogoNatoora height="45px" />
          <Typography variant="title">Forgot your password?</Typography>
          <Typography variant="subtitle1">
            Please enter below your email address associated with your account
          </Typography>
          <Typography variant="subtitle1">
            and we will send you a link to reset your password.
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit((data) => {
              mutate(data.email);
            })}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDirection="column"
            gap={3}
            py={3}
          >
            <Box
              display="flex"
              flexDirection="column"
              paddingBottom={2}
              gap={3}
              py={3}
              width="100%"
            >
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    id="email"
                    label="E-mail"
                    type="email"
                    autoComplete="e-mail"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Box>
            <Button type="submit" variant="gradient">
              CONFIRM
            </Button>
          </Box>
        </Box>
      </Container>
    </LoadingWrapper>
  );
}
