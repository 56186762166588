import moment from 'moment';

import 'moment/locale/pt';
import 'moment/locale/en-gb';
import 'moment/locale/en-au';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/da';

const formatDateBasedOnBrowser = (date) => {
  if (!date) return null;

  const browserLanguage = navigator.language;
  return moment(date).locale(browserLanguage).format('L');
};

export default formatDateBasedOnBrowser;
