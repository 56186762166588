import React from 'react';

const SvgIconNatoora = (props) => (
  <svg viewBox="0 0 178 90" {...props}>
    <g>
      <path d="M50.3,17.3c0,6.6-5.3,11.9-11.9,11.9s-11.9-5.3-11.9-11.9S31.8,5.4,38.4,5.4C45,5.3,50.3,10.7,50.3,17.3" />
      <path d="M3.6,5.4c6.5,0,11.8,5.3,11.8,11.8S10.1,29,3.6,29C3.6,29,3.6,5.4,3.6,5.4z" />
      <path d="M85,17.3c0-6.6-5.3-11.9-11.9-11.9s-11.9,5.3-11.9,11.9s5.3,11.9,11.9,11.9L85,17.3z" />
    </g>
    <g>
      <path d="M21.2,64.2L6.4,44.1v20.2H3.3V38.9h3.2L21,58.6V38.9h3.1v25.3H21.2z" />
      <path d="M48.2,64.2L46,58.6H33.3l-2.2,5.6h-3.6l10.2-25.3h3.9l10.2,25.3H48.2z M39.6,42.2l-5.4,13.6H45L39.6,42.2z" />
      <path d="M58.8,64.2V41.7h-8v-2.8H70v2.8h-8v22.5H58.8z" />
      <path d="M72.1,51.6c0-7.5,5-13.1,12.5-13.1s12.5,5.6,12.5,13.1S92,64.7,84.6,64.7C77.1,64.7,72.1,59.1,72.1,51.6zM93.9,51.6c0-5.9-3.6-10.3-9.3-10.3c-5.7,0-9.3,4.4-9.3,10.3s3.6,10.3,9.3,10.3C90.3,61.9,93.9,57.5,93.9,51.6z" />
      <path d="M101.1,51.6c0-7.5,5-13.1,12.5-13.1s12.5,5.6,12.5,13.1s-5.1,13.1-12.5,13.1C106.1,64.7,101.1,59.1,101.1,51.6z M122.9,51.6c0-5.9-3.6-10.3-9.3-10.3c-5.7,0-9.3,4.4-9.3,10.3s3.6,10.3,9.3,10.3C119.3,61.9,122.9,57.5,122.9,51.6z" />
      <path d="M145.7,64.2l-6.4-10h-5v10h-3.1V38.9h10.2c4.6,0,7.9,3,7.9,7.6c0,4.5-3.1,7-6.6,7.3l6.8,10.3L145.7,64.2L145.7,64.2z M145.9,46.6c0-2.8-2-4.8-5-4.8h-6.6v9.7h6.6C143.9,51.4,145.9,49.4,145.9,46.6z" />
      <path d="M172.2,64.2l-2.2-5.6h-12.6l-2.2,5.6h-3.6l10.2-25.3h3.9l10.2,25.3H172.2z M163.7,42.2l-5.4,13.6H169L163.7,42.2z" />
    </g>
  </svg>
);

export default SvgIconNatoora;
