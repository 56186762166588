import axios from 'axios';

import config from '../../config/constants';
import { setupInterceptors } from './interceptors';

export const api = axios.create({
  baseURL: `${config.baseUrl}/api/`,
});

setupInterceptors(api);
