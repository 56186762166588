import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFeedback } from '../../stores/useFeedback';
import UploadButtonLayout from '../UploadButtonLayout';

interface IUploadButton {
  onChange: (file: File) => void;
  questionId: number;
  label?: string;
}

// Wrote this way to make more sense and be easier to change based on MB instead of bytes
const MAX_FILE_SIZE = 10 * 1024 * 1024;
const ACCEPTED_FILE_TYPES = [
  'image/png',
  'image/jpeg',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export default function UploadButton({
  onChange,
  questionId,
  label,
}: IUploadButton) {
  const { t } = useTranslation();
  const { setAlert } = useFeedback();
  const id = `${questionId}`;
  const handleCapture = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target?.files?.[0];

    if (!file) {
      return;
    }
    if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
      setAlert({
        show: true,
        title: t('errorTitle'),
        message: t('fileNotAccepted'),
      });
      return;
    }
    if (file.size > MAX_FILE_SIZE) {
      setAlert({
        show: true,
        title: t('errorTitle'),
        message: t('fileTooBig'),
      });
      return;
    }

    onChange(file);
  };
  return (
    <>
      <label htmlFor={id}>
        <input
          id={id}
          style={{ display: 'none' }}
          type="file"
          onChange={handleCapture}
          onClick={(e) => {
            // Fixes selecting the same file
            e.currentTarget.value = '';
          }}
        />
        <UploadButtonLayout label={label || t('certificate')} />
      </label>
    </>
  );
}
