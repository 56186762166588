import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Typography, Box, Divider, Button } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import Container from '../../components/Container/index';
import LoadingWrapper from '../../components/LoadingWrapper';
import Paper from '../../components/Paper';
import { PATHS } from '../../config/paths';
import { useCategory, useUpdateCategory } from '../../services/categories';
import { useSupplierType } from '../../services/supplierProfile';
import { useAuth } from '../../stores/useAuth';
import { useCategories } from '../../stores/useCategories';
import { useSupplierTypes } from '../../stores/useSupplierTypes';

function Category() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth, setCategory, setType } = useAuth();
  const { categories } = useCategories();
  const { types } = useSupplierTypes();
  const { setCategories } = useCategories();
  const { setTypes } = useSupplierTypes();

  const { mutate: updateCategory, isLoading: updateLoading } =
    useUpdateCategory({
      onSuccess: () => {
        navigate(PATHS.DETAILS);
      },
    });

  const { mutate: mutateCategory, isLoading: categoryLoading } = useCategory({
    onSuccess: (response) => {
      setCategories(response);
    },
  });

  const { mutate: mutateSupplierType, isLoading: typeLoading } =
    useSupplierType({
      onSuccess: (response) => {
        setTypes(response);
      },
    });

  useEffect(() => {
    mutateCategory({ language: auth.user?.language });
    mutateSupplierType({ language: auth.user?.language });
  }, []);

  const displayCategories = () => {
    return categories.map((category) => (
      <FormControlLabel
        key={category.id}
        value={category.id}
        control={<Radio />}
        label={category.translations[0].name}
      />
    ));
  };

  const displayTypes = () => {
    return types.map((type) => (
      <FormControlLabel
        key={type.id}
        value={type.id}
        control={<Radio />}
        label={type.translations[0].name}
      />
    ));
  };

  const save = () => {
    if (auth.user?.active_supplier_details.category) {
      updateCategory({
        id: auth.user.active_supplier_details.id,
        category: auth.user.active_supplier_details.category,
        type: auth.user.active_supplier_details.type,
      });
    }
  };

  return (
    <LoadingWrapper loading={updateLoading || categoryLoading || typeLoading}>
      <Container paddingTop={2}>
        <Paper width={46}>
          <Typography variant="pagetitle">SAQ</Typography>
          <Typography variant="subtitle1">{t('categoryText1')}</Typography>
          <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
          <Box data-testid="categories-selection-container">
            <Typography variant="question">{t('categoryText2')}</Typography>
            <RadioGroup
              aria-labelledby="categories-type"
              defaultValue={auth.user?.active_supplier_details.category}
              name="categories-group"
              onChange={(e) => setCategory(parseInt(e.target.value))}
            >
              {displayCategories()}
            </RadioGroup>
          </Box>
          <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
          <Box data-testid="types-selection-container">
            <Typography variant="question">{t('categoryText3')}</Typography>

            <RadioGroup
              aria-labelledby="supplier-type"
              defaultValue={auth.user?.active_supplier_details.type}
              name="product-type-group"
              onChange={(e) => setType(parseInt(e.target.value))}
            >
              {displayTypes()}
            </RadioGroup>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            mt={5}
            data-testid="buttons-container"
          >
            <Button
              variant="gradient"
              color="secondary"
              onClick={() => navigate(PATHS.HOME)}
            >
              {t('back')}
            </Button>
            <Box>
              <Button variant="gradient" onClick={save}>
                {t('next')}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
    </LoadingWrapper>
  );
}

export default Category;
