import { ISAQ } from '../entities/SAQ';

export const optionsSeparator = ',';

export const changeBoolean = (
  questionIndex: number,
  questionId: number,
  value: string,
  saq: ISAQ,
  currentSection: number,
) => {
  const boolValue = value === 'true';
  const tempSaq = { ...saq };
  const tempAnswer = tempSaq.sections[currentSection].questions[questionIndex]
    .answers[0]
    ? {
        ...tempSaq.sections[currentSection].questions[questionIndex].answers[0],
        boolean_answer: boolValue,
      }
    : {
        question: questionId,
        boolean_answer: boolValue,
        text_answer: null,
        json_answer: null,
      };
  tempSaq.sections[currentSection].questions[questionIndex].answers[0] =
    tempAnswer;
  return tempSaq;
};

export const changeText = (
  questionIndex: number,
  questionId: number,
  value: string,
  saq: ISAQ,
  currentSection: number,
  setSAQ: (saq: ISAQ) => void,
) => {
  const tempSaq = { ...saq };
  const tempAnswer = tempSaq.sections[currentSection].questions[questionIndex]
    .answers[0]
    ? {
        ...tempSaq.sections[currentSection].questions[questionIndex].answers[0],
        text_answer: value,
      }
    : {
        question: questionId,
        boolean_answer: null,
        text_answer: value,
        json_answer: null,
      };
  tempSaq.sections[currentSection].questions[questionIndex].answers[0] =
    tempAnswer;
  setSAQ(tempSaq);
};

export const changeMultiple = (
  questionIndex: number,
  questionId: number,
  checked: boolean,
  option: string,
  saq: ISAQ,
  currentSection: number,
  setSAQ: (saq: ISAQ) => void,
) => {
  const tempSaq = { ...saq };
  const options =
    tempSaq.sections[currentSection].questions[questionIndex].translations[0]
      ?.options;

  const jsonAnswer = options
    .split(optionsSeparator)
    .reduce<Record<string, boolean>>(
      (acc, currentOption) => ({ ...acc, [currentOption]: false }),
      {},
    );

  const tempAnswer = tempSaq.sections[currentSection].questions[questionIndex]
    .answers[0]
    ? {
        ...tempSaq.sections[currentSection].questions[questionIndex].answers[0],
      }
    : {
        question: questionId,
        boolean_answer: null,
        text_answer: null,
        json_answer: jsonAnswer,
      };

  tempAnswer.json_answer = {
    ...tempAnswer.json_answer,
    [option]: checked,
  };
  tempSaq.sections[currentSection].questions[questionIndex].answers[0] =
    tempAnswer;
  setSAQ(tempSaq);
};

export const changeSingle = (
  questionIndex: number,
  questionId: number,
  value: boolean,
  option: string,
  saq: ISAQ,
  currentSection: number,
  setSAQ: (saq: ISAQ) => void,
) => {
  const tempSaq = { ...saq };
  const tempAnswer = tempSaq.sections[currentSection].questions[questionIndex]
    .answers[0]
    ? {
        ...tempSaq.sections[currentSection].questions[questionIndex].answers[0],
      }
    : {
        question: questionId,
        boolean_answer: null,
        text_answer: null,
        json_answer: {},
      };

  tempAnswer.json_answer = { [option]: true };
  tempSaq.sections[currentSection].questions[questionIndex].answers[0] =
    tempAnswer;
  setSAQ(tempSaq);
};

export const changeDocument = (
  questionId: number,
  file: File,
  saq: ISAQ,
  currentSection: number,
) => {
  const tempSaq = { ...saq };
  const questionIndex = saq.sections[currentSection].questions.findIndex(
    (question) => question.id === questionId,
  );
  const tempQuestion =
    tempSaq.sections[currentSection].questions[questionIndex];
  if (!tempQuestion.documents) {
    tempQuestion.documents = [];
  }
  tempQuestion.documents.push(file);
  return tempSaq;
};

export const changeProduct = (
  questionIndex: number,
  questionId: number,
  value: string,
  saq: ISAQ,
  currentSection: number,
  answerIndex?: number,
) => {
  const boolValue = value === 'true';
  const tempSaq = { ...saq };
  const tempQuestion =
    tempSaq.sections[currentSection].questions[questionIndex];
  if (answerIndex || answerIndex === 0) {
    tempQuestion.answers[0].json_answer[answerIndex] = value;
  } else if (Array.isArray(tempQuestion.answers[0].json_answer)) {
    tempQuestion.answers[0].json_answer.push('');
  } else {
    tempQuestion.answers[0].json_answer = [''];
  }
  return tempSaq;
};
