import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RadioGroup, Typography, Box, FormHelperText } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import colors from '../../assets/colors';
import { IQuestion } from '../../entities/SAQ';
import { getAnswer } from '../../pages/SAQOverview/utils';
import { useSAQ } from '../../stores/useSAQ';
import { changeSingle } from '../../utils/onChangeFunctions';
import UploadButtonLayout from '../UploadButtonLayout';
import UploadedDocumentsSAQ from '../UploadedDocumentsSAQ';
import UploadNewDocumentsModal from '../UploadNewDocumentsModal';

interface IQuestionSingle {
  question: IQuestion;
  questionIndex: number;
  options?: string[];
  showUpload: boolean;
  isDocumentRequired: boolean;
  isRequired: boolean;
  showError: boolean;
  currentSection: number;
}

export default function QuestionSingleChoice({
  question,
  questionIndex,
  options,
  showUpload,
  isDocumentRequired,
  isRequired,
  showError,
  currentSection,
}: IQuestionSingle) {
  const { saq, setSAQ } = useSAQ();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const { t } = useTranslation();

  const questionId = question.id;
  const isSub = question.is_sub;

  const hasError = isRequired && !getAnswer(question) && showError;
  const hasDocumentError =
    showUpload &&
    isDocumentRequired &&
    showError &&
    !saq.sections[currentSection].questions[questionIndex].documents?.length;

  return (
    <Box>
      <Typography
        variant="question"
        fontWeight={isSub ? 300 : 600}
        className={hasError ? 'error' : ''}
      >
        {question.translations[0].title}
        {isRequired && '*'}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <RadioGroup
          name={`single-choice${questionId}`}
          sx={{
            color: hasError ? colors.error : 'inherit',
          }}
        >
          {options?.map((option: string) => (
            <FormControlLabel
              key={option}
              control={
                <Radio
                  checked={question.answers[0]?.json_answer[option] || false}
                  onChange={(e) =>
                    changeSingle(
                      questionIndex,
                      question.id,
                      e.target.checked,
                      option,
                      saq,
                      currentSection,
                      setSAQ,
                    )
                  }
                  name={option}
                  required={isRequired}
                  className={hasError ? 'error' : ''}
                />
              }
              label={option}
            />
          ))}
        </RadioGroup>
        {showUpload && (
          <Box>
            <UploadButtonLayout
              label={t('certificate') + (isDocumentRequired ? '*' : '')}
              onClick={() => setShowUploadModal(true)}
              error={hasDocumentError}
            />
          </Box>
        )}
      </Box>
      {hasError && <FormHelperText error>{t('required')}</FormHelperText>}
      {showUpload && (
        <UploadedDocumentsSAQ
          currentSection={currentSection}
          questionIndex={questionIndex}
        />
      )}
      {hasDocumentError && (
        <FormHelperText error>{t('requiredDocument')}</FormHelperText>
      )}
      <UploadNewDocumentsModal
        open={showUploadModal}
        setOpen={setShowUploadModal}
        questionId={questionId}
        currentSection={currentSection}
      />
    </Box>
  );
}
