import React from 'react';

const SvgIconOrders = (props) => (
  <svg viewBox="0 0 55 56.7" {...props}>
    <path
      className="icon-logout_svg__st0"
      fill="#999"
      d="M24.4,35.7h-4.1c-1.4,0-2.2-0.9-2.2-2.2v-9.9c0-1.4,0.9-2.2,2.2-2.2h4.2c0.6,0,1-0.4,1-1v-0.5c0-0.8-0.4-1-1-1
		H20c-1,0-2,0.4-2.9,1.2c-0.8,0.7-1.2,1.8-1.4,2.6v11.4c0,0.1,0,0.2,0.1,0.3l0,0.1c0.4,1.9,2.1,3.4,3.9,3.4h4.8c0.2,0,0.6,0,0.6-0.3
		c0.1-0.4,0.2-1,0.1-1.3C25.2,35.8,24.5,35.7,24.4,35.7z"
    />
    <path
      className="icon-logout_svg__st0"
      fill="#999"
      d="M39.2,28.1L39.2,28.1c-0.2-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.1-0.2-0.2-0.2l-7.9-7.9c-0.4-0.4-1-0.6-1.4-0.4
		c-0.8,0.3-0.8,1-0.8,1.3v3.9h-6.1c-1.1,0-1.5,0.4-1.5,1.5v5.2c0,1.1,0.4,1.5,1.5,1.5h6.2v3.9c0,0.5,0.3,0.9,0.8,1.1
		c0.2,0.1,0.3,0.1,0.5,0.1c0.3,0,0.5-0.1,0.8-0.4l0.2-0.2c0.9-0.9,1.7-1.7,2.6-2.5c1.7-1.6,3.4-3.3,5-5c0.1-0.1,0.2-0.2,0.3-0.4
		c0.1-0.1,0.2-0.2,0.3-0.3l0.1-0.1v-0.1C39.3,28.6,39.3,28.4,39.2,28.1z M29,30.1h-5.4v-3.2h5.3c1.2,0,2.2-1,2.2-2.2v-1.2l5.1,5.1
		c-1.4,1.4-2.8,2.8-4.3,4.3l-0.6,0.6v-1.1C31.3,31.1,30.3,30.1,29,30.1z"
    />
  </svg>
);

export default SvgIconOrders;
