import React from 'react';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button } from '@mui/material';

interface IUploadButton {
  label: string;
  onClick?: () => void;
  error?: boolean;
}

export default function UploadButtonLayout({
  label,
  onClick,
  error,
}: IUploadButton) {
  // Using span because it needs to work inside a label. DO NOT REMOVE
  // If you remove it will mess the propagation of the event
  return (
    <Button
      variant="outlined"
      color={error ? 'error' : 'primary'}
      component="span"
      onClick={onClick}
    >
      <FileUploadIcon color={error ? 'error' : 'primary'} sx={{ mr: 1 }} />
      {label}
    </Button>
  );
}
