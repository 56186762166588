/* eslint-disable no-param-reassign */
import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

import { useAuth } from '../../stores/useAuth';

export interface ApiErrorDefaultResponse {
  response: {
    data: {
      non_field_errors?: string[];
      password?: string[];
      email?: string[];
      error?: string[];
      incomplete_questions?: number[];
    };
  };
}
export type ApiError<Error = ApiErrorDefaultResponse> = AxiosError<Error>;
export type ApiResponse<Data> = Promise<AxiosResponse<Data>>;

const onRequest = async (config: AxiosRequestConfig) => {
  const { auth: data } = useAuth.getState();

  // handles django's csrf issue
  config.withCredentials = false;

  if (data?.access_token && config?.headers) {
    if (config?.url !== 'users/auth/login/') {
      config.headers.Authorization = `JWT ${data.access_token}`;
    }
  }

  return config;
};

const onRequestError = (error: AxiosError) => {
  return Promise.reject(error);
};

// ----- //

const onResponse = (response: AxiosResponse) => response;

const onResponseError = (error: AxiosError<any>) => {
  if (
    error.response?.status === 401 &&
    (error.response?.data?.code === 'token_not_valid' ||
      error.response?.data?.detail ===
        'Authentication credentials were not provided.')
  ) {
    const { logout } = useAuth.getState();
    logout();
  }
  return Promise.reject(error);
};

// ----- //

export const setupInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
};
