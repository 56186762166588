import React from 'react';

import Loading from '../Loading';

interface ILoadingWrapper {
  loading: boolean;
  children: React.ReactNode;
  doNotShowContent?: boolean;
}

export default function LoadingWrapper({
  loading,
  children,
  doNotShowContent,
}: ILoadingWrapper) {
  return (
    <>
      {doNotShowContent ? null : children}
      <Loading loading={loading} />
    </>
  );
}
