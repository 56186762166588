import React from 'react';

import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { useFeedback } from '../../stores/useFeedback';
import ModalAlert from '../ModalAlert';
import ModalConfirmation from '../ModalConfirmation';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Feedback() {
  const { alert, setAlert, toast, setToast, confirmation, setConfirmation } =
    useFeedback();

  return (
    <>
      <Snackbar
        open={toast.show}
        autoHideDuration={2000}
        onClose={() => setToast({ show: false, type: 'success', message: '' })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() =>
            setToast({ show: false, type: 'success', message: '' })
          }
          severity={toast.type === 'error' ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {toast.message}
        </Alert>
      </Snackbar>
      <ModalAlert
        title={alert.title}
        subtitle={alert.message}
        open={alert.show}
        onClose={() => setAlert({ show: false, title: '', message: '' })}
      />
      <ModalConfirmation
        title={confirmation.title}
        message={confirmation.message}
        open={confirmation.show}
        onConfirm={confirmation.onConfirm}
        onCancel={confirmation.onCancel}
        onClose={() => {
          setConfirmation({
            show: false,
            title: '',
            message: '',
            onConfirm: () => null,
            onCancel: () => null,
          });
        }}
      />
    </>
  );
}
