import { ITranslate } from '..';

const ES_ES: ITranslate = {
  profile: 'Perfil',
  productList: 'Lista de productos',
  home: 'Home',
  notifications: 'Notificaciones',
  Documents: 'Documentos',
  Suppliers: 'Proveedores',
  products: 'Productos',
  settings: 'Ajustes',
  logout: 'Log Out',
  start: 'Iniciar',
  continue: 'Continuar',
  review: 'Revisar',
  view: 'Ver',
  defaultSAQText: 'Iniciar nuevo Cuestionario de Evaluación de Proveedores.',
  incompleteSAQText: 'Complete su Cuestionario de Evaluación de Proveedores.',
  pendingSAQText: 'Una o más preguntas están esperando revisión.',
  awaitingApprovalSAQText:
    'Tu {{ saqCategoryName }} SAQ está esperando aprobación.',
  categoryHasBeen: 'Su {{ saqCategoryName }} SAQ ha sido',
  approved: 'APROBADO',
  rejected: 'RECHAZADO',
  next: 'Siguiente',
  back: 'Anterior',
  tryAgain: 'Intentar otra vez',
  save: 'Guardar',
  progressSaved: 'Progreso guardado',
  submit: 'Enviar',
  section: 'Sección',
  of: 'de',
  listEmpty: 'Lista vacía',
  categoryText1: 'Responda las siguientes preguntas y envíe su solicitud',
  categoryText2: 'Seleccione el tipo de producto que está vendiendo',
  categoryText3: 'Tipo de proveedor',
  completeFormText: 'Complete el formulario de especificación del producto',
  certificate: 'cargar',
  fileNotAccepted:
    'El tipo de documento que ha subido no es compatible. Vuelva a intentarlo y asegúrese de que el tipo de documento sea uno de los admitidos en la lista.',
  fileTooBig:
    'El tamaño del documento que ha subido es demasiado grande. Vuelva a intentarlo y asegúrese de que el tamaño del documento sea aceptable.',
  errorTitle: 'Algo salió mal',
  supplierDetails: 'Datos de la Empresa',
  technicalContact: 'Contacto de Control de Calidad',
  productRecallWithdrawalContact: 'Contacto en caso de retiro de producto',
  supplierName: 'Nombre',
  siteAddress: 'Dirección',
  email: 'E-mail',
  sitePhoneNumber: 'Número de teléfono',
  name: 'Nombre',
  contactNumber: 'Número de teléfono',
  outOfHoursContactNumber: 'Contacto para llamar fuera de horario',
  required: 'Este campo es obligatorio',
  requiredDocument: 'Se requiere documento',
  unansweredQuestions: 'Tienes preguntas sin respuesta',
  reviewAnswers: 'Revise sus respuestas antes de enviar la solicitud',
  details: 'datos',
  questions: 'preguntas',
  edit: 'editar',
  tryLater: 'Por favor, inténtelo de nuevo más tarde',
  uploadNewDocument: 'Subir nuevo documento',
  uploadModalInfo:
    'Seleccione el tipo de documento que desea cargar con la información adicional.',
  documentName: 'Nombre del Documento',
  documentType: 'Tipo de Documento',
  uploadedDate: 'Fecha de carga',
  expiryDate: 'Fecha de caducidad',
  noExpiryDate: 'Sin fecha de caducidad',
  cancel: 'cancelar',
  supplierAssessmentQuestionnaire: 'Cuestionario de Proveedor Cumplimentado',
  saq: 'SAQ',
  uploadedDocumentation: 'Documentación cargada',
  notBiggerThan: 'Asegúrese de que el archivo no sea más grande que',
  formatsAre: 'Los formatos aceptados son',
  completeSAQ: 'Completa tu SAQ',
  continueSAQInfo: 'Continúe/envíe para completar el cuestionario.',
  completedSAQ: 'SAQ completado',
  completedSAQInfo:
    'Este SAQ está completo. El estado es {{status}} y se inició en {{date_started}}. Puede revisar sus respuestas a continuación, pero no puede realizar ningún cambio',
  position: 'Cargo',
  add: 'Agregar',
  edited: 'Editado',
  reviewText: 'Revise su Cuestionario',
  reviewComments:
    'Por favor, revise las preguntas marcadas en rojo. Una vez completo, por favor envíe el Cuestionario',
  done: 'Hecho',
  submitted: 'Su SAQ ha sido actualizado y enviado para su aprobación',
  ok: 'Ok',
  completeYourQuestionnaire: `Complete su Cuestionario`,
  incompleteText: `Aún debe terminar y/o enviar su Cuestionario.`,
  rejectedText: 'Este Cuestionario ha sido rechazado el {{date_started}}',
  approvedText:
    'Este Cuestionario ha sido aprobado el {{date_started}}. Puede ver sus respuestas a continuación, pero no puede realizar cambios.',
  awaitingApproval: 'En espera de aprobación',
  awaitingApprovalText:
    'Este Cuestionario ha sido enviado. Puede ver sus respuestas a continuación, pero no puede realizar cambios.',
};

export default ES_ES;
