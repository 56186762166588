import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { ICategories } from '../entities/Categories';

interface ICategoriesState {
  categories: ICategories;
  setCategories: (data: ICategories) => void;
}

const storeName = 'categoriesStore';
const initialCategories: ICategories = [
  {
    id: -1,
    translations: [{ name: '' }],
  },
];

export const useCategories = create<ICategoriesState>()(
  devtools(
    (set) => ({
      categories: initialCategories,
      setCategories: (categories) => {
        set((state) => ({ ...state, categories }));
      },
    }),
    { name: storeName },
  ),
);
