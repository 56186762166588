import { IQuestion, ISAQSections } from '../../entities/SAQ';
import { optionsSeparator } from '../../utils/onChangeFunctions';

export const getAnswer = (question: IQuestion) => {
  switch (question.question_type) {
    case 'YES_NO': {
      const translations =
        question.translations[0].options.split(optionsSeparator);
      if (typeof question.answers[0]?.boolean_answer === 'boolean') {
        return question.answers[0]?.boolean_answer
          ? translations[0]
          : translations[1];
      }
      return '';
    }
    case 'TEXT':
      return question.answers[0]?.text_answer;
    case 'SINGLE_CHOICE':
      // eslint-disable-next-line no-restricted-syntax
      for (const x in question.answers[0]?.json_answer) {
        if (question.answers[0]?.json_answer[x]) {
          return x;
        }
      }
      return '';
    case 'MULTIPLE_CHOICE': {
      const answers = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const x in question.answers[0]?.json_answer) {
        if (question.answers[0]?.json_answer[x]) {
          answers.push(x);
        }
      }
      return answers.join(', ');
    }
    case 'PRODUCT_LIST': {
      return question.answers[0]?.json_answer?.join(', ');
    }
    default:
      return '';
  }
};

export const checkRequiredIf = (
  question: IQuestion,
  section: ISAQSections,
  type = 'question',
) => {
  let required_if;
  let questionToCheck;
  if (type === 'question') {
    if (!question.required_if) {
      return false;
    }
    required_if = question.required_if;
    questionToCheck = section.questions[required_if.question - 1];
  } else {
    if (!question.documents_required_if) {
      return false;
    }
    questionToCheck = question;
    required_if = question.documents_required_if;
  }
  if (typeof required_if.boolean_value === 'boolean') {
    return (
      questionToCheck?.answers[0]?.boolean_answer === required_if.boolean_value
    );
  }
  if (required_if.json_value) {
    const translations =
      questionToCheck?.translations[0]?.options?.split(optionsSeparator) || [];
    const index = translations[required_if.json_value - 1];
    return questionToCheck?.answers[0]?.json_answer[index] === true;
  }
  return true;
};
