import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Tab, Tabs, Typography } from '@mui/material';

import { PATHS } from '../../config/paths';
import { ESAQStatuses } from '../../entities/SAQ';
import Questions from '../../pages/SAQOverview/components/Questions';
import SupplierDetails from '../../pages/SAQOverview/components/SupplierDetails';
import { checkRequiredIf, getAnswer } from '../../pages/SAQOverview/utils';
import Terms from '../../pages/Terms';
import {
  useGetSaqOverview,
  useQuestionsById,
  useSubmitSaq,
} from '../../services/saq';
import { useAuth } from '../../stores/useAuth';
import { useFeedback } from '../../stores/useFeedback';
import { useSAQ } from '../../stores/useSAQ';
import formatDateBasedOnBrowser from '../../utils/formatDateBasedOnBrowser';
import LoadingWrapper from '../LoadingWrapper';

export default function SAQTabPanel() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { saq, setSAQ } = useSAQ();
  const { t } = useTranslation();
  const { setAlert, setToast } = useFeedback();

  const [currentSAQId, setCurrentSAQId] = useState(0);
  const [secondTabsValue, setSecondTabsValue] = useState(0);
  const [unansweredSectionIndex, setUnansweredSectionIndex] = useState(-1);

  const { data: listOfSaqs, isLoading } = useGetSaqOverview({
    language: auth.user?.language,
    supplierId: auth.user?.active_supplier_details.id,
  });

  const { mutate: mutateQuestion, isLoading: isLoadingQuestion } =
    useQuestionsById({
      onSuccess: (response) => {
        setSAQ(response);
        if (response.status === ESAQStatuses.INCOMPLETE) {
          // This should be the default value at the moment the saq was pulled from the backend
          setUnansweredSectionIndex(0);

          response.sections.some((section) => {
            return section.questions.some((question) => {
              const hasUnansweredAnswer =
                (question.is_required || checkRequiredIf(question, section)) &&
                !getAnswer(question);

              if (hasUnansweredAnswer) {
                setUnansweredSectionIndex(section.sequence);
                return true;
              }
              return false;
            });
          });
        }
      },
    });
  const { mutateAsync: mutateSubmit, isLoading: isLoadingSubmit } =
    useSubmitSaq({
      onSuccess: (data) => {
        if (data.status === ESAQStatuses.AWAITING_APPROVAL) {
          setToast({ show: true, type: 'success', message: t('submitted') });
          mutateQuestion({
            supplierId: auth.user?.active_supplier_details.id,
            language: auth.user?.language,
            currentSAQId,
          });
        }
      },
      onError: (error) => {
        if (error?.response?.data?.incomplete_questions?.length) {
          setAlert({
            show: true,
            title: t('unansweredQuestions'),
            message: t('reviewAnswers'),
          });
        } else {
          setAlert({
            show: true,
            title: t('errorTitle'),
            message: t('tryLater'),
          });
        }
      },
    });

  useEffect(() => {
    if (listOfSaqs?.length) {
      setSecondTabsValue(listOfSaqs.length);
      setCurrentSAQId(listOfSaqs[listOfSaqs.length - 1].id);
    }
  }, [listOfSaqs]);

  useEffect(() => {
    if (currentSAQId > 0) {
      mutateQuestion({
        supplierId: auth.user?.active_supplier_details.id,
        language: auth.user?.language,
        currentSAQId,
      });
    }
  }, [
    auth.user?.active_supplier_details.id,
    auth.user?.language,
    currentSAQId,
    mutateQuestion,
  ]);

  const handleSubmit = () => {
    mutateSubmit({ id: saq.id });
  };

  const tabsOfSAQs = () => {
    return listOfSaqs?.map((saqItem, index) => (
      <Tab
        label={`${saqItem.category_when_created} SAQ - ${saqItem.status}`}
        value={index + 1}
        onClick={() => {
          setCurrentSAQId(saqItem.id);
          setSecondTabsValue(index + 1);
        }}
      />
    ));
  };

  const commentsResolved = saq.sections.every((section) =>
    section.questions.every(
      (question) =>
        ((!question.required_if || checkRequiredIf(question, section)) &&
          (question.comments[0]?.resolved || question.comments.length === 0)) ||
        (question.required_if && !checkRequiredIf(question, section)),
    ),
  );

  const showSubmit = () => {
    if (saq.status === ESAQStatuses.PENDING) {
      return (
        <Box
          position="fixed"
          bottom={0}
          display="flex"
          left={0}
          width="100%"
          justifyContent="center"
          p={2}
          borderTop={1}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
          }}
        >
          <Button
            variant="gradient"
            disabled={!commentsResolved}
            onClick={handleSubmit}
          >
            {t('submit')}
          </Button>
        </Box>
      );
    }
    return null;
  };

  const isIncomplete = saq.status === ESAQStatuses.INCOMPLETE;

  return (
    <LoadingWrapper
      loading={isLoading || isLoadingSubmit || isLoadingQuestion}
      doNotShowContent={isLoading}
    >
      <Box mb={15}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={secondTabsValue}
            orientation="horizontal"
            sx={{
              width: '100%',
              backgroundColor: '#fafafa',
              position: 'relative',
            }}
          >
            {tabsOfSAQs()}
          </Tabs>
        </Box>

        {currentSAQId > 0 && (
          <Box sx={{ mt: 4 }}>
            {saq.status === ESAQStatuses.AWAITING_APPROVAL ? (
              <Box bgcolor="rgba(253, 237, 225, 1)" p={2} borderRadius={2}>
                <Typography variant="title">{t('awaitingApproval')}</Typography>
                <Typography>{t('awaitingApprovalText')}</Typography>
              </Box>
            ) : null}

            {saq.status === ESAQStatuses.REJECTED ? (
              <Box bgcolor="rgba(244, 229, 228, 1)" p={2} borderRadius={2}>
                <Typography variant="title">{t('rejected')}</Typography>
                <Typography>
                  {t('rejectedText', {
                    date_started: formatDateBasedOnBrowser(saq.checked_date),
                    interpolation: { escapeValue: false },
                  })}
                </Typography>
              </Box>
            ) : null}

            {saq.status === ESAQStatuses.APPROVED ? (
              <Box bgcolor="rgba(46, 125, 50, 0.12)" p={2} borderRadius={2}>
                <Typography variant="title">{t('approved')}</Typography>
                <Typography>
                  {t('approvedText', {
                    date_started: formatDateBasedOnBrowser(saq.date_approved),
                    interpolation: { escapeValue: false },
                  })}
                </Typography>
              </Box>
            ) : null}

            {isIncomplete ? (
              <Box
                bgcolor="rgba(227, 239, 250, 1)"
                p={2}
                borderRadius={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box sx={{ mb: 2 }}>
                  <Typography variant="title">
                    {t('completeYourQuestionnaire')}
                  </Typography>
                  <Typography>{t(`incompleteText`)}</Typography>
                </Box>

                {unansweredSectionIndex > 0 && (
                  <Box>
                    <Button
                      variant="gradient"
                      onClick={() =>
                        navigate(PATHS.SAQ, {
                          state: {
                            index: unansweredSectionIndex - 1,
                            doCheck: false,
                          },
                        })
                      }
                    >
                      {t('continue')}
                    </Button>
                  </Box>
                )}

                {unansweredSectionIndex === 0 && (
                  <Box>
                    <Button variant="gradient" onClick={handleSubmit}>
                      {t('submit')}
                    </Button>
                  </Box>
                )}
              </Box>
            ) : null}
            {saq.status === ESAQStatuses.PENDING && !auth.user?.is_admin ? (
              <Box
                display="flex"
                bgcolor="rgba(237, 108, 2, 0.12)"
                p={2}
                justifyContent="space-between"
                alignItems="center"
                borderRadius={2}
              >
                <Box>
                  <Typography variant="title">{t('reviewText')}</Typography>
                  <Typography>{t('reviewComments')}</Typography>
                </Box>
              </Box>
            ) : null}
            <Box sx={{ width: '60rem', pt: 1, mb: 4 }}>
              <SupplierDetails />
              <Questions />
              <Terms isOverview />
            </Box>
          </Box>
        )}
        {showSubmit()}
      </Box>
    </LoadingWrapper>
  );
}
