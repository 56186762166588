import { ITranslate } from '..';

const IT_IT: ITranslate = {
  profile: 'Profilo',
  productList: 'Elenco prodotti',
  home: 'Home',
  notifications: 'Notifiche',
  Documents: 'Documenti',
  Suppliers: 'Fornitori',
  products: 'Prodotti',
  settings: 'Impostazioni',
  logout: 'Log Out',
  start: 'Inizia',
  continue: 'Continua',
  review: 'Revisiona',
  view: 'Visualizza',
  defaultSAQText: 'Avvia nuovo Questionario di Valutazione Fornitori.',
  incompleteSAQText: 'Completa il tuo Questionario di Valutazione Fornitori.',
  pendingSAQText: 'Una o più domande sono in attesa di revisione.',
  awaitingApprovalSAQText:
    'Il tuo {{ saqCategoryName }} Questionario è in attesa di approvazione.',
  categoryHasBeen: 'Il tuo {{ saqCategoryName }} Questionario è stato',
  approved: 'APPROVATO.',
  rejected: 'RESPINTO.',
  next: 'Successivo',
  back: 'Precedente',
  tryAgain: 'Riprova',
  save: 'Salva',
  progressSaved: 'Progressi salvati',
  submit: 'Invia',
  section: 'Sezione',
  of: 'di',
  listEmpty: 'Elenco vuoto',
  categoryText1: 'Rispondi alle domande seguenti e invia la tua candidatura',
  categoryText2: 'Seleziona il tipo di prodotto che stai vendendo',
  categoryText3: 'Tipologia di fornitore',
  completeFormText:
    'Si prega di compilare il modulo delle specifiche del prodotto',
  certificate: 'caricamento',
  fileNotAccepted:
    "Il tipo di documento che hai caricato non è supportato. Riprova e assicurati che il tipo di documento sia uno dell'elenco supportato.",
  fileTooBig:
    'La dimensione del documento che hai caricato è troppo grande. Riprova e assicurati che le dimensioni del documento siano accettabili.',
  errorTitle: 'Qualcosa è andato storto',
  supplierDetails: 'Azienda / Dettagli del fornitore',
  technicalContact: 'Ufficio Controllo Qualità',
  productRecallWithdrawalContact: 'Contatto in caso di richiamo',
  supplierName: 'Nome azienda',
  siteAddress: 'Indirizzo',
  email: 'E-mail',
  sitePhoneNumber: 'Telefono',
  name: 'Nome',
  contactNumber: 'Numero',
  outOfHoursContactNumber: 'Contatto da chiamare fuori orario',
  required: 'Questo campo è obbligatorio',
  requiredDocument: 'Document is required',
  unansweredQuestions: 'Il documento è obbligatorio',
  reviewAnswers: 'Si prega di rivedere le risposte prima di inviare la domanda',
  details: 'particolari',
  questions: 'domande',
  edit: 'modificare',
  tryLater: 'Per favore riprova più tardi',
  uploadNewDocument: 'Carica nuovo documento',
  uploadModalInfo:
    'Seleziona il tipo di documento che desideri caricare con le informazioni aggiuntive.',
  documentName: 'Nome del Documento',
  documentType: 'Tipo di Documento',
  uploadedDate: 'Data di Caricamento',
  expiryDate: 'Data di Scadenza',
  noExpiryDate: 'Nessuna data di scadenza',
  cancel: 'Annulla',
  supplierAssessmentQuestionnaire: "Questionario Controllo Qualita'",
  saq: 'SAQ',
  uploadedDocumentation: 'Documentazione caricata',
  notBiggerThan: 'Assicurati che il file non sia più grande di',
  formatsAre: 'I formati accettati sono',
  completeSAQ: 'Completa il tuo SAQ',
  continueSAQInfo:
    'Si prega di continuare/inviare per completare il questionario.',
  completedSAQ: 'SAQ completato',
  completedSAQInfo:
    'Questo SAQ è completato. Lo stato è {{status}} ed è stato avviato il {{date_started}} puoi rivedere le tue risposte di seguito ma non puoi apportare modifiche',
  position: 'Posizione',
  add: 'Aggiungi',
  edited: 'Modificato',
  reviewText: 'Revisiona il tuo Questionario',
  reviewComments:
    'Ti preghiamo di controllare le domande contrassegnate in rosso. Una volta completate, invia il tuo Questionario',
  done: 'Fatto',
  submitted: "Il tuo SAQ è stato aggiornato e inviato per l'approvazione",
  ok: 'Ok',
  completeYourQuestionnaire: `Completa il tuo Questionario`,
  incompleteText: `Devi ancora completare e/o inviare il tuo Questionario.`,
  rejectedText:
    'Il tuo Questionario non e’ stato approvato in data {{date_started}}',
  approvedText:
    'Il tuo Questionario è stato approvato in data {{date_started}}. Puoi visionare le tue risposte, ma non potrai apportare modifiche.',
  awaitingApproval: 'In attesa di approvazione',
  awaitingApprovalText:
    'Il tuo Questionario è stato inviato ed e’ in attesa di approvazione. Puoi visionare le tue risposte, ma non potrai apportare modifiche.',
};

export default IT_IT;
