import { useMutation, UseMutationOptions } from 'react-query';

import { ICategories } from '../entities/Categories';
import { api } from '../libs/axios';
import { ApiErrorDefaultResponse } from '../libs/axios/interceptors';

type ICategoriesRequest = {
  language?: string;
};

type ICategoriesResponse = ICategories;

export const useCategory = (
  options?: UseMutationOptions<
    ICategoriesResponse,
    ApiErrorDefaultResponse,
    ICategoriesRequest
  >,
) => {
  const getCategories = (payload: ICategoriesRequest) =>
    api
      .get<ICategoriesResponse>(
        `supplier-profile/supplier-category?language=${payload.language}`,
      )
      .then((response) => response.data);

  return useMutation('useCategory', getCategories, options);
};

type IUpdateCategoriesRequest = {
  id?: string;
  type?: number;
  category?: number;
};

export const useUpdateCategory = (
  options?: UseMutationOptions<
    unknown,
    ApiErrorDefaultResponse,
    IUpdateCategoriesRequest
  >,
) => {
  const updateCategories = (payload: IUpdateCategoriesRequest) =>
    api.patch(`api/suppliers/v1/${payload.id}`, {
      category: payload.category,
      type: payload.type,
    });

  return useMutation('useUpdateCategory', updateCategories, options);
};
