import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography, Button } from '@mui/material';

import colors from '../../assets/colors';
import LogoNatoora from '../../assets/icons/IconNatoora';
import Container from '../../components/Container';
import { PATHS } from '../../config/paths';

export default function SucessfulSolicited() {
  const navigate = useNavigate();
  return (
    <Container paddingTop={10}>
      <Box
        p={2}
        borderRadius={2}
        minWidth="400px"
        border={`1px solid ${colors.disabledGrey}`}
        bgcolor={colors.white}
      >
        <LogoNatoora height="45px" />
        <Typography variant="title">
          Thank you for submitting you request.
        </Typography>
        <Typography variant="subtitle1">
          We have sent you a password reset link.
        </Typography>
        <Typography variant="subtitle1">
          Please check your email and follow the instructions to reset your
          password.
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection="column"
          gap={3}
          py={6}
        >
          <Button
            type="submit"
            variant="gradient"
            onClick={() => navigate(PATHS.ROOT)}
          >
            Back to login
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
