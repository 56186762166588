import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface IAlert {
  show: boolean;
  title: string;
  message: string;
}

interface IToast {
  show: boolean;
  type: 'success' | 'error';
  message: string;
}

interface IConfirmation {
  show: boolean;
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

interface IFeedbackState {
  alert: IAlert;
  setAlert: (data: IAlert) => void;
  toast: IToast;
  setToast: (data: IToast) => void;
  confirmation: IConfirmation;
  setConfirmation: (data: IConfirmation) => void;
}

const storeName = 'feedbackStore';
const initialAlert: IAlert = {
  show: false,
  title: '',
  message: '',
};

const initialToast: IToast = {
  show: false,
  type: 'success',
  message: '',
};

const initialConfirmation: IConfirmation = {
  show: false,
  title: '',
  message: '',
  onConfirm: () => null,
  onCancel: () => null,
};

export const useFeedback = create<IFeedbackState>()(
  devtools(
    (set) => ({
      alert: initialAlert,
      setAlert: (alert) => {
        set((state) => ({ ...state, alert }));
      },
      toast: initialToast,
      setToast: (toast) => {
        set((state) => ({ ...state, toast }));
      },
      confirmation: initialConfirmation,
      setConfirmation: (confirmation) => {
        set((state) => ({ ...state, confirmation }));
      },
    }),
    { name: storeName },
  ),
);
