import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { da, enAU, enGB, fr, it, enUS } from 'date-fns/locale';
import moment, { Moment } from 'moment';

import colors from '../../assets/colors';
import {
  useGetDocumentType,
  useSaveDocuments,
} from '../../services/supplierDocuments';
import { useAuth } from '../../stores/useAuth';
import { useSAQ } from '../../stores/useSAQ';
import { changeDocument } from '../../utils/onChangeFunctions';
import LoadingWrapper from '../LoadingWrapper';
import Modal from '../Modal';
import UploadButton from '../UploadButton';
import UploadedDocument from '../UploadedDocument';

interface IUploadNewDocumentsModal {
  open: boolean;
  setOpen: (open: boolean) => void;
  questionId?: number;
  afterUpload?: () => void;
  currentSection?: number;
}

export default function UploadNewDocumentsModal({
  open,
  setOpen,
  questionId,
  afterUpload,
  currentSection,
}: IUploadNewDocumentsModal) {
  const { auth } = useAuth();
  const { saq, setSAQ } = useSAQ();
  const { t } = useTranslation();
  const [expirationDate, setExpirationDate] = useState<Moment | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [type, setType] = useState<string>('');
  const [showErrors, setShowErrors] = useState(false);
  const [noExpire, setNoExpire] = useState(false);
  const { isLoading, data: documentTypes } = useGetDocumentType({
    language: auth.user?.language,
  });

  const clearData = () => {
    setFiles([]);
    setShowErrors(false);
    setType('');
    setExpirationDate(null);
    setNoExpire(false);
  };

  const { mutate: mutateDocument, isLoading: documentIsLoading } =
    useSaveDocuments({
      onSuccess: (response) => {
        if (questionId && currentSection) {
          let tempSaq = { ...saq };

          // TODO: FIXME
          response.forEach((document: any) => {
            tempSaq = changeDocument(
              questionId,
              document,
              tempSaq,
              currentSection,
            );
          });

          setSAQ(tempSaq);
        }
        setOpen(false);
        clearData();

        if (afterUpload) {
          afterUpload();
        }
      },
    });

  const handleFileSelection = (file: File) => {
    setFiles([...files, file]);
  };

  const removeFile = (index: number) => {
    const tempFiles = [...files];
    tempFiles.splice(index, 1);
    setFiles(tempFiles);
  };

  const displayFiles = () => {
    return files.map((file, index) => (
      <UploadedDocument name={file.name} remove={() => removeFile(index)} />
    ));
  };

  const saveDocuments = () => {
    const hasErrors = !type || !files.length || (!expirationDate && !noExpire);
    setShowErrors(hasErrors);
    if (!hasErrors) {
      mutateDocument({
        supplier_question: questionId,
        documents: files,
        type,
        expiry_date: expirationDate
          ? expirationDate.format('YYYY-MM-DD')
          : expirationDate,
      });
    }
  };

  const languageSwitcher = () => {
    const atualLanguage = navigator.language;
    let language;
    switch (atualLanguage) {
      case 'en-US':
        language = enUS;
        break;
      case 'da':
        language = da;
        break;
      case 'it':
        language = it;
        break;
      case 'fr':
        language = fr;
        break;
      case 'en-AU':
        language = enAU;
        break;
      default:
        language = enGB;
    }
    return language;
  };

  return (
    <LoadingWrapper
      loading={isLoading || documentIsLoading}
      doNotShowContent={isLoading}
    >
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={{ mb: 3 }}>
          <Typography variant="title" fontWeight="semibold">
            {t('uploadNewDocument')}
          </Typography>
          <Typography variant="subtitle1">{t('uploadModalInfo')}</Typography>
          <Box sx={{ mt: 3 }}>
            <FormControl
              sx={{
                width: '50%',
              }}
            >
              <InputLabel
                sx={{
                  color: showErrors && !type ? colors.error : 'inherit',
                }}
              >
                {t('documentType')}
              </InputLabel>
              <Select
                variant="standard"
                value={type}
                onChange={(e) => setType(e.target.value)}
                sx={{
                  '&::before': {
                    borderColor: showErrors && !type ? colors.error : 'inherit',
                  },
                }}
              >
                {documentTypes?.map((documentType) => (
                  <MenuItem key={documentType.id} value={documentType.id}>
                    {documentType.translations[0].name}
                  </MenuItem>
                ))}
              </Select>

              {showErrors && !type && (
                <FormHelperText sx={{ color: colors.error }}>
                  {t('required')}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box sx={{ mt: 3, mb: 4, display: 'flex' }}>
            <Box width="50%">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={languageSwitcher()}
              >
                <DatePicker
                  label={t('expiryDate')}
                  value={expirationDate}
                  minDate={moment().toDate()}
                  onChange={(date) => setExpirationDate(moment(date))}
                  disabled={noExpire}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{ width: '100%' }}
                      helperText={
                        showErrors && !expirationDate && !noExpire
                          ? 'required'
                          : ''
                      }
                      error={showErrors && !expirationDate && !noExpire}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box marginLeft={5}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={noExpire}
                    onChange={() => {
                      setNoExpire(!noExpire);
                      setExpirationDate(null);
                    }}
                  />
                }
                label={t('noExpiryDate')}
              />
            </Box>
          </Box>
          <Box sx={{ marginBottom: 2 }}>
            <UploadButton
              label={t('certificate')}
              onChange={handleFileSelection}
              questionId={1}
            />
            {showErrors && !files.length && (
              <FormHelperText sx={{ color: colors.error }}>
                {t('required')}
              </FormHelperText>
            )}
          </Box>
          <Box>{displayFiles()}</Box>
        </Box>
        <Divider sx={{ width: '100%' }} />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-evenly',
            paddingTop: 2,
          }}
        >
          <Button
            variant="gradient"
            color="secondary"
            component="span"
            onClick={() => {
              setOpen(false);
              clearData();
            }}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="gradient"
            color="primary"
            component="span"
            onClick={saveDocuments}
          >
            {t('save')}
          </Button>
        </Box>
      </Modal>
    </LoadingWrapper>
  );
}
