import { useMutation, UseMutationOptions } from 'react-query';

import { IAuth } from '../entities/Auth';
import { api } from '../libs/axios';
import { ApiErrorDefaultResponse } from '../libs/axios/interceptors';
import i18n from '../libs/i18n';

type LoginRequest = {
  email: string;
  password: String;
};

type LoginResponse = IAuth;

export const useLogin = (
  options?: UseMutationOptions<
    LoginResponse,
    ApiErrorDefaultResponse,
    LoginRequest
  >,
) => {
  const getUser = (payload: LoginRequest) =>
    api.post<LoginResponse>('users/auth/login/', payload).then((response) => {
      i18n.changeLanguage(response.data.user?.language);
      return response.data;
    });

  return useMutation('useLogin', getUser, options);
};

export const useForgotPassword = (
  options?: UseMutationOptions<
    LoginResponse,
    ApiErrorDefaultResponse,
    LoginRequest['email']
  >,
) => {
  const forgotPassword = (payload: LoginRequest['email']) =>
    api
      .post<LoginResponse>(`users/request-password-reset-email`, {
        email: payload,
      })
      .then((response) => response.data);

  return useMutation('useForgotPassword', forgotPassword, options);
};

type passwordRequest = {
  userId?: string;
  token?: string;
  password: string;
};

export const useSetPassword = (
  options?: UseMutationOptions<
    LoginResponse,
    ApiErrorDefaultResponse,
    passwordRequest
  >,
) => {
  const setPassword = (payload: passwordRequest) =>
    api
      .post<LoginResponse>(`users/activate/${payload.userId}`, {
        token: payload.token,
        password: payload.password,
      })
      .then((response) => response.data);

  return useMutation('useSetPassword', setPassword, options);
};

export const useResetPassword = (
  options?: UseMutationOptions<
    LoginResponse,
    ApiErrorDefaultResponse,
    passwordRequest
  >,
) => {
  const resetPassword = (payload: passwordRequest) =>
    api
      .post<LoginResponse>(`users/set-password/${payload.userId}`, {
        token: payload.token,
        password: payload.password,
      })
      .then((response) => response.data);

  return useMutation('useResetPassword', resetPassword, options);
};
