export default {
  white: '#FFF',
  black: '#000',
  background: '#FAFAFA',
  shadow: '#0000003D',
  lightButtonBlue: '#07BAF1',
  lightBackgroundBlue: 'rgba(2, 136, 209, 0.12)',
  mainBlue: '#058DD5',
  darkButtonBlue: '#0781CE',
  darkGrey: '#343333',
  lightGrey: '#676767',
  lightBackgroundGrey: 'rgba(245, 244, 244, 1)',
  disabledGrey: '#999999',
  greyRow: 'rgba(175, 175, 175, 0.1)',
  errorRow: 'rgba(235, 92, 52, 0.1)',
  borderGrey: '#dfe1e5',
  text: '#555555',
  error: '#EC613C',
  backgroundError: 'rgba(246, 233, 233)',
  persianPlum: '#4a1212',
};
