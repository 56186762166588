import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Box, Button } from '@mui/material';

import colors from '../../assets/colors';
import Modal from '../Modal';

type IModalAlert = {
  title: string;
  subtitle: string;
  open: boolean;
  onClose: () => void;
};

export default function ModalAlert({
  title,
  subtitle,
  open,
  onClose,
}: IModalAlert) {
  const { t } = useTranslation();
  return (
    <Modal onClose={onClose} open={open} timeout={500}>
      <img src="./error.png" alt="Error" width="70" height="70" />
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography align="center" variant="title" fontWeight="semibold">
          {title}
        </Typography>
        <Typography align="center" variant="subquestion" color={colors.text}>
          {subtitle}
        </Typography>
      </Box>

      <Button
        variant="gradient"
        color="secondary"
        size="medium"
        onClick={onClose}
      >
        {t('tryAgain')}
      </Button>
    </Modal>
  );
}
