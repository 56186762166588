import React from 'react';

const SvgIconOrders = (props) => (
  <svg viewBox="0 0 55 56.7" {...props}>
    <path
      className="icon-orders_svg__st0"
      fill="#999"
      d="M36.1 23.3l-6.6-6.6c-.2-.2-.5-.3-.8-.3h-8.8c-1.8 0-3.3 1.5-3.3 3.3v17.7c0 1.8 1.5 3.3 3.3 3.3h13.2c1.8 0 3.3-1.5 3.3-3.3V24.1c0-.3-.1-.6-.3-.8zm-1.9 14.1c0 .6-.5 1.1-1.1 1.1H19.9c-.6 0-1.1-.5-1.1-1.1V19.7c0-.6.5-1.1 1.1-1.1h8.4l6 6-.1 12.8z"
    />
    <path
      className="icon-orders_svg__st0"
      fill="#999"
      d="M22.1 33h8.8v2.2h-8.8zM22.1 28.6h8.8v2.2h-8.8zM26.8 25.9h6l-6-6.1z"
    />
  </svg>
);

export default SvgIconOrders;
