import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Divider, Typography, Button } from '@mui/material';

import Container from '../../components/Container/index';
import LoadingWrapper from '../../components/LoadingWrapper';
import Paper from '../../components/Paper';
import QuestionSwitch from '../../components/QuestionSwitch';
import { PATHS } from '../../config/paths';
import { IQuestionsAnswers } from '../../entities/SAQ';
import { useQuestions, useSaveAnswers } from '../../services/saq';
import { useAuth } from '../../stores/useAuth';
import { useFeedback } from '../../stores/useFeedback';
import { useSAQ } from '../../stores/useSAQ';

type IState = {
  doCheck: boolean;
  index: number;
};

function SAQ() {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const { saq, currentSection, setCurrentSection, setSAQ } = useSAQ();
  const { setToast } = useFeedback();

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as IState;

  const { mutate: mutateAnswers, isLoading: answerSavingloading } =
    useSaveAnswers({
      onSuccess: (data, { callback }) => {
        if (callback) {
          callback(data);
        }
      },
      onError: () => {
        setToast({
          show: true,
          type: 'error',
          message: 'An error occurred while saving!',
        });
      },
    });

  useEffect(() => {
    if (state?.index || state?.index === 0) {
      setCurrentSection(state.index);
    }
  }, [state]);

  const { mutate: mutateQuestion, isLoading: questionsLoading } = useQuestions({
    onSuccess: (response) => {
      setSAQ(response);
    },
  });

  useEffect(() => {
    mutateQuestion({
      supplierId: auth.user?.active_supplier_details.id,
      language: auth.user?.language,
    });
  }, []);

  const displayQuestions = () => {
    // Order questions by sequence instead of ID to avoid problems in required fields
    saq.sections[currentSection].questions.sort((a, b) => {
      return a.sequence - b.sequence;
    });
    const toShow = saq.sections[currentSection].questions.map(
      (question, questionIndex) => (
        <QuestionSwitch
          key={question.id}
          question={question}
          questionIndex={questionIndex}
          section={saq.sections[currentSection]}
          doCheck={state?.doCheck}
          currentSection={currentSection}
        />
      ),
    );
    return toShow;
  };

  const removeEmptyProducts = () => {
    const tempSaq = { ...saq };
    tempSaq.sections[currentSection].questions = tempSaq.sections[
      currentSection
    ].questions.map((question) => {
      if (
        question.question_type === 'PRODUCT_LIST' &&
        question.answers.length
      ) {
        const tempQuestion = { ...question };
        const jsonAnswer = tempQuestion.answers[0].json_answer.filter(
          (product: string) => product.trim(),
        );
        tempQuestion.answers[0].json_answer = jsonAnswer;
        return tempQuestion;
      }
      return question;
    });
    return tempSaq;
  };

  const saveSection = (
    callback: (data: IQuestionsAnswers[]) => void | undefined,
  ) => {
    const tempSaq = removeEmptyProducts();
    const answers = tempSaq.sections[currentSection].questions
      .filter((question) => question.answers.length)
      .map((question) => {
        return question.answers[0];
      });
    mutateAnswers({
      answers,
      callback,
    });
    setSAQ(tempSaq);
  };

  const handleSaveClick = () => {
    saveSection(() => {
      setToast({
        show: true,
        type: 'success',
        message: 'Progress Saved!',
      });
    });
  };

  const handleSaveRequestResponse = (response: IQuestionsAnswers[]) => {
    const tempSaq = { ...saq };
    const { questions } = tempSaq.sections[currentSection];
    response.forEach((answer: IQuestionsAnswers) => {
      questions.forEach((question, x) => {
        if (questions[x].id === answer.question) {
          questions[x].answers[0].id = answer.id;
        }
      });
      return null;
    });
  };

  const changeSection = (type: string) => {
    let tempCurrentSection = currentSection;

    if (type === 'next') {
      tempCurrentSection += 1;
      if (tempCurrentSection >= saq.sections.length) {
        saveSection((response) => {
          handleSaveRequestResponse(response);
          navigate(PATHS.TERMS);
        });
      } else {
        saveSection((response) => {
          handleSaveRequestResponse(response);
          setCurrentSection(tempCurrentSection);
          window.scrollTo(0, 0);
        });
      }
    } else {
      tempCurrentSection -= 1;
      if (tempCurrentSection >= 0) {
        setCurrentSection(tempCurrentSection);
      } else {
        navigate(PATHS.DETAILS);
      }
    }
  };

  return (
    <LoadingWrapper
      loading={!saq?.sections.length || questionsLoading || answerSavingloading}
      doNotShowContent={!saq?.sections.length}
    >
      <Container paddingTop={2}>
        <Paper width={46}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="pagetitle" flex={0.8}>
              {saq?.sections[currentSection]?.translations[0]?.title}
            </Typography>
            <Typography variant="pagenumber" flex={0.2}>
              {t('section')} {currentSection + 1} {t('of')}{' '}
              {saq?.sections.length}
            </Typography>
          </Box>
          {saq?.sections[currentSection]?.translations[0]?.description && (
            <Typography variant="question" fontWeight={300}>
              {saq?.sections[currentSection]?.translations[0]?.description}
            </Typography>
          )}
          <Divider sx={{ marginTop: 2 }} />
          <Box display="flex" flexDirection="column" gap={1}>
            {!!saq?.sections.length && displayQuestions()}
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            margin="20px 0"
            data-testid="buttons-container"
          >
            <Button
              variant="gradient"
              color="secondary"
              onClick={() => handleSaveClick()}
            >
              {t('save')}
            </Button>
            <Box display="flex" gap={3}>
              <Button
                variant="gradient"
                color="secondary"
                onClick={() => changeSection('back')}
              >
                {t('back')}
              </Button>
              <Button variant="gradient" onClick={() => changeSection('next')}>
                {t('next')}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
    </LoadingWrapper>
  );
}

export default SAQ;
