import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { PATHS } from '../../../config/paths';
import { ESAQStatuses } from '../../../entities/SAQ';
import { useSupplierDetails } from '../../../services/supplierProfile';
import { useAuth } from '../../../stores/useAuth';
import { useSAQ } from '../../../stores/useSAQ';
import Details from './Details';
import Section from './Section';

type IState = {
  supplier: number;
};

export default function SupplierDetails() {
  const { auth } = useAuth();
  const { saq } = useSAQ();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as IState;
  const { mutate: getSupplierDetails, data: detailsData } =
    useSupplierDetails();

  useEffect(() => {
    getSupplierDetails({
      supplierId:
        auth.user?.active_supplier_details?.id || state.supplier.toString(),
    });
  }, []);

  return (
    <>
      <Section
        title={t('supplierDetails')}
        header={t('details')}
        onEdit={() => navigate(PATHS.DETAILS)}
        disableSectionEditButton={saq.status !== ESAQStatuses.INCOMPLETE}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          p={2}
          paddingRight={12}
        >
          <Details name={t('supplierName')} value={detailsData?.general_name} />
          <Details name={t('email')} value={detailsData?.general_email} />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          p={2}
          paddingRight={12}
        >
          <Box>
            <Details
              name={t('siteAddress')}
              value={detailsData?.general_address}
            />
          </Box>
        </Box>
      </Section>

      <Section
        title={t('technicalContact')}
        header={t('details')}
        onEdit={() => navigate(PATHS.DETAILS)}
        disableSectionEditButton={saq.status !== ESAQStatuses.INCOMPLETE}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          p={2}
          paddingRight={12}
        >
          <Details name={t('name')} value={detailsData?.technical_name} />
          <Details name={t('email')} value={detailsData?.technical_email} />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          p={2}
          paddingRight={12}
        >
          <Box>
            <Details
              name={t('contactNumber')}
              value={detailsData?.technical_phone_number}
            />
          </Box>
        </Box>
      </Section>

      <Section
        title={t('productRecallWithdrawalContact')}
        header={t('details')}
        onEdit={() => navigate(PATHS.DETAILS)}
        disableSectionEditButton={saq.status !== ESAQStatuses.INCOMPLETE}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          p={2}
          paddingRight={12}
        >
          <Details name={t('name')} value={detailsData?.product_recall_name} />
          <Details
            name={t('email')}
            value={detailsData?.product_recall_email}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          p={2}
          paddingRight={12}
        >
          <Box>
            <Details
              name={t('contactNumber')}
              value={detailsData?.product_recall_phone_number}
            />
          </Box>
          <Box>
            <Details
              name={t('contactNumber')}
              value={detailsData?.product_recall_ooh_number}
            />
          </Box>
        </Box>
      </Section>
    </>
  );
}
