import React from 'react';

import { Backdrop, CircularProgress } from '@mui/material';

import colors from '../../assets/colors';

interface ILoading {
  loading: boolean;
}

export default function Loading({ loading }: ILoading) {
  return loading ? (
    <>
      <Backdrop open sx={{ zIndex: 99999 }}>
        <CircularProgress sx={{ color: colors.white }} />
      </Backdrop>
    </>
  ) : (
    // Used to stop typescript error.
    <></>
  );
}
