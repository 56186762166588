import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Button, Tab, Tabs } from '@mui/material';

import Container from '../../../../components/Container';
import LoadingWrapper from '../../../../components/LoadingWrapper';
import Paper from '../../../../components/Paper';
import TabPanel from '../../../../components/TabPanel';
import UploadedDocumentation from '../../../../components/UploadedDocumentation';
import { ESAQStatuses } from '../../../../entities/SAQ';
import {
  InitialStateSupplierDetails,
  ISupplierDetails,
} from '../../../../entities/SupplierDetails';
import {
  useGetSaqOverview,
  useQuestionsById,
  useSetSAQStatus,
} from '../../../../services/saq';
import { useSupplierDetails } from '../../../../services/supplierProfile';
import { useAuth } from '../../../../stores/useAuth';
import { useFeedback } from '../../../../stores/useFeedback';
import { useSAQ } from '../../../../stores/useSAQ';
import SAQContent from './SAQContent';

type IState = {
  supplier: number;
};
export default function AdminSupplierSAQ() {
  const [firstTabsValue, setFirstTabsValue] = useState(1);
  const [secondTabsValue, setSecondTabsValue] = useState(0);
  const [currentSAQId, setCurrentSAQId] = useState(0);
  const { auth } = useAuth();
  const { saq, setSAQ } = useSAQ();
  const location = useLocation();
  const { setToast, setConfirmation } = useFeedback();
  const state = location.state as IState;
  const {
    data: listOfSaqs,
    isLoading: overviewLoading,
    refetch: refetchlistOfSaqs,
  } = useGetSaqOverview({
    language: auth.user?.language,
    supplierId: state.supplier.toString(),
  });

  const { mutate: mutateQuestion, isLoading: questionLoading } =
    useQuestionsById({
      onSuccess: (response) => {
        setSAQ(response);
      },
    });

  const [detailsData, setDetailsData] = useState<ISupplierDetails>(
    InitialStateSupplierDetails,
  );

  const { mutate: getSupplierDetails, isLoading: detailsLoading } =
    useSupplierDetails({
      onSuccess: (response) => {
        setDetailsData(response);
      },
    });

  const { mutate: mutateSaqStatus, isLoading: saqStatusLoading } =
    useSetSAQStatus({
      onSuccess: () => {
        setToast({
          show: true,
          type: 'success',
          message: 'Status changed sucessfully',
        });
        mutateQuestion({
          supplierId: state.supplier.toString(),
          language: auth.user?.language,
          currentSAQId,
        });
        refetchlistOfSaqs();
      },
      onError: () => {
        setToast({
          show: true,
          type: 'error',
          message: 'An error occurred while saving the status',
        });
      },
    });
  const loadCurrentSAQ = () => {
    mutateQuestion({
      supplierId: state.supplier.toString(),
      language: auth.user?.language,
      currentSAQId,
    });
  };

  useEffect(() => {
    getSupplierDetails({
      supplierId: state.supplier.toString(),
    });
  }, []);

  useEffect(() => {
    if (Array.isArray(listOfSaqs)) {
      const tempListOfSaqs = listOfSaqs.filter(
        (saq) => saq.status !== ESAQStatuses.INCOMPLETE,
      );
      if (!secondTabsValue && !currentSAQId) {
        setSecondTabsValue(tempListOfSaqs.length);
        setCurrentSAQId(tempListOfSaqs[tempListOfSaqs.length - 1]?.id || 0);
      }
    }
  }, [listOfSaqs]);

  useEffect(() => {
    if (currentSAQId > 0) {
      loadCurrentSAQ();
    }
  }, [currentSAQId]);

  const handleTabChange = (
    event: React.SyntheticEvent,
    firstTabsValue: number,
  ) => {
    setFirstTabsValue(firstTabsValue);
  };

  const handleStatusChange = (status: string) => {
    if (saq.status === ESAQStatuses.AWAITING_APPROVAL) {
      mutateSaqStatus({
        saqId: saq.id,
        status,
      });
    } else if (saq.status !== status) {
      setConfirmation({
        show: true,
        title: 'Change SAQ Status',
        message:
          'Are you sure you want to change the status to Pending/Reject/Approve?\nPlease note that once confirmed, an email will be sent to the supplier with the new SAQ status',
        onConfirm: () =>
          mutateSaqStatus({
            saqId: saq.id,
            status,
          }),
        onCancel: () => null,
      });
    } else {
      setToast({
        show: true,
        type: 'error',
        message: 'SAQ is already on selected status',
      });
    }
  };

  const tabsOfSAQs = () => {
    return listOfSaqs
      ?.filter((saq) => saq.status !== ESAQStatuses.INCOMPLETE)
      ?.map((saqItem, index) => (
        <Tab
          label={`${saqItem.category_when_created} SAQ - ${saqItem.status}`}
          value={index + 1}
          onClick={() => {
            setCurrentSAQId(saqItem.id);
            setSecondTabsValue(index + 1);
          }}
        />
      ));
  };

  return (
    <LoadingWrapper
      loading={
        detailsLoading || overviewLoading || questionLoading || saqStatusLoading
      }
    >
      <Container paddingBottom={0}>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: 'background.paper',
            display: 'flex',
            height: window.innerHeight - 64,
          }}
        >
          <Tabs
            orientation="vertical"
            value={firstTabsValue}
            onChange={handleTabChange}
            sx={{
              borderRight: 1,
              borderColor: 'divider',
              width: '20%',
              position: 'fixed',
              height: '100%',
              backgroundColor: '#fff',
            }}
          >
            <Tab label="Saq" value={1} />
            <Tab label="Documents" value={2} />
          </Tabs>
          <TabPanel value={firstTabsValue} index={1}>
            <Box id="supplier_overview_pdf" mb={14}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={secondTabsValue}
                  orientation="horizontal"
                  sx={{ width: '100%' }}
                >
                  {tabsOfSAQs()}
                </Tabs>
              </Box>
              {/* Everything that should appear in the PDF should go inside the Paper element */}
              {currentSAQId > 0 && (
                <>
                  <Paper width={60} marginTop={4} marginBottom={4}>
                    <SAQContent
                      detailsData={detailsData}
                      reload={loadCurrentSAQ}
                    />
                  </Paper>
                  <Box
                    position="fixed"
                    bottom={0}
                    display="flex"
                    left={0}
                    width="100%"
                    justifyContent="space-around"
                    p={2}
                    borderTop={1}
                    sx={{
                      backgroundColor: 'rgba(255, 255, 255, 1)',
                      borderTopColor: '#cecece',
                    }}
                  >
                    <Box>
                      <Button
                        variant="outlined"
                        onClick={() => handleStatusChange(ESAQStatuses.PENDING)}
                      >
                        Pending
                      </Button>
                    </Box>
                    <Box display="flex" gap={2}>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() =>
                          handleStatusChange(ESAQStatuses.REJECTED)
                        }
                      >
                        Reject
                      </Button>
                      <Button
                        variant="gradient"
                        onClick={() =>
                          handleStatusChange(ESAQStatuses.APPROVED)
                        }
                      >
                        Approve
                      </Button>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </TabPanel>
          <TabPanel value={firstTabsValue} index={2}>
            <UploadedDocumentation />
          </TabPanel>
        </Box>
      </Container>
    </LoadingWrapper>
  );
}
