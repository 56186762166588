import React from 'react';

const SvgBallsLogo = (props) => (
  <svg viewBox="0 0 1024 305.8" {...props}>
    <path d="M586.9,152.3c0,82.2-66,148.1-148.1,148.1s-148.1-66-148.1-148.1S356.6,4.2,438.8,4.2C520.9,2.9,586.9,70.2,586.9,152.3" />
    <path d="M5.6,4.2c80.9,0,146.9,66,146.9,146.9S86.5,297.9,5.6,297.9C5.6,297.9,5.6,4.2,5.6,4.2z" />
    <path d="M1018.9,152.3c0-82.2-66-148.1-148.1-148.1s-148.1,66-148.1,148.1s66,148.1,148.1,148.1L1018.9,152.3z" />
  </svg>
);

export default SvgBallsLogo;
