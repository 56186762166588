import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import CommentIcon from '@mui/icons-material/Comment';
import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import * as yup from 'yup';

import colors from '../../../../../assets/colors';
import LoadingWrapper from '../../../../../components/LoadingWrapper';
import Modal from '../../../../../components/Modal';
import { IQuestion } from '../../../../../entities/SAQ';
import { useYupValidationResolver } from '../../../../../hooks/useYupValidationResolver';
import { useSubmitComment } from '../../../../../services/saq';
import { useSAQ } from '../../../../../stores/useSAQ';

interface IComment {
  questionId: number;
}

interface Form {
  comment: string;
}

const schema = yup.object().shape({
  comment: yup.string().required('This field may not be blank'),
});

export default function Comment({ questionId }: IComment) {
  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>({
    resolver: useYupValidationResolver(schema),
  });
  const { saq, setSAQ } = useSAQ();
  const [modalOpen, setModalOpen] = useState(false);
  const [question, setQuestion] = useState<IQuestion | null>(null);
  const { mutate: mutateComment, isLoading: commentIsLoading } =
    useSubmitComment({
      onSuccess: () => {
        const tempSAQ = { ...saq };
        tempSAQ.sections.every((section) => {
          const question = section.questions.find(
            (question) => question.id === questionId,
          );
          if (question) {
            question.comments.unshift({ comment: getValues('comment') });
            return false;
          }
          return true;
        });
        setSAQ(tempSAQ);
        setModalOpen(false);
      },
    });

  useEffect(() => {
    const tempSAQ = { ...saq };
    tempSAQ.sections.every((section) => {
      const question = section.questions.find(
        (question) => question.id === questionId,
      );
      if (question) {
        setQuestion(question);
        return false;
      }
      return true;
    });
  }, [questionId]);

  const saveComment = ({ comment }: Form) => {
    mutateComment({ supplier_saq_question: questionId, comment });
  };

  return (
    <LoadingWrapper loading={commentIsLoading}>
      <CommentIcon
        sx={{
          cursor: 'pointer',
          color: question?.comments.length ? colors.error : colors.lightGrey,
        }}
        onClick={() => setModalOpen(true)}
      />
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <form onSubmit={handleSubmit(saveComment)}>
          <Box>
            <Box>
              <Typography variant="title">Add a comment</Typography>
              <Typography variant="subtitle1" sx={{ mb: 6 }}>
                Insert your comment below for Supplier&apos;s review
              </Typography>
              <TextField
                {...register('comment')}
                label="Insert"
                error={!!errors.comment}
                helperText={errors.comment && errors?.comment?.message}
                fullWidth
                multiline
                rows={3}
                inputProps={{ maxLength: 300 }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-evenly',
                paddingTop: 6,
              }}
            >
              <Button
                variant="gradient"
                color="secondary"
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button variant="gradient" color="primary" type="submit">
                Save
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
    </LoadingWrapper>
  );
}
