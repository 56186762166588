import React from 'react';
import { useTranslation } from 'react-i18next';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { IQuestion } from '../../entities/SAQ';
import { useGetDocumentType } from '../../services/supplierDocuments';
import { useAuth } from '../../stores/useAuth';
import { handleDownload } from '../../utils/downloadFiles';
import formatDateBasedOnBrowser from '../../utils/formatDateBasedOnBrowser';

interface ISAQAdminDocuments {
  question: IQuestion;
}

export default function SAQAdminDocuments({ question }: ISAQAdminDocuments) {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const { data: documentTypes } = useGetDocumentType({
    language: auth.user?.language,
  });

  const downloadFile = (id: number) => {
    const document = question.documents.find(
      (document: any) => document.id === id,
    );
    handleDownload(document.document, document.document_name);
  };

  const showDocuments = () => {
    return question.documents.map((document: any) => {
      return (
        <TableRow>
          <TableCell>
            {
              documentTypes?.find((type) => type.id === document.type)
                ?.translations[0].name
            }
          </TableCell>
          <TableCell>{document.document_name}</TableCell>
          <TableCell>
            {document.expiry_date
              ? formatDateBasedOnBrowser(document.expiry_date)
              : t('noExpiryDate')}
          </TableCell>
          <TableCell style={{ display: 'flex' }}>
            <FileDownloadIcon
              color="primary"
              onClick={() => downloadFile(document.id)}
              sx={{ cursor: 'pointer' }}
            />
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <TableContainer sx={{ width: '70%' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Expire Date</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>{showDocuments()}</TableBody>
      </Table>
    </TableContainer>
  );
}
