import axios from 'axios';
import fileDownload from 'js-file-download';

export const handleDownload = (url, filename, event) => {
  if (event) {
    event.preventDefault();
  }
  axios
    .get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, filename);
    })
    .catch(() => {
      window.open(url);
    });
};
