import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Button } from '@mui/material';

import Container from '../../components/Container/index';
import DisplayDetailsQuestion from '../../components/DisplayDetailsQuestion';
import { suppliersDetailsValidationSchema } from '../../components/DisplayDetailsQuestion/helpers';
import LoadingWrapper from '../../components/LoadingWrapper';
import Paper from '../../components/Paper';
import { PATHS } from '../../config/paths';
import {
  InitialStateSupplierDetails,
  ISupplierDetails,
} from '../../entities/SupplierDetails';
import { useYupValidationResolver } from '../../hooks/useYupValidationResolver';
import {
  useSaveSupplierDetails,
  useSupplierDetails,
  useUpdateSupplierDetails,
} from '../../services/supplierProfile';
import { useAuth } from '../../stores/useAuth';

function SupplierDetails() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [detailsData, setDetailsData] = useState<ISupplierDetails>(
    InitialStateSupplierDetails,
  );
  const { control, reset, handleSubmit } = useForm({
    resolver: useYupValidationResolver(suppliersDetailsValidationSchema),
    defaultValues: detailsData,
  });

  const { mutate: getSupplierDetails, isLoading: getLoading } =
    useSupplierDetails({
      onSuccess: (response) => {
        setDetailsData(response);
        reset(response);
      },
    });
  const { mutate: updateSupplierDetails, isLoading: updateLoading } =
    useUpdateSupplierDetails();
  const { mutate: saveSupplierDetails, isLoading: saveLoading } =
    useSaveSupplierDetails();

  useEffect(() => {
    getSupplierDetails({
      supplierId: auth.user?.active_supplier_details.id,
    });
  }, [auth.user?.active_supplier_details.id, getSupplierDetails]);

  const submitFunction = (payload: any) => {
    if (detailsData?.id) {
      updateSupplierDetails(payload);
    } else {
      saveSupplierDetails(payload);
    }
    navigate(PATHS.SAQ, { state: { index: 0 } });
  };

  return (
    <LoadingWrapper loading={saveLoading || updateLoading || getLoading}>
      <Container paddingTop={2}>
        <Paper width={46} form onSubmit={handleSubmit(submitFunction)}>
          <DisplayDetailsQuestion control={control} />
          <Box display="flex" justifyContent="space-between" mt={5}>
            <Button
              variant="gradient"
              color="secondary"
              onClick={() => navigate(PATHS.CATEGORY)}
            >
              {t('back')}
            </Button>
            <Box display="flex">
              <Button variant="gradient" type="submit">
                {t('next')}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
    </LoadingWrapper>
  );
}
export default SupplierDetails;
