import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField, Typography, Box, FormHelperText } from '@mui/material';

import { IQuestion } from '../../entities/SAQ';
import { getAnswer } from '../../pages/SAQOverview/utils';
import { useSAQ } from '../../stores/useSAQ';
import { changeText } from '../../utils/onChangeFunctions';
import UploadButtonLayout from '../UploadButtonLayout';
import UploadedDocumentsSAQ from '../UploadedDocumentsSAQ';
import UploadNewDocumentsModal from '../UploadNewDocumentsModal';

interface IQuestionText {
  question: IQuestion;
  questionIndex: number;
  showUpload: boolean;
  isDocumentRequired: boolean;
  isRequired: boolean;
  showError: boolean;
  currentSection: number;
}

export default function QuestionText({
  question,
  questionIndex,
  showUpload,
  isDocumentRequired,
  isRequired,
  showError,
  currentSection,
}: IQuestionText) {
  const { saq, setSAQ } = useSAQ();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const { t } = useTranslation();

  const isSub = question.is_sub;

  const hasError = isRequired && !getAnswer(question) && showError;
  const hasDocumentError =
    showUpload &&
    isDocumentRequired &&
    showError &&
    !saq.sections[currentSection].questions[questionIndex].documents?.length;

  return (
    <>
      <Typography
        variant="question"
        fontWeight={isSub ? 300 : 600}
        className={hasError ? 'error' : ''}
      >
        {question.translations[0].title}
        {isRequired && '*'}
      </Typography>
      {showUpload && (
        <Box display="flex" justifyContent="flex-end">
          <UploadButtonLayout
            label={t('certificate') + (isDocumentRequired ? '*' : '')}
            onClick={() => setShowUploadModal(true)}
            error={hasDocumentError}
          />
        </Box>
      )}
      <TextField
        name={`text-field${question.id}`}
        variant="standard"
        multiline
        maxRows={4}
        onChange={(e) =>
          changeText(
            questionIndex,
            question.id,
            e.target.value,
            saq,
            currentSection,
            setSAQ,
          )
        }
        value={question.answers[0]?.text_answer || ''}
        required={isRequired}
        error={hasError}
        helperText={hasError ? t('required') : ' '}
      />
      {showUpload && (
        <UploadedDocumentsSAQ
          currentSection={currentSection}
          questionIndex={questionIndex}
        />
      )}
      {hasDocumentError && (
        <FormHelperText error>{t('requiredDocument')}</FormHelperText>
      )}
      <UploadNewDocumentsModal
        open={showUploadModal}
        setOpen={setShowUploadModal}
        questionId={question.id}
        currentSection={currentSection}
      />
    </>
  );
}
