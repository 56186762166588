import React from 'react';

import { Box } from '@mui/material';

import colors from '../../assets/colors';
import NatooraLogo from '../../assets/icons/IconNatoora';
import { useAuth } from '../../stores/useAuth';
import CollapsibleMenu from '../Drawer';

// TODO: change it to MUI AppBar: https://mui.com/material-ui/react-app-bar/

function Header() {
  const { auth } = useAuth();
  return (
    <Box
      sx={{
        height: '64px',
        backgroundColor: colors.darkGrey,
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center',
        position: 'fixed',
        width: '100%',
        zIndex: 9,
      }}
    >
      {auth?.user?.id !== undefined && (
        <Box>
          <CollapsibleMenu />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          marginTop: '5px',
          justifyItems: 'center',
          alignItems: 'center',
          ml: 2,
        }}
      >
        <NatooraLogo height="40px" fill="#fff" />
      </Box>
    </Box>
  );
}

export default Header;
