import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import colors from '../../assets/colors';
import {
  useDeleteDocument,
  useGetDocumentType,
  useGetSupplierDocument,
} from '../../services/supplierDocuments';
import { useAuth } from '../../stores/useAuth';
import { handleDownload } from '../../utils/downloadFiles';
import formatDateBasedOnBrowser from '../../utils/formatDateBasedOnBrowser';
import LoadingWrapper from '../LoadingWrapper';
import UploadButtonLayout from '../UploadButtonLayout';
import UploadNewDocumentsModal from '../UploadNewDocumentsModal';

type IState = {
  supplier: number;
  supplierName: string;
};

export default function UploadedDocumentation() {
  const { auth } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const state = location.state as IState;
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [documents, setDocuments] = useState<any[]>([]);
  const {
    isLoading: isDocumentLoading,
    data: documentsResponse,
    refetch: refetchDocument,
  } = useGetSupplierDocument({
    supplierId:
      state?.supplier?.toString() || auth.user?.active_supplier_details.id,
  });
  const { isLoading: isTypeLoading, data: types } = useGetDocumentType({
    language: auth.user?.language,
  });
  const { mutate: mutateDeleteDocument, isLoading: documentDeleteIsLoading } =
    useDeleteDocument({
      onSuccess: (data) => {
        refetchDocument();
      },
    });

  const columns: GridColDef[] = [
    {
      field: 'document_name',
      headerName: t('documentName'),
      minWidth: 200,
      resizable: true,
    },
    {
      field: 'type',
      headerName: t('documentType'),
      minWidth: 150,
      resizable: true,
    },
    {
      field: 'uploaded_date',
      headerName: t('uploadedDate'),
      minWidth: 150,
      resizable: true,
    },
    {
      field: 'expiry_date',
      headerName: t('expiryDate'),
      width: 150,
      resizable: true,
      flex: 1,
    },
    {
      field: 'document',
      headerName: '',
      resizable: true,
      renderCell: (params) => (
        <a
          href={params.value}
          target="_blank"
          rel="noopener noreferrer"
          download={params.getValue(params.id, 'document_name')}
          onClick={(e) =>
            handleDownload(
              params.value,
              params.getValue(params.id, 'document_name'),
              e,
            )
          }
          style={{ height: 24 }}
        >
          <FileDownloadIcon color="primary" />
        </a>
      ),
    },
    {
      field: 'id',
      headerName: '',
      resizable: true,
      renderCell: (params) =>
        (!auth.user?.is_admin && params.row?.deletable) ||
        params.row?.deletable === null ? (
          <DeleteForeverIcon
            sx={{ cursor: 'pointer' }}
            color="error"
            onClick={() => mutateDeleteDocument({ id: params.value })}
          />
        ) : (
          ''
        ),
    },
  ];

  useEffect(() => {
    if (documentsResponse?.length && types?.length) {
      const tempDocuments = documentsResponse?.map((document) => {
        const tempDocument = {
          ...document,
          uploaded_date: formatDateBasedOnBrowser(document.uploaded_date),
          expiry_date: formatDateBasedOnBrowser(document.expiry_date),
        };
        tempDocument.type = types?.find(
          (type) => type.id === document.type,
        )?.translations[0].name;
        return tempDocument;
      });
      setDocuments(tempDocuments);
    }
  }, [documentsResponse, types]);

  return (
    <LoadingWrapper
      loading={isDocumentLoading || isTypeLoading || documentDeleteIsLoading}
    >
      <Box
        sx={{
          margin: 2,
        }}
        height={window.innerHeight - 200}
        position="relative"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {state?.supplier && (
            <Box>
              <Typography variant="title" fontWeight="semibold">
                {state.supplierName} Documents
              </Typography>
            </Box>
          )}
          {!state?.supplier && (
            <>
              <Box>
                <Typography variant="title" fontWeight="semibold">
                  {t('uploadedDocumentation')}
                </Typography>
                <Typography variant="subtitle1">
                  {t('notBiggerThan')} 10mb.
                </Typography>
                <Typography variant="subtitle1">
                  {t('formatsAre')} pdf, png, jpg, DOCX, DOC, xlsx, xls.
                </Typography>
              </Box>
              <Box>
                <UploadButtonLayout
                  label={t('certificate')}
                  onClick={() => setShowUploadModal(true)}
                />
              </Box>
            </>
          )}
        </Box>
        <Box
          sx={{
            height: 'calc(100% - 130px)',
            marginTop: 4,
            border: '1px solid',
            borderColor: colors.borderGrey,
          }}
        >
          <DataGrid columns={columns} rows={documents || []} autoPageSize />
        </Box>
        <UploadNewDocumentsModal
          open={showUploadModal}
          setOpen={setShowUploadModal}
          afterUpload={refetchDocument}
        />
      </Box>
    </LoadingWrapper>
  );
}
