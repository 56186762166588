import moment from 'moment';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { ISAQ } from '../entities/SAQ';

interface ISAQState {
  saq: ISAQ;
  currentSection: number;
  setSAQ: (data: ISAQ) => void;
  setCurrentSection: (data: number) => void;
}

const storeName = 'saqStore';
const initialSaq: ISAQ = {
  id: -1,
  translations: [],
  supplier: '',
  sections: [],
  terms_of_supply: [],
  user_acceptance_statements: [],
  checked_date: moment().format('DD/MM/YYYY'),
  checked_by: '',
  date_approved: '',
  date_submitted: '',
};
const initialCurrentSection = 0;

export const useSAQ = create<ISAQState>()(
  devtools(
    (set) => ({
      saq: initialSaq,
      currentSection: initialCurrentSection,
      setSAQ: (saq) => {
        set((state) => ({ ...state, saq }));
      },
      setCurrentSection: (currentSection) => {
        set((state) => ({ ...state, currentSection }));
      },
    }),
    { name: storeName },
  ),
);
