export const PATHS = {
  ROOT: '/',
  FORGOT_PASSWORD: '/forgot-password',
  SUCCESSFUL_SOLICITED: '/successful-solicited',
  HOME: '/home',
  ADMIN: '/staff',
  SET_PASSWORD: '/set-password',
  RESET_PASSWORD: '/reset-password',
  CATEGORY: '/category',
  SAQ: '/saq',
  DETAILS: '/details',
  DOCUMENT: '/document',
  OVERVIEW: '/overview',
  TERMS: '/terms',
  SUPPLIERS_LIST: '/staff/suppliers/list',
  ADMIN_SUPPLIER_SAQ: '/staff/suppliers/saq',
};
