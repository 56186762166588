import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import AppTheme from './assets/theme';
import ReactQueryClientProvider from './libs/react-query';
import AppRouter from './routes';

import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <AppTheme>
      <ReactQueryClientProvider>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </ReactQueryClientProvider>
    </AppTheme>
  </React.StrictMode>,
);
