import { useMutation, UseMutationOptions, useQuery } from 'react-query';

import { api } from '../libs/axios';
import { ApiErrorDefaultResponse } from '../libs/axios/interceptors';

// ----- //

type IDocumentTypeResponse = [
  {
    id: string;
    translations: [
      {
        name: string;
        language: string;
      },
    ];
  },
];

export const useGetDocumentType = (payload: { language?: string }) => {
  const getDocumentType = () => {
    return api
      .get<IDocumentTypeResponse>(
        `saq/document-type?language=${payload.language}`,
      )
      .then((response) => response.data);
  };

  return useQuery('document-types', getDocumentType);
};

// ----- //

type ISupplierDocumentResponse = [
  {
    id: number;
    document: string;
    document_name: string;
    supplier: string;
    supplier_answer: null;
    supplier_question: number;
    type?: number | string;
    expiry_date: string;
    uploaded_date: string;
  },
];

export const useGetSupplierDocument = ({
  supplierId,
}: {
  supplierId?: string;
}) => {
  const getSupplierDocument = () => {
    return api
      .get<ISupplierDocumentResponse>(
        `saq/supplier-document?supplier=${supplierId}`,
      )
      .then((response) => response.data);
  };

  return useQuery(['documents', supplierId], getSupplierDocument);
};

// ----- //

type IDocumentRequest = {
  supplier_answer?: number;
  supplier_question?: number;
  documents: File[];
  type: string;
  expiry_date: string | null;
};

export const useSaveDocuments = (
  options?: UseMutationOptions<any, ApiErrorDefaultResponse, IDocumentRequest>,
) => {
  const saveDocuments = (payload: IDocumentRequest) => {
    const formData = new FormData();

    formData.append('type', payload.type);
    if (payload.expiry_date) {
      formData.append('expiry_date', payload.expiry_date);
    }

    payload.documents.forEach((document) => {
      formData.append('documents', document);
    });

    if (payload.supplier_answer) {
      formData.append('supplier_answer', `${payload.supplier_answer}`);
    }

    if (payload.supplier_question) {
      formData.append('supplier_question', `${payload.supplier_question}`);
    }

    return api.post('saq/supplier-document', formData).then((response) => {
      return response.data;
    });
  };

  return useMutation('useSaveDocuments', saveDocuments, options);
};

// ----- //

type IDeleteDocumentRequest = { id: number; index?: number };

export const useDeleteDocument = (
  options?: UseMutationOptions<
    any,
    ApiErrorDefaultResponse,
    IDeleteDocumentRequest
  >,
) => {
  const deleteDocument = (payload: IDeleteDocumentRequest) => {
    return api
      .delete(`saq/supplier-document/${payload.id}`)
      .then((response) => {
        return response.data;
      });
  };
  return useMutation('useDeleteDocument', deleteDocument, options);
};
