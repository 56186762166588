import React from 'react';

import { Box } from '@mui/material';

import colors from '../../assets/colors';

interface IPaper {
  children: React.ReactNode;
  form?: boolean;
  onSubmit?: React.FormEventHandler<HTMLFormElement> | undefined;
  width?: number;
  marginTop?: number;
  marginBottom?: number;
}

function Paper({
  children,
  form,
  onSubmit,
  width,
  marginTop,
  marginBottom,
}: IPaper) {
  return (
    <Box
      onSubmit={onSubmit}
      component={form ? 'form' : 'div'}
      sx={{
        width: width ? `${width}rem` : 'auto',
        maxWidth: '60rem',
        minWidth: '37rem',
        background: colors.white,
        border: `1px solid #dadce0`,
        borderRadius: 2,
        padding: 2,
        marginTop: marginTop || 0,
        marginBottom: marginBottom || 0,
      }}
    >
      {children}
    </Box>
  );
}

export default Paper;
