import React, { useEffect } from 'react';

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

import LoadingWrapper from '../../../../../components/LoadingWrapper';
import { ISupplierFilters } from '../../../../../entities/SupplierFilters';
import { useCategory } from '../../../../../services/categories';
import { useGetSAQStatuses } from '../../../../../services/saq';
import {
  useGetSupplierRegions,
  useGetSupplierStatuses,
} from '../../../../../services/supplierProfile';
import { useAuth } from '../../../../../stores/useAuth';
import { useCategories } from '../../../../../stores/useCategories';
import { useSupplierFilters } from '../../../../../stores/useSupplierFilters';

export default function AdminSuppliersListFilters() {
  const { auth } = useAuth();
  const { isLoading: isLoadingRegions, data: regions } =
    useGetSupplierRegions();
  const { isLoading: isLoadingStatuses, data: statuses } =
    useGetSupplierStatuses();
  const { isLoading: isLoadingSaqStatuses, data: saqStatus } =
    useGetSAQStatuses();
  const { filters, setFilters } = useSupplierFilters();
  const { categories, setCategories } = useCategories();

  const { mutate: mutateCategory, isLoading: isLoadingCategories } =
    useCategory({
      onSuccess: (response) => {
        setCategories(response);
      },
    });

  useEffect(() => {
    mutateCategory({ language: auth.user?.language });
  }, []);

  const changeFilters = (
    key: keyof ISupplierFilters,
    value: string | boolean,
  ) => {
    setFilters({ ...filters, [key]: value, page: 1 });
  };

  return (
    <LoadingWrapper
      loading={
        isLoadingRegions ||
        isLoadingStatuses ||
        isLoadingSaqStatuses ||
        isLoadingCategories
      }
      doNotShowContent={
        isLoadingRegions ||
        isLoadingStatuses ||
        isLoadingSaqStatuses ||
        isLoadingCategories
      }
    >
      <Box>
        <Box padding={2} display="flex" mt={4} mb={2}>
          <Box display="flex" flexDirection="column" width="50%">
            <FormControl>
              <FormLabel>Category</FormLabel>
              <RadioGroup
                row
                value={filters.categories}
                onChange={(e, value) => changeFilters('categories', value)}
              >
                {categories?.map((category) => (
                  <FormControlLabel
                    value={category.translations[0].name}
                    control={<Radio color="primary" />}
                    label={category.translations[0].name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <Box mt={2}>
              <FormControl>
                <FormLabel>Region</FormLabel>
                <RadioGroup
                  row
                  value={filters.regions}
                  onChange={(e, value) => changeFilters('regions', value)}
                >
                  {regions?.regions.map((region) => (
                    <FormControlLabel
                      value={region}
                      control={<Radio color="primary" />}
                      label={region}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" width="50%">
            <FormControl>
              <FormLabel>SAQ Status</FormLabel>
              <RadioGroup
                row
                value={filters.saq_statuses}
                onChange={(e, value) => changeFilters('saq_statuses', value)}
              >
                {saqStatus?.statuses.map((status) => (
                  <FormControlLabel
                    value={status}
                    control={<Radio color="primary" />}
                    label={status}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <Box mt={2}>
              <FormControl>
                <FormLabel>Status</FormLabel>
                <RadioGroup
                  row
                  value={filters.supplier_statuses}
                  onChange={(e, value) =>
                    changeFilters('supplier_statuses', value)
                  }
                >
                  {statuses?.statuses.map((status) => (
                    <FormControlLabel
                      value={status}
                      control={<Radio color="primary" />}
                      label={status}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row-reverse">
          <Button
            variant="gradient"
            onClick={() => changeFilters('apply', !filters.apply)}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </LoadingWrapper>
  );
}
