import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';

import { IQuestion } from '../../entities/SAQ';
import { getAnswer } from '../../pages/SAQOverview/utils';
import { useSAQ } from '../../stores/useSAQ';
import { changeProduct } from '../../utils/onChangeFunctions';
import UploadButtonLayout from '../UploadButtonLayout';
import UploadedDocumentsSAQ from '../UploadedDocumentsSAQ';
import UploadNewDocumentsModal from '../UploadNewDocumentsModal';

interface IQuestionProducts {
  questionIndex: number;
  showUpload: boolean;
  isDocumentRequired: boolean;
  isRequired: boolean;
  showError: boolean;
  question: IQuestion;
  currentSection: number;
}

export default function QuestionProducts({
  questionIndex,
  showUpload,
  isDocumentRequired,
  isRequired,
  showError,
  question,
  currentSection,
}: IQuestionProducts) {
  const { saq, setSAQ } = useSAQ();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const { t } = useTranslation();

  const questionId = question.id;
  const isSub = question.is_sub;

  const hasError =
    isRequired &&
    !getAnswer(saq.sections[currentSection].questions[questionIndex]) &&
    showError;
  const hasDocumentError =
    showUpload &&
    isDocumentRequired &&
    showError &&
    !saq.sections[currentSection].questions[questionIndex].documents?.length;

  const changeAnswer = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index?: number,
  ) => {
    const tempSaq = changeProduct(
      questionIndex,
      questionId,
      e.target.value,
      saq,
      currentSection,
      index,
    );
    setSAQ(tempSaq);
  };

  const addNewProduct = () => {
    const tempSaq = { ...saq };
    if (
      !tempSaq.sections[currentSection].questions[questionIndex].answers?.length
    ) {
      tempSaq.sections[currentSection].questions[questionIndex].answers[0] = {
        question: questionId,
        json_answer: [],
      };
    }
    const { json_answer } =
      tempSaq.sections[currentSection].questions[questionIndex].answers[0];
    json_answer.push('');
    setSAQ(tempSaq);
  };

  const showProducts = () => {
    return saq.sections[currentSection].questions[
      questionIndex
    ].answers[0]?.json_answer?.map((product: string, index: number) => (
      // This disabling needs to be here since it's the only possible key,
      // because the user could enter the same string twice or more
      // eslint-disable-next-line react/no-array-index-key
      <li key={index}>
        <Box display="inline-table" sx={{ verticalAlign: 'middle' }}>
          <TextField
            variant="standard"
            value={product}
            onChange={(e) => changeAnswer(e, index)}
          />
        </Box>
      </li>
    ));
  };

  return (
    <>
      <Typography
        variant="question"
        fontWeight={isSub ? 300 : 600}
        className={hasError ? 'error' : ''}
      >
        {question.translations[0].title}
        {isRequired && '*'}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <ul>{showProducts()}</ul>
        </Box>
        <Box>
          {showUpload && (
            <Box>
              <UploadButtonLayout
                label={t('certificate') + (isDocumentRequired ? '*' : '')}
                onClick={() => setShowUploadModal(true)}
                error={hasDocumentError}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Button variant="gradient" onClick={addNewProduct}>
        {t('add')}
      </Button>
      {hasError && <FormHelperText error>{t('required')}</FormHelperText>}
      {showUpload && (
        <UploadedDocumentsSAQ
          currentSection={currentSection}
          questionIndex={questionIndex}
        />
      )}
      {hasDocumentError && (
        <FormHelperText error>{t('requiredDocument')}</FormHelperText>
      )}
      <UploadNewDocumentsModal
        open={showUploadModal}
        setOpen={setShowUploadModal}
        questionId={questionId}
        currentSection={currentSection}
      />
    </>
  );
}
