import React, { useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SearchIcon from '@mui/icons-material/Search';
import { Box, InputBase, Paper, Typography } from '@mui/material';

import colors from '../../assets/colors';
import SvgBallsLogo from '../../assets/icons/SvgBallsLogo';
import { useSupplierFilters } from '../../stores/useSupplierFilters';

interface IHeaderSearch {
  children?: React.ReactNode;
  appName: string;
}

export default function HeaderSearch({ children, appName }: IHeaderSearch) {
  const { filters, setFilters } = useSupplierFilters();
  const [showFilters, setShowFilters] = useState(false);
  const [text, setText] = useState(filters.search);
  const [timeOutFunc, setTimeOutFunc] = useState<ReturnType<typeof setTimeout>>(
    setTimeout(() => null, 100),
  );

  const handleTextChange = (value: string) => {
    clearTimeout(timeOutFunc);
    setText(value);
    const func = setTimeout(() => {
      setFilters({ ...filters, search: value, page: 1 });
    }, 300);
    setTimeOutFunc(func);
  };

  const displayFilters = () => {
    return children;
  };

  return (
    <Paper variant="container" sx={{ boxShadow: 1, marginTop: 1 }}>
      <Box display="flex">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          style={{ opacity: 0.7 }}
        >
          <SvgBallsLogo style={{ height: 10, marginBottom: 8 }} />
          <Typography variant="subtitle2">{appName}</Typography>
        </Box>
        <Box
          sx={{
            border: '1px solid',
            borderColor: colors.borderGrey,
            borderRadius: 1,
          }}
          marginLeft={5}
          display="flex"
          width="50%"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderColor: colors.disabledGrey,
              width: '100%',
            }}
          >
            <Box
              sx={{
                padding: 1,
                display: 'flex',
                alignItems: 'center',
                width: '80%',
              }}
            >
              <SearchIcon sx={{ color: colors.disabledGrey, marginRight: 1 }} />
              <InputBase
                fullWidth
                value={text}
                onChange={(e) => handleTextChange(e.currentTarget.value)}
              />
            </Box>
            <Box
              sx={{
                width: '20%',
                borderLeft: `1px solid ${colors.borderGrey}`,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => setShowFilters(!showFilters)}
            >
              <Typography variant="body2" sx={{ color: colors.text }}>
                Filters
              </Typography>
              {!showFilters && (
                <ArrowDropDownIcon sx={{ color: colors.text }} />
              )}
              {showFilters && <ArrowDropUpIcon sx={{ color: colors.text }} />}
            </Box>
          </Box>
        </Box>
      </Box>
      {showFilters && displayFilters()}
    </Paper>
  );
}
