import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Paper } from '@mui/material';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';

import Container from '../../../../components/Container';
import HeaderSearch from '../../../../components/HeaderSearch';
import LoadingWrapper from '../../../../components/LoadingWrapper';
import { PATHS } from '../../../../config/paths';
import { useGetSuppliersList } from '../../../../services/supplierProfile';
import { useSupplierFilters } from '../../../../stores/useSupplierFilters';
import AdminSuppliersListFilters from './Components/AdminSuppliersListFilters';

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Supplier Name',
    minWidth: 150,
    resizable: true,
    flex: 1,
  },
  {
    field: 'category_name',
    headerName: 'Category',
    minWidth: 150,
    resizable: true,
    flex: 1,
  },
  {
    field: 'region',
    headerName: 'Region',
    minWidth: 50,
    resizable: true,
  },
  {
    field: 'saq_status',
    headerName: 'SAQ Status',
    minWidth: 150,
    resizable: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 150,
    resizable: true,
  },
];

export default function AdminSuppliersList() {
  const { filters, setFilters } = useSupplierFilters();
  const { isLoading, data } = useGetSuppliersList(filters);
  const [rowCount, setRowCount] = useState(data?.pagination?.count || 0);

  const navigate = useNavigate();

  useEffect(() => {
    setRowCount((prevRowCountState: number) =>
      data?.pagination?.count !== undefined
        ? data?.pagination?.count
        : prevRowCountState,
    );
  }, [data?.pagination?.count]);

  const handleRowClick = (rowData: GridRowParams) => {
    navigate(PATHS.ADMIN_SUPPLIER_SAQ, {
      state: { supplier: rowData.row.id, supplierName: rowData.row.name },
    });
  };

  const handlePageChange = (page: number) => {
    setFilters({ ...filters, page: page + 1 });
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <Container>
        <Box marginX="15%" marginY={2} width="100%">
          <HeaderSearch appName="GLOBAL SUPPLIERS">
            <AdminSuppliersListFilters />
          </HeaderSearch>
          <Paper variant="container" sx={{ boxShadow: 1, marginTop: 1 }}>
            <Box height={650}>
              <DataGrid
                columns={columns}
                rows={data?.results || []}
                pageSize={data?.pagination?.page_size}
                rowCount={rowCount}
                onPageChange={(page) => handlePageChange(page)}
                page={filters.page - 1}
                onRowClick={handleRowClick}
                disableColumnFilter
                paginationMode="server"
              />
            </Box>
          </Paper>
        </Box>
      </Container>
    </LoadingWrapper>
  );
}
