import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { IAuth } from '../entities/Auth';

interface IAuthState {
  auth: IAuth;
  setAuth: (auth: IAuth) => void;
  setCategory: (category: number) => void;
  setType: (type: number) => void;

  logout: () => void;
}

const storeName = 'authStore';
const initialAuth: IAuth = {
  access_token: undefined,
  refresh_token: undefined,
  user: {
    id: undefined,
    email: undefined,
    language: undefined,
    is_admin: undefined,
    active_supplier_details: {
      id: undefined,
      name: undefined,
      category: undefined,
      saq_completed: undefined,
      type: undefined,
    },
  },
};

export const useAuth = create<IAuthState>()(
  devtools(
    persist(
      (set) => ({
        auth: initialAuth,
        setAuth: (auth) => {
          set((state) => ({ ...state, auth }));
        },
        setCategory: (category) => {
          set((state) => {
            return {
              ...state,
              auth: {
                ...state.auth,
                user: {
                  ...state.auth.user,
                  active_supplier_details: {
                    ...state.auth.user?.active_supplier_details,
                    category,
                  },
                },
              },
            };
          });
        },
        setType: (type) => {
          set((state) => {
            return {
              ...state,
              auth: {
                ...state.auth,
                user: {
                  ...state.auth.user,
                  active_supplier_details: {
                    ...state.auth.user?.active_supplier_details,
                    type,
                  },
                },
              },
            };
          });
        },

        logout: () => {
          set((state) => {
            return {
              ...state,
              auth: initialAuth,
            };
          });
        },
      }),
      { name: storeName },
    ),
    { name: storeName },
  ),
);
