import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Box, Tab, Tabs } from '@mui/material';

import colors from '../../assets/colors';
import Container from '../../components/Container';
import SAQTabPanel from '../../components/SAQTabPanel';
import TabPanel from '../../components/TabPanel';
import UploadedDocumentation from '../../components/UploadedDocumentation';

export default function Document() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchParamTabIndex = searchParams.get('tabIndex');

  const { t } = useTranslation();
  const [firstTabsValue, setFirstTabsValue] = useState(
    searchParamTabIndex ? Number(searchParamTabIndex) : 2,
  );

  const handleTabChange = (
    event: React.SyntheticEvent,
    firstTabValue: number,
  ) => {
    setFirstTabsValue(firstTabValue);
  };

  return (
    <Container paddingBottom={0}>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'background.paper',
          display: 'flex',
          height: window.innerHeight - 64,
        }}
      >
        <Tabs
          orientation="vertical"
          value={firstTabsValue}
          onChange={handleTabChange}
          sx={{
            borderRight: 1,
            borderColor: 'divider',
            width: '20%',
            backgroundColor: colors.white,
            position: 'fixed',
            height: '100%',
          }}
        >
          <Tab
            label={`${t('supplierAssessmentQuestionnaire')}(${t('saq')})`}
            value={1}
          />
          <Tab label={t('uploadedDocumentation')} value={2} />
        </Tabs>
        <TabPanel value={firstTabsValue} index={1}>
          <SAQTabPanel />
        </TabPanel>
        <TabPanel value={firstTabsValue} index={2}>
          <UploadedDocumentation />
        </TabPanel>
      </Box>
    </Container>
  );
}
