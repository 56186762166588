import { useMutation, UseMutationOptions, useQuery } from 'react-query';

import { ESAQStatuses, IQuestionsAnswers, ISAQ } from '../entities/SAQ';
import { api } from '../libs/axios';
import { ApiErrorDefaultResponse } from '../libs/axios/interceptors';

type SAQRequest = {
  supplierId?: string;
  language?: string;
  currentSAQId?: number;
};

export const useQuestions = (
  options?: UseMutationOptions<ISAQ, ApiErrorDefaultResponse, SAQRequest>,
) => {
  const getSAQ = (payload: SAQRequest) =>
    api
      .get<ISAQ[]>(
        `saq/supplier-saq?supplier=${payload.supplierId}&language=${payload.language}`,
      )
      .then((response) => response.data[0]);

  return useMutation('useQuestions', getSAQ, options);
};

export const useQuestionsById = (
  options?: UseMutationOptions<ISAQ, ApiErrorDefaultResponse, SAQRequest>,
) => {
  const getSAQ = (payload: SAQRequest) =>
    api
      .get<ISAQ>(
        `saq/supplier-saq/${payload.currentSAQId}?language=${payload.language}`,
      )
      .then((response) => response.data);

  return useMutation('useQuestionsById', getSAQ, options);
};

export interface ISaqOverview {
  id: number;
  status: string;
  expiry_date: string;
  date_started: string;
  category_when_created: string;
}

export const useGetSaqOverview = (payload: {
  language?: string;
  supplierId?: string;
}) => {
  const getSaqOverview = () => {
    return api
      .get<ISaqOverview[]>(
        payload.supplierId
          ? `saq/supplier-saq?overview=true&language=${payload.language}&supplier=${payload.supplierId}`
          : `saq/supplier-saq?overview=true&language=${payload.language}`,
      )
      .then((response) => response.data);
  };

  return useQuery(['useSaqOverview', payload.supplierId], getSaqOverview);
};

// ----- //

type ISubmitSaqRequest = {
  id: number;
};

interface ISubmitSaqResponse {
  status: string;
}

export const useSubmitSaq = (
  options?: UseMutationOptions<
    ISubmitSaqResponse,
    ApiErrorDefaultResponse,
    ISubmitSaqRequest
  >,
) => {
  const submitSaq = (payload: ISubmitSaqRequest) => {
    return api
      .post<ISubmitSaqResponse>(`saq/supplier-saq/${payload.id}/submit`)
      .then((response) => response.data);
  };

  return useMutation('useSaqOverview', submitSaq, options);
};

type ISubmitCommentRequest = {
  supplier_saq_question: number;
  comment: string;
};

export const useSubmitComment = (
  options?: UseMutationOptions<
    any,
    ApiErrorDefaultResponse,
    ISubmitCommentRequest
  >,
) => {
  const submitComment = (payload: ISubmitCommentRequest) => {
    return api.post(`comments/`, payload).then((response) => response.data);
  };

  return useMutation('useSubmitComment', submitComment, options);
};

type IAcceptancesRequest = {
  saqId: number;
  data: any;
  next?: boolean;
};

export const useSaveAcceptances = (
  options?: UseMutationOptions<
    any,
    ApiErrorDefaultResponse,
    IAcceptancesRequest
  >,
) => {
  const saveAcceptances = (payload: IAcceptancesRequest) => {
    return api
      .put<any>(
        `saq/supplier-saq/${payload.saqId}/final-confirmation`,
        payload.data,
      )
      .then((response) => response.data);
  };

  return useMutation('useSaveAcceptances', saveAcceptances, options);
};

type IStatusChangeRequest = {
  status: string;
  saqId: number;
};

export const useSetSAQStatus = (
  options?: UseMutationOptions<
    any,
    ApiErrorDefaultResponse,
    IStatusChangeRequest
  >,
) => {
  const setSAQStatus = (payload: IStatusChangeRequest) => {
    return api
      .patch(`saq/supplier-saq/${payload.saqId}`, { status: payload.status })
      .then((response) => response.data);
  };

  return useMutation('useSetSAQStatus', setSAQStatus, options);
};

type ISaveAnswersRequest = {
  answers: IQuestionsAnswers[];
  callback?: (data: IQuestionsAnswers[]) => void;
};

export const useSaveAnswers = (
  options?: UseMutationOptions<
    IQuestionsAnswers[],
    ApiErrorDefaultResponse,
    ISaveAnswersRequest
  >,
) => {
  const saveAnswers = (payload: ISaveAnswersRequest) => {
    return api
      .post('saq/supplier-answer', payload.answers)
      .then((response) => response.data);
  };

  return useMutation('useSaveAnswers', saveAnswers, options);
};

type ISAQStatuses = {
  statuses: string[];
};

export const useGetSAQStatuses = () => {
  const getSAQStatuses = () => {
    return api
      .get<ISAQStatuses>('saq/saq-status-options')
      .then((response) => response.data);
  };

  return useQuery('saq_statuses', getSAQStatuses);
};

type ISAQStatus = {
  status: ESAQStatuses;
  category_name: string;
};

export const useGetSAQStatus = () => {
  const getSAQStatus = () => {
    return api
      .get<ISAQStatus>('saq/current-saq-details')
      .then((response) => response.data);
  };

  return useQuery('saq_status', getSAQStatus);
};
