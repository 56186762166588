import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Box, Button, Divider } from '@mui/material';

import colors from '../../assets/colors';
import Modal from '../Modal';

type IModalConfirmation = {
  title: string;
  message: string;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
};

export default function ModalConfirmation({
  title,
  message,
  open,
  onConfirm,
  onCancel,
  onClose,
}: IModalConfirmation) {
  const { t } = useTranslation();
  return (
    <Modal onClose={() => null} open={open} timeout={500}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography align="center" variant="title" fontWeight="semibold">
          {title}
        </Typography>
        {message.split('\n').map((str) => (
          <Typography
            key={str}
            align="center"
            variant="subquestion"
            color={colors.text}
          >
            {str}
          </Typography>
        ))}
      </Box>
      <Divider sx={{ width: '100%', marginTop: 5, marginBottom: 3 }} />
      <Box display="flex" justifyContent="space-evenly" width="100%">
        <Button
          variant="gradient"
          color="secondary"
          onClick={() => {
            onCancel();
            onClose();
          }}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="gradient"
          color="primary"
          onClick={() => {
            onConfirm();
            onClose();
          }}
        >
          {t('ok')}
        </Button>
      </Box>
    </Modal>
  );
}
