import { useMutation, UseMutationOptions, useQuery } from 'react-query';

import { ICategories } from '../entities/Categories';
import {
  IErrorSupplierDetails,
  ISupplierDetails,
} from '../entities/SupplierDetails';
import { ISupplierFilters } from '../entities/SupplierFilters';
import { api } from '../libs/axios';
import { ApiErrorDefaultResponse } from '../libs/axios/interceptors';

type SAQRequest = {
  supplierId?: string;
};

type ITypesRequest = {
  language?: string;
};

export const useSupplierDetails = (
  options?: UseMutationOptions<
    ISupplierDetails,
    ApiErrorDefaultResponse,
    SAQRequest
  >,
) => {
  const getDetails = (payload: SAQRequest) =>
    api
      .get<ISupplierDetails[]>(
        `api/supplier-profile/supplier-detail?supplier_id=${payload.supplierId}`,
      )
      .then((response) => response.data[0]);

  return useMutation('useSupplierDetails', getDetails, options);
};

export const useSaveSupplierDetails = (
  options?: UseMutationOptions<
    IErrorSupplierDetails,
    ApiErrorDefaultResponse,
    ISupplierDetails
  >,
) => {
  const saveSupplierDetails = (payload: ISupplierDetails) =>
    api
      .post(`supplier-profile/supplier-detail`, payload)
      .then((response) => response.data);

  return useMutation('useSaveSupplierDetails', saveSupplierDetails, options);
};

export const useUpdateSupplierDetails = (
  options?: UseMutationOptions<
    unknown,
    ApiErrorDefaultResponse,
    ISupplierDetails
  >,
) => {
  const updateSupplierDetails = (payload: ISupplierDetails) =>
    api.put(`supplier-profile/supplier-detail/${payload.id}`, payload);

  return useMutation(
    'useUpdateSupplierDetails',
    updateSupplierDetails,
    options,
  );
};

export const useSupplierType = (
  options?: UseMutationOptions<
    ICategories,
    ApiErrorDefaultResponse,
    ITypesRequest
  >,
) => {
  const getSupplierType = (payload: ITypesRequest) =>
    api
      .get<ICategories>(
        `supplier-profile/supplier-type?language=${payload.language}`,
      )
      .then((response) => response.data);

  return useMutation('useSupplierType', getSupplierType, options);
};

export const useGetSuppliersList = (payload: ISupplierFilters) => {
  const getSupplierList = () => {
    const keys = Object.keys(payload) as Array<keyof ISupplierFilters>;

    const params = keys.reduce<ISupplierFilters>((acc, key) => {
      if (key === 'saq_statuses' && acc[key] !== '') {
        acc.saq_statuses = acc.saq_statuses.toUpperCase().replaceAll(' ', '_');
      }

      if (key === 'supplier_statuses' && acc[key] !== '') {
        acc.supplier_statuses = acc.supplier_statuses
          .toUpperCase()
          .replaceAll(' ', '_');
      }

      if (acc[key] === '') {
        delete acc[key];
      }

      return acc;
    }, payload);

    return api.get('suppliers', { params }).then((response) => response.data);
  };

  return useQuery(
    ['suppliers_list', payload.apply, payload.search, payload.page],
    getSupplierList,
  );
};

type ISupplierStatuses = {
  statuses: string[];
};

type ISupplierRegions = {
  regions: string[];
};

export const useGetSupplierStatuses = () => {
  const getSupplierStatuses = () => {
    return api
      .get<ISupplierStatuses>('suppliers/supplier-status-options')
      .then((response) => response.data);
  };

  return useQuery('suplier_statuses', getSupplierStatuses);
};

export const useGetSupplierRegions = () => {
  const getSupplierRegions = () => {
    return api
      .get<ISupplierRegions>('suppliers/supplier-region-options')
      .then((response) => response.data);
  };

  return useQuery('suplier_regions', getSupplierRegions);
};
