import React from 'react';

import { Typography } from '@mui/material';

import { IQuestion, ISAQSections } from '../../entities/SAQ';
import { checkRequiredIf } from '../../pages/SAQOverview/utils';
import { optionsSeparator } from '../../utils/onChangeFunctions';
import QuestionMultipleChoice from '../QuestionMultipleChoice';
import QuestionProducts from '../QuestionProducts';
import QuestionSingleChoice from '../QuestionSingleChoice';
import QuestionText from '../QuestionText';
import QuestionYesOrNo from '../QuestionYesOrNo';

interface IQuestionSwitch {
  question: IQuestion;
  questionIndex: number;
  section: ISAQSections;
  doCheck: boolean;
  currentSection: number;
  noDivider?: boolean;
}

export default function QuestionSwitch({
  question,
  questionIndex,
  section,
  doCheck,
  currentSection,
  noDivider,
}: IQuestionSwitch) {
  const showUpload = question.document_upload;
  const isDocumentRequired = checkRequiredIf(question, section, 'document');
  const isRequired = question.is_required || checkRequiredIf(question, section);
  const options = question.translations[0].options?.split(optionsSeparator);

  if (
    !isRequired &&
    typeof question.required_if === 'object' &&
    question.question_type !== 'HEADING'
  ) {
    return null;
  }

  switch (question.question_type) {
    case 'HEADING':
      return (
        <Typography
          key={question.id}
          variant="h6"
          fontWeight={question.is_sub ? 300 : 600}
        >
          {question.translations[0].title}
        </Typography>
      );
    case 'YES_NO':
      return (
        <QuestionYesOrNo
          key={question.id}
          question={question}
          translation={options}
          questionIndex={questionIndex}
          showUpload={showUpload}
          isDocumentRequired={isDocumentRequired}
          isRequired={isRequired}
          showError={doCheck}
          currentSection={currentSection}
          noDivider={noDivider}
        >
          {question.translations[0].title}
        </QuestionYesOrNo>
      );
    case 'SINGLE_CHOICE':
      return (
        <QuestionSingleChoice
          key={question.id}
          question={question}
          questionIndex={questionIndex}
          options={options}
          showUpload={showUpload}
          isDocumentRequired={isDocumentRequired}
          isRequired={isRequired}
          showError={doCheck}
          currentSection={currentSection}
        />
      );
    case 'MULTIPLE_CHOICE':
      return (
        <QuestionMultipleChoice
          key={question.id}
          question={question}
          questionIndex={questionIndex}
          options={options}
          showUpload={showUpload}
          isDocumentRequired={isDocumentRequired}
          isRequired={isRequired}
          showError={doCheck}
          currentSection={currentSection}
        />
      );
    case 'TEXT':
      return (
        <QuestionText
          key={question.id}
          question={question}
          questionIndex={questionIndex}
          showUpload={showUpload}
          isDocumentRequired={isDocumentRequired}
          isRequired={isRequired}
          showError={doCheck}
          currentSection={currentSection}
        />
      );
    case 'PRODUCT_LIST':
      return (
        <QuestionProducts
          key={question.id}
          question={question}
          questionIndex={questionIndex}
          showUpload={showUpload}
          isDocumentRequired={isDocumentRequired}
          isRequired={isRequired}
          showError={doCheck}
          currentSection={currentSection}
        />
      );
    default:
      return null;
  }
}
