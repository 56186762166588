import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';

import colors from '../../../assets/colors';
import { useAuth } from '../../../stores/useAuth';

interface ISection {
  children: React.ReactNode;
  title: string;
  header: string;
  onEdit?: () => void;
  width?: number | string;
  alternate?: boolean;
  disableSectionEditButton: boolean;
}

export default function Section({
  children,
  title,
  header,
  onEdit,
  width,
  alternate,
  disableSectionEditButton,
}: ISection) {
  const { t } = useTranslation();
  const { auth } = useAuth();
  return (
    <Box marginTop={4} className="saq_section_container">
      <Typography
        variant="h5"
        sx={{
          color: colors.lightGrey,
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          backgroundColor: colors.white,
          border: '1px solid rgba(190, 190, 190, 0.5)',
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          marginTop: 2,
          width: width || 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
            borderBottom: '1px solid rgba(190, 190, 190, 0.5)',
          }}
        >
          <Box>
            <Typography variant="overline">{header}</Typography>
          </Box>
          {!auth.user?.is_admin && (
            <Box>
              <Button disabled={disableSectionEditButton} onClick={onEdit}>
                {t('edit')}
              </Button>
            </Box>
          )}
        </Box>
        <Box
          sx={
            alternate
              ? {
                  '>div': {
                    '&:nth-child(even)': {
                      backgroundColor: colors.greyRow,
                    },
                  },
                }
              : {}
          }
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}
