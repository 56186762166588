import React, { useEffect, useRef, useState } from 'react';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Button, Typography } from '@mui/material';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';

import LoadingWrapper from '../../../../components/LoadingWrapper';
import { ESAQStatuses } from '../../../../entities/SAQ';
import { ISupplierDetails } from '../../../../entities/SupplierDetails';
import { useSAQ } from '../../../../stores/useSAQ';
import formatDateBasedOnBrowser from '../../../../utils/formatDateBasedOnBrowser';
import Questions from '../../../SAQOverview/components/Questions';
import SupplierDetails from '../../../SAQOverview/components/SupplierDetails';
import Terms from '../../../Terms';

interface ISAQContent {
  detailsData: ISupplierDetails;
  reload: () => void;
}

const a4Proportion = 1.4;

export default function SAQContent({ detailsData, reload }: ISAQContent) {
  const pdfButtonRef = useRef<HTMLDivElement>(null);
  const { saq, setSAQ } = useSAQ();
  const [pdfLoading, setPdfLoading] = useState(false);
  const [downloadPDFEffect, setDownloadPDFEffect] = useState(false);

  const getPages = (display: string) => {
    const container = document.querySelector('#saq_content');
    const elements = document.getElementsByClassName(
      'saq_section_container',
    ) as unknown as HTMLElement[];
    if (container) {
      const pageHeight = container.clientWidth * a4Proportion;
      const pages = [];
      let page: HTMLElement[] = [];
      let currentPageSize = 0;
      Array.from(elements).forEach((element) => {
        if (currentPageSize + element.clientHeight > pageHeight) {
          pages.push(page);
          page = [];
          currentPageSize = 0;
        }
        page.push(element);
        currentPageSize += element.clientHeight;
        // eslint-disable-next-line no-param-reassign
        element.style.display = display;
      });
      pages.push(page);
      return pages;
    }
    return [];
  };

  const setPageElementsDisplay = (page: HTMLElement[], display: string) => {
    page.forEach((element) => {
      // eslint-disable-next-line no-param-reassign
      element.style.display = display;
    });
  };

  const createPDF = (pages: HTMLElement[][], pdf: JsPDF) => {
    setPageElementsDisplay(pages[0], 'block');
    html2canvas(document.querySelector('#saq_content')!).then((canvas) => {
      const dataURL = canvas.toDataURL('image/jpeg');
      const img = new Image();
      img.onload = () => {
        const padding = 0;
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        let imageWidth = pageWidth;
        let imageHeight = img.height * (pageWidth / img.width);
        if (imageHeight > pageHeight) {
          imageWidth = img.width * (pageHeight / img.height);
          imageHeight = pageHeight;
        }
        pdf.addImage(
          dataURL,
          'JPEG',
          padding,
          padding,
          imageWidth,
          imageHeight,
          '',
          'MEDIUM',
        );
        if (pages.length > 1) {
          setPageElementsDisplay(pages[0], 'none');
          pages.shift();
          pdf.addPage();
          createPDF(pages, pdf);
        } else {
          pdf.save(`${detailsData?.general_name} SAQ.pdf`);
          getPages('block');
          if (pdfButtonRef.current) {
            pdfButtonRef.current.style.display = 'block';
          }
          reload();
          setPdfLoading(false);
        }
      };
      img.src = dataURL;
    });
  };

  const generatePDF = () => {
    const pages = getPages('none');
    const pdf = new JsPDF();
    if (pdfButtonRef.current) {
      pdfButtonRef.current.style.display = 'none';
    }
    if (pages.length) {
      setPdfLoading(true);
      createPDF(pages, pdf);
    }
  };
  useEffect(() => {
    if (downloadPDFEffect) {
      setDownloadPDFEffect(false);
      generatePDF();
    }
  }, [saq, downloadPDFEffect, setDownloadPDFEffect, generatePDF]);
  const checkPages = () => {
    const container = document.querySelector('#saq_content');
    const elements = document.getElementsByClassName(
      'saq_section_container',
    ) as unknown as HTMLElement[];
    if (container) {
      const pageHeight = container.clientWidth * a4Proportion;
      const biggers: { title?: string | null; timesBigger: number }[] = [];
      Array.from(elements).forEach((element) => {
        if (element.clientHeight > pageHeight) {
          biggers.push({
            title: element.firstChild?.textContent,
            timesBigger: element.clientHeight / pageHeight,
          });
        }
      });
      if (biggers.length) {
        const tempSAQ = { ...saq };
        const sections: {
          index: number;
          biggerData: { title?: string | null; timesBigger: number };
        }[] = [];
        tempSAQ.sections.forEach((section, index) => {
          const biggerData = biggers.find(
            (bigger) => bigger.title === section.translations[0].title,
          );
          if (biggerData) {
            sections.push({
              index,
              biggerData,
            });
          }
        });
        let addIndex = 0;
        sections.forEach((section) => {
          let { timesBigger } = section.biggerData;
          while (timesBigger > 1) {
            const questionsLength =
              tempSAQ.sections[section.index + addIndex].questions.length;
            const indexToStartRemoving = Math.ceil(
              questionsLength / timesBigger,
            );
            const removedQuestions =
              tempSAQ.sections[section.index + addIndex].questions.splice(
                indexToStartRemoving,
              );
            const newSection = {
              ...tempSAQ.sections[section.index + addIndex],
            };
            if (removedQuestions.length) {
              newSection.questions = removedQuestions;
              newSection.id += Math.floor(Math.random() * 100);
              addIndex += 1;
              tempSAQ.sections.splice(section.index + addIndex, 0, newSection);
            }
            timesBigger -= 1;
          }
        });
        setDownloadPDFEffect(true);
        setSAQ(tempSAQ);
      } else {
        generatePDF();
      }
    }
  };

  return (
    <LoadingWrapper loading={pdfLoading}>
      <Box id="saq_content" padding={1}>
        <Box className="saq_section_container">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="title">Completed SAQ</Typography>
              <Typography>
                Please review the answers before approving
              </Typography>
            </Box>
            <Box ref={pdfButtonRef}>
              <Button variant="outlined" onClick={checkPages}>
                <FileDownloadIcon color="primary" sx={{ mr: 1 }} />
                Download
              </Button>
            </Box>
          </Box>
        </Box>
        <SupplierDetails />
        <Questions />
        <Terms isOverview />
        {saq.status === ESAQStatuses.REJECTED && (
          <Box m={2}>
            {`${saq.status} on ${formatDateBasedOnBrowser(
              saq.checked_date,
            )} by ${saq.checked_by}`}
          </Box>
        )}
        {saq.status === ESAQStatuses.APPROVED && (
          <Box m={2}>
            {`${saq.status} on ${formatDateBasedOnBrowser(
              saq.date_approved,
            )} by ${saq.checked_by}`}
          </Box>
        )}
      </Box>
    </LoadingWrapper>
  );
}
