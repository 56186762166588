import React, { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Checkbox,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

import Container from '../../components/Container';
import LoadingWrapper from '../../components/LoadingWrapper';
import Paper from '../../components/Paper';
import { PATHS } from '../../config/paths';
import { ISAQ } from '../../entities/SAQ';
import { useQuestions, useSaveAcceptances } from '../../services/saq';
import { useAuth } from '../../stores/useAuth';
import { useFeedback } from '../../stores/useFeedback';
import { useSAQ } from '../../stores/useSAQ';
import formatDateBasedOnBrowser from '../../utils/formatDateBasedOnBrowser';

interface ITerms {
  isOverview?: boolean;
  hideSubmittedDate?: boolean;
}

export default function Terms({
  isOverview = false,
  hideSubmittedDate = false,
}: ITerms) {
  const { auth } = useAuth();
  const { t } = useTranslation();
  const { saq, setSAQ } = useSAQ();
  const navigate = useNavigate();
  const { setAlert } = useFeedback();

  const { mutate: mutateQuestion, isLoading: questionsLoading } = useQuestions({
    onSuccess: (response) => {
      setSAQ(response);
    },
  });

  const { mutate: mutateAcceptances, isLoading: acceptancesLoading } =
    useSaveAcceptances({
      onSuccess: (response, { next }) => {
        if (next) {
          navigate(PATHS.OVERVIEW);
        }
      },
      onError: (error) => {
        setAlert({
          show: true,
          title: 'All fields must be filled',
          message:
            'Please, review all the fields and make sure they are filled and accepted.',
        });
      },
    });

  useEffect(() => {
    if (!isOverview) {
      mutateQuestion({
        supplierId: auth.user?.active_supplier_details?.id,
        language: auth.user?.language,
      });
    }
    // Removed exhaustive-deps to avoid infinite loops.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user?.active_supplier_details?.id, auth.user?.language, isOverview]);

  const save = (next = false) => {
    const data = {
      completed_by_name: saq.completed_by_name,
      completed_by_position: saq.completed_by_position,
    };

    saq.user_acceptance_statements.forEach((acceptance) => {
      // @ts-ignore
      data[`${acceptance.statement_type.toLowerCase()}_accepted`] =
        // @ts-ignore
        saq[`${acceptance.statement_type.toLowerCase()}_accepted`];
    });
    mutateAcceptances({
      saqId: saq.id,
      data,
      next,
    });
  };

  const changeSection = (type: string) => {
    if (type === 'next') {
      save(true);
    } else {
      navigate(PATHS.SAQ);
    }
  };

  const showClauses = () => {
    return saq.terms_of_supply[0]?.clauses
      .split('|')
      .filter((clause) => clause)
      .map((clause, index) => <li key={clause}>{clause}</li>);
  };

  const changeAcceptance = (
    e: ChangeEvent<HTMLInputElement>,
    acceptance: string,
  ) => {
    const tempSaq = { ...saq };
    // @ts-ignore
    tempSaq[`${acceptance}_accepted`] = e.target.checked;
    setSAQ(tempSaq);
  };

  const changeTextFields = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    key: keyof ISAQ,
  ) => {
    setSAQ({ ...saq, [key]: e.target.value });
  };

  const showAcceptances = () => {
    return saq.user_acceptance_statements.map((acceptance) => (
      <Box key={acceptance.statement_type}>
        <FormControlLabel
          control={
            <Checkbox
              checked={
                // @ts-ignore
                saq[`${acceptance.statement_type.toLowerCase()}_accepted`]
              }
              onChange={
                !isOverview
                  ? (e) => {
                      changeAcceptance(
                        e,
                        acceptance.statement_type.toLowerCase(),
                      );
                    }
                  : () => false
              }
              name={acceptance.statement_type.toLowerCase()}
            />
          }
          label={acceptance.title}
        />
      </Box>
    ));
  };

  return isOverview ? (
    <Box paddingTop={2} className="saq_section_container">
      <Paper width={57}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="pagetitle" textAlign="center" flex={1}>
            {saq.terms_of_supply[0]?.title}
          </Typography>
        </Box>
        <Divider sx={{ marginTop: 2 }} />
        <Box display="flex" flexDirection="column" gap={1}>
          <ol>{showClauses()}</ol>
        </Box>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        <Box display="flex" flexDirection="column" gap={1}>
          {showAcceptances()}
        </Box>
        <Box display="flex" flexDirection="column" width="50%">
          <TextField
            variant="standard"
            value={saq.completed_by_name || ''}
            onChange={(e) => changeTextFields(e, 'completed_by_name')}
            label={t('name')}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            variant="standard"
            value={saq.completed_by_position || ''}
            onChange={(e) => changeTextFields(e, 'completed_by_position')}
            label={t('position')}
            InputProps={{
              readOnly: true,
            }}
          />
          {hideSubmittedDate ? null : (
            <TextField
              variant="standard"
              value={
                saq.date_submitted
                  ? formatDateBasedOnBrowser(saq.date_submitted)
                  : ''
              }
              label="Date Submitted"
              disabled
            />
          )}
        </Box>
      </Paper>
    </Box>
  ) : (
    <LoadingWrapper loading={questionsLoading || acceptancesLoading}>
      <Container paddingTop={2}>
        <Paper width={50}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="pagetitle" textAlign="center" flex={1}>
              {saq.terms_of_supply[0]?.title}
            </Typography>
          </Box>
          <Divider sx={{ marginTop: 2 }} />
          <Box display="flex" flexDirection="column" gap={1}>
            <ol>{showClauses()}</ol>
          </Box>
          <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
          <Box display="flex" flexDirection="column" gap={1}>
            {showAcceptances()}
          </Box>
          <Box display="flex" flexDirection="column" width="50%">
            <TextField
              variant="standard"
              value={saq.completed_by_name || ''}
              onChange={(e) => changeTextFields(e, 'completed_by_name')}
              label={t('name')}
            />
            <TextField
              variant="standard"
              value={saq.completed_by_position || ''}
              onChange={(e) => changeTextFields(e, 'completed_by_position')}
              label={t('position')}
            />
            {hideSubmittedDate ? null : (
              <TextField
                variant="standard"
                value={
                  saq.date_submitted
                    ? formatDateBasedOnBrowser(saq.date_submitted)
                    : ''
                }
                label="Date"
                disabled
              />
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            margin="20px 0"
            data-testid="buttons-container"
          >
            <Button variant="gradient" color="secondary" onClick={() => save()}>
              {t('save')}
            </Button>
            <Box display="flex" gap={3}>
              <Button
                variant="gradient"
                color="secondary"
                onClick={() => changeSection('back')}
              >
                {t('back')}
              </Button>
              <Button variant="gradient" onClick={() => changeSection('next')}>
                {t('next')}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
    </LoadingWrapper>
  );
}
