export interface IQuestionsTranslations {
  description: string;
  options: string;
  title: string;
}

type IJsonAnswer = {
  (allergens?: string): boolean;
  Yes?: boolean;
  No?: boolean;
};

export interface IQuestionsAnswers {
  id?: number;
  boolean_answer?: boolean | null;
  json_answer?: IJsonAnswer[] | null;
  question: number | null;
  text_answer?: string | null;
}

export interface IRequiredIf {
  boolean_value?: boolean;
  json_value?: any;
  question: number;
}

export interface IComments {
  comment: string;
  id?: number;
  supplier_saq_question?: number;
  resolved?: boolean;
  fake?: boolean;
}

export interface IQuestion {
  answers: any[];
  document_upload: boolean;
  documents_required_if: {
    json_value: IJsonAnswer | null;
    boolean_value: boolean | null;
  };
  id: number;
  is_sub: boolean;
  is_required: boolean;
  required_if?: IRequiredIf;
  question_type: string;
  sequence: number;
  translations: IQuestionsTranslations[];
  documents?: any;
  comments: IComments[];
}

export interface ITranslations {
  title: string;
  description?: string;
}

export interface ISAQSections {
  id: number;
  sequence: number;
  translations: ITranslations[];
  questions: IQuestion[];
}

export interface ITerms {
  title: string;
  language: string;
  clauses: string;
}

export interface IAcceptances {
  title: string;
  language: string;
  statement_type: string;
}

export enum ESAQStatuses {
  INCOMPLETE = 'INCOMPLETE',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  NOT_STARTED = 'NOT_STARTED',
}

export interface ISAQ {
  id: number;
  status?: ESAQStatuses;
  supplier: string;
  date_started?: string;
  sections: ISAQSections[];
  translations: ITranslations[];
  terms_of_supply: ITerms[];
  user_acceptance_statements: IAcceptances[];
  completed_by_name?: string;
  completed_by_position?: string;
  date_submitted?: string;
  checked_date: string;
  date_approved: string;
  checked_by: string;
}
