import React from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';

import { Box, TextField, Typography } from '@mui/material';

import { useGetSections } from './helpers';

type IControl = {
  control: Control<FieldValues>;
};

const DisplayDetailsQuestion = ({ control }: IControl) => {
  const sections = useGetSections();
  return (
    <>
      {sections.map((section) => (
        <Box
          key={section.title}
          display="flex"
          flexDirection="column"
          width="25rem"
          gap={2}
        >
          <Typography variant="title">{section.title}</Typography>
          <Box
            display="flex"
            flexDirection="column"
            style={{ marginBottom: 24 }}
          >
            {section.questions.map((question) => (
              <Controller
                name={question.name}
                key={question.id}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    style={{ marginBottom: 16 }}
                    {...field}
                    id={question.id}
                    label={question.label}
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            ))}
          </Box>
        </Box>
      ))}
    </>
  );
};
export default DisplayDetailsQuestion;
