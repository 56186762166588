import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import colors from '../../assets/colors';
import Container from '../../components/Container';
import LoadingWrapper from '../../components/LoadingWrapper';
import { ProfileCard } from '../../components/ProfileCard';
import { PATHS } from '../../config/paths';
import { useGetSAQStatus } from '../../services/saq';
import { useAuth } from '../../stores/useAuth';

function Home() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { isLoading, data: saqStatus } = useGetSAQStatus();

  useEffect(() => {
    if (auth.user?.is_admin) {
      navigate(PATHS.ADMIN);
    }
  }, [auth, navigate]);

  return (
    <LoadingWrapper
      loading={!auth?.user?.active_supplier_details?.name || isLoading}
      doNotShowContent={!auth?.user?.active_supplier_details?.name}
    >
      <Box sx={{ backgroundColor: colors.background, height: '100vh' }}>
        <Container paddingTop={10}>
          <Box display="flex" justifyContent="center" gap={2}>
            <ProfileCard
              userName={auth?.user?.active_supplier_details?.name}
              saqStatus={saqStatus?.status!}
              saqCategoryName={saqStatus?.category_name}
              userAddress={auth?.user?.email}
            />
          </Box>
        </Container>
      </Box>
    </LoadingWrapper>
  );
}

export default Home;
