import React from 'react';

import { Modal as MUIModal, Fade, Box } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';

import colors from '../../assets/colors';

type ModalType = {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  timeout?: number;
  height?: number;
};

export default function Modal({
  open,
  onClose,
  children,
  timeout,
  height,
}: ModalType) {
  return (
    <MUIModal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={open}
      onClose={onClose}
      disableEnforceFocus
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout }}
    >
      <Fade in={open}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            border: `1px solid ${colors.disabledGrey}`,
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'relative',
            backgroundColor: colors.background,
            height: height ? `${height}px` : 'auto',
            borderRadius: 2,
            padding: 2,
            paddingLeft: 3,
            paddingRight: 3,
          }}
        >
          {children}
        </Box>
      </Fade>
    </MUIModal>
  );
}
