import { initReactI18next } from 'react-i18next';

import i18next from 'i18next';

import EN_GB from './locales/EN_GB';
import EN_US from './locales/EN_US';
import ES_ES from './locales/ES_ES';
import FR_FR from './locales/FR_FR';
import IT_IT from './locales/IT_IT';

export type ITranslate = {
  // Details Page
  supplierDetails: string;
  technicalContact: string;
  productRecallWithdrawalContact: string;
  supplierName: string;
  siteAddress: string;
  email: string;
  sitePhoneNumber: string;
  name: string;
  contactNumber: string;
  outOfHoursContactNumber: string;
  // Drawer
  home: string;
  notifications: string;
  Documents: string;
  Suppliers: string;
  products: string;
  settings: string;
  logout: string;
  // Home Page
  profile: string;
  productList: string;
  listEmpty: string;
  completeFormText: string;
  defaultSAQText: string;
  incompleteSAQText: string;
  pendingSAQText: string;
  awaitingApprovalSAQText: string;
  categoryHasBeen: string;
  // SAQ
  save: string;
  progressSaved: string;
  start: string;
  continue: string;
  review: string;
  view: string;
  next: string;
  back: string;
  tryAgain: string;
  submit: string;
  section: string;
  of: string;
  categoryText1: string;
  categoryText2: string;
  categoryText3: string;
  certificate: string;
  fileNotAccepted: string;
  fileTooBig: string;
  errorTitle: string;
  required: string;
  requiredDocument: string;
  unansweredQuestions: string;
  reviewAnswers: string;
  details: string;
  questions: string;
  edit: string;
  tryLater: string;
  uploadNewDocument: string;
  uploadModalInfo: string;
  documentName: string;
  documentType: string;
  uploadedDate: string;
  expiryDate: string;
  noExpiryDate: string;
  cancel: string;
  supplierAssessmentQuestionnaire: string;
  saq: string;
  uploadedDocumentation: string;
  notBiggerThan: string;
  formatsAre: string;
  completeSAQ: string;
  continueSAQInfo: string;
  completedSAQ: string;
  completedSAQInfo: string;
  position: string;
  add: string;
  edited: string;
  reviewText: string;
  reviewComments: string;
  done: string;
  submitted: string;
  ok: string;
  completeYourQuestionnaire: string;
  incompleteText: string;
  rejected: string;
  rejectedText: string;
  approved: string;
  approvedText: string;
  awaitingApproval: string;
  awaitingApprovalText: string;
};

const resources = {
  EN_GB: {
    translation: EN_GB,
  },
  EN_US: {
    translation: EN_US,
  },
  ES_ES: {
    translation: ES_ES,
  },
  FR_FR: {
    translation: FR_FR,
  },
  IT_IT: {
    translation: IT_IT,
  },
};

const defaultLanguage = 'EN_GB';

const previousLanguage = localStorage.getItem('language');

i18next.use(initReactI18next).init({
  lng: previousLanguage || defaultLanguage,
  resources,
});

i18next.on('languageChanged', (lng) => {
  if (!Object.keys(resources).find((language) => language === lng)) {
    i18next.changeLanguage(defaultLanguage);
  } else {
    localStorage.setItem('language', lng);
  }
});

export default i18next;
