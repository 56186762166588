import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { ISupplierFilters } from '../entities/SupplierFilters';

interface ISupplierFiltersState {
  filters: ISupplierFilters;
  setFilters: (data: ISupplierFilters) => void;
}

const storeName = 'suplierFilters';
const initialSupplierFilters: ISupplierFilters = {
  regions: '',
  supplier_statuses: '',
  saq_statuses: '',
  categories: '',
  search: '',
  page: 1,
  apply: false,
};

export const useSupplierFilters = create<ISupplierFiltersState>()(
  devtools(
    (set) => ({
      filters: initialSupplierFilters,
      setFilters: (filters) => {
        set((state) => ({ ...state, filters }));
      },
    }),
    { name: storeName },
  ),
);
