import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';

import AdminDashboard from '../components/AdminDashboard';
import { PATHS } from '../config/paths';
import AdminSuppliersList from '../pages/Admin/Suppliers/List';
import AdminSupplierSAQ from '../pages/Admin/Suppliers/SAQ';
import Category from '../pages/Category';
import SupplierDetails from '../pages/details';
import Document from '../pages/Document';
import ForgotPassword from '../pages/ForgotPassword';
import Home from '../pages/Home';
import Login from '../pages/Login';
import NewPassword from '../pages/NewPassword';
import SAQ from '../pages/SAQ';
import SAQOverview from '../pages/SAQOverview';
import SucessfulSolicited from '../pages/SucessfulSolicited';
import Terms from '../pages/Terms';
import { useAuth } from '../stores/useAuth';
import { checkAuth } from '../stores/utils/auth';

function AppRouter() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const pagesAccessibleWithoutLoggedIn = [
      PATHS.ROOT,
      PATHS.RESET_PASSWORD,
      PATHS.SET_PASSWORD,
      PATHS.SUCCESSFUL_SOLICITED,
      PATHS.FORGOT_PASSWORD,
    ];
    if (checkAuth(auth)) {
      if (location.pathname === PATHS.ROOT) {
        if (auth.user?.is_admin) {
          navigate(PATHS.ADMIN);
        } else {
          navigate(PATHS.HOME);
        }
      }
    } else if (
      /*
        If the accessed page didn't be included in the array list
        it gonna thrown the user to the root page.
      */
      !pagesAccessibleWithoutLoggedIn.some((page) =>
        // Splits the pathname in '/' and selects only the name.
        page.includes(location.pathname.split('/')[1]),
      )
    ) {
      navigate(PATHS.ROOT);
    }
  }, [auth, location.pathname, navigate]);

  return (
    <Routes>
      <Route path={PATHS.ROOT} element={<Login />} />
      <Route path={PATHS.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route
        path={PATHS.SUCCESSFUL_SOLICITED}
        element={<SucessfulSolicited />}
      />
      <Route
        path={`${PATHS.RESET_PASSWORD}/:user_id/:token`}
        element={<NewPassword />}
      />
      <Route
        path={`${PATHS.SET_PASSWORD}/:user_id/:token`}
        element={<NewPassword />}
      />
      <Route path={PATHS.HOME} element={<Home />} />
      <Route path={PATHS.CATEGORY} element={<Category />} />
      <Route path={PATHS.SAQ} element={<SAQ />} />
      <Route path={PATHS.DOCUMENT} element={<Document />} />
      <Route path={PATHS.DETAILS} element={<SupplierDetails />} />
      <Route path={PATHS.OVERVIEW} element={<SAQOverview />} />
      <Route path={PATHS.TERMS} element={<Terms hideSubmittedDate />} />
      <Route path={PATHS.ADMIN} element={<AdminDashboard />} />
      <Route path={PATHS.SUPPLIERS_LIST} element={<AdminSuppliersList />} />
      <Route path={PATHS.ADMIN_SUPPLIER_SAQ} element={<AdminSupplierSAQ />} />
    </Routes>
  );
}

export default AppRouter;
