import { ApiErrorDefaultResponse } from '../libs/axios/interceptors';

export function getFirstErrorMessage(error: ApiErrorDefaultResponse | null) {
  if (!error) {
    return null;
  }
  if (typeof error === 'string') {
    return error;
  }

  const errorData = error?.response?.data || {};
  const firstErrorMessage = errorData.error ?? Object.values(errorData)[0]?.[0];

  return firstErrorMessage;
}
