import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';

import colors from '../../assets/colors';
import Container from '../../components/Container';
import LoadingWrapper from '../../components/LoadingWrapper';
import Paper from '../../components/Paper';
import { PATHS } from '../../config/paths';
import { ESAQStatuses } from '../../entities/SAQ';
import { useQuestions, useSubmitSaq } from '../../services/saq';
import { useAuth } from '../../stores/useAuth';
import { useFeedback } from '../../stores/useFeedback';
import { useSAQ } from '../../stores/useSAQ';
import Terms from '../Terms';
import Questions from './components/Questions';
import SupplierDetails from './components/SupplierDetails';

export default function SAQOverview() {
  const { setAlert } = useFeedback();
  const { auth } = useAuth();
  const { saq, setSAQ } = useSAQ();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate: mutateQuestion, isLoading: questionsLoading } = useQuestions({
    onSuccess: (response) => {
      setSAQ(response);
    },
  });

  const { mutateAsync: mutateSubmit, isLoading: isLoadingSubmit } =
    useSubmitSaq({
      onSuccess: (data) => {
        if (data.status === ESAQStatuses.AWAITING_APPROVAL) {
          navigate(PATHS.HOME);
        }
      },
      onError: (error) => {
        if (error?.response?.data?.incomplete_questions?.length) {
          setAlert({
            show: true,
            title: t('unansweredQuestions'),
            message: t('reviewAnswers'),
          });
        } else {
          setAlert({
            show: true,
            title: t('errorTitle'),
            message: t('tryLater'),
          });
        }
      },
    });

  useEffect(() => {
    mutateQuestion({
      supplierId: auth.user?.active_supplier_details.id,
      language: auth.user?.language,
    });
  }, []);

  const handleSubmit = () => {
    mutateSubmit({ id: saq.id });
  };

  return (
    <LoadingWrapper
      loading={!saq.sections || isLoadingSubmit || questionsLoading}
      doNotShowContent={!saq.sections}
    >
      <Container>
        <Paper width={60} marginTop={4} marginBottom={4}>
          <Typography variant="title">SAQ</Typography>
          <Typography>{t('reviewAnswers')}</Typography>
          <SupplierDetails />
          <Questions />
          <Terms isOverview hideSubmittedDate />
        </Paper>
        <Box
          position="fixed"
          bottom={0}
          width="100%"
          display="flex"
          justifyContent="center"
          p={1}
          borderTop={`1px solid ${colors.disabledGrey}`}
          sx={{ backgroundColor: 'rgba(255,255,255, 0.8)' }}
        >
          <Button variant="gradient" onClick={handleSubmit}>
            {t('submit')}
          </Button>
        </Box>
      </Container>
    </LoadingWrapper>
  );
}
