import React from 'react';

import { Box, Typography } from '@mui/material';

import colors from '../../../assets/colors';

interface IDetails {
  name?: string;
  value?: string;
}

export default function Details({ name, value }: IDetails) {
  return (
    <Box>
      <Typography variant="body2" sx={{ color: colors.text }}>
        {name}
      </Typography>
      <Typography variant="body1">{value}</Typography>
    </Box>
  );
}
