import React from 'react';

import { Box } from '@mui/material';

import { useDeleteDocument } from '../../services/supplierDocuments';
import { useSAQ } from '../../stores/useSAQ';
import LoadingWrapper from '../LoadingWrapper';
import UploadedDocument from '../UploadedDocument';

interface IUploadedDocuments {
  questionIndex: number;
  currentSection: number;
}

export default function UploadedDocumentsSAQ({
  questionIndex,
  currentSection,
}: IUploadedDocuments) {
  const { saq, setSAQ } = useSAQ();
  const { mutate: mutateDeleteDocument, isLoading: documentDeleteIsLoading } =
    useDeleteDocument({
      onSuccess: (data, { index }) => {
        const tempSAQ = { ...saq };
        saq.sections[currentSection].questions[questionIndex].documents.splice(
          index,
          1,
        );
        setSAQ(tempSAQ);
      },
    });

  const deleteFileFromServer = (id: number, index: number) => {
    mutateDeleteDocument({ id, index });
  };

  const displayFiles = () => {
    const question = saq.sections[currentSection].questions[questionIndex];
    if (!question.documents) {
      return null;
    }
    return question.documents?.map(
      (document: { document_name: string; id: number }, index: number) => (
        <UploadedDocument
          key={document.id}
          name={document.document_name}
          remove={() => deleteFileFromServer(document.id, index)}
        />
      ),
    );
  };

  return (
    <LoadingWrapper loading={documentDeleteIsLoading}>
      <Box>{displayFiles()}</Box>
    </LoadingWrapper>
  );
}
