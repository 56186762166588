import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Box, Typography, TextField, Button } from '@mui/material';
import Cookies from 'js-cookie';
import * as yup from 'yup';

import colors from '../../assets/colors';
import LogoNatoora from '../../assets/icons/IconNatoora';
import Container from '../../components/Container';
import LoadingWrapper from '../../components/LoadingWrapper';
import { PATHS } from '../../config/paths';
import { useYupValidationResolver } from '../../hooks/useYupValidationResolver/index';
import i18n from '../../libs/i18n';
import { useLogin } from '../../services/auth';
import { useAuth } from '../../stores/useAuth';
import { getFirstErrorMessage } from '../../utils/errorResponseHandler';

interface IPayload {
  email: string;
  password: string;
}

const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .matches(
      /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
      'Please enter a valid email address.',
    )
    .required('Required'),
  password: yup.string().required('Required'),
});

export default function Login() {
  const { handleSubmit, control } = useForm<IPayload>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: useYupValidationResolver(loginValidationSchema),
  });
  const navigate = useNavigate();
  const { setAuth, logout } = useAuth();

  const { mutate, isLoading, error } = useLogin({
    onSuccess: (response) => {
      i18n.changeLanguage(response.user?.language);
      setAuth(response);
      navigate(PATHS.HOME);
    },
    onError: (err) => {
      logout();
      if (err.response) {
        localStorage.removeItem('authStore');
        const cookiesName = ['natoora', 'csrftoken', 'sessionid'];
        cookiesName.forEach((cookie) => {
          Cookies.set(cookie, cookie, { path: '/' });
          Cookies.remove(cookie, { path: '/' });
        });
      }
    },
  });

  const errorMessage = getFirstErrorMessage(error);

  return (
    <LoadingWrapper loading={isLoading}>
      <Container paddingTop={10}>
        <Box
          p={2}
          borderRadius={2}
          minWidth="522px"
          border={`1px solid ${colors.disabledGrey}`}
          bgcolor={colors.white}
        >
          <LogoNatoora height="45px" />
          <Typography variant="title">Sign in</Typography>
          <Typography variant="subtitle1">
            Using your e-mail and password
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit((data) => mutate(data))}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDirection="column"
            gap={3}
            py={3}
          >
            <Box
              display="flex"
              flexDirection="column"
              paddingBottom={2}
              gap={3}
              py={3}
              width="100%"
            >
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    id="email"
                    label="E-mail"
                    type="email"
                    autoComplete="e-mail"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    id="password"
                    label="Password"
                    type="password"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              {errorMessage && (
                <Typography variant="caption" color="error">
                  {errorMessage}
                </Typography>
              )}
              <Typography
                style={{
                  cursor: 'pointer',
                  fontSize: '14px',
                  paddingLeft: '5px',
                  color: colors.darkButtonBlue,
                }}
                onClick={() => navigate(PATHS.FORGOT_PASSWORD)}
              >
                Forgot Password?
              </Typography>
            </Box>
            <Button type="submit" variant="gradient">
              LOGIN
            </Button>
          </Box>
        </Box>
      </Container>
    </LoadingWrapper>
  );
}
