import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import colors from '../../assets/colors';
import IconHome from '../../assets/icons/IconHome';
import LogoutIcon from '../../assets/icons/IconLogOut';
// import IconNotification from '../../assets/icons/IconNotification';
import IconOrders from '../../assets/icons/IconOrders';
// import IconProduct from '../../assets/icons/IconProduct';
// import IconSetting from '../../assets/icons/IconSetting';
import IconSuppliers from '../../assets/icons/IconSuppliers';
import { PATHS } from '../../config/paths';
import { useAuth } from '../../stores/useAuth';

export default function CollapsibleMenu() {
  const { t } = useTranslation();
  const { auth, logout } = useAuth();
  const navigate = useNavigate();
  const [isClicked, setIsClicked] = useState(false);

  // Below lines are commented and not removed because they will be back later
  const drawerApplist = [
    {
      groupName: t('home'),
      icon: <IconHome />,
      url: PATHS.HOME,
      isAdmin: false,
    },
    // {
    //   groupName: t('notifications'),
    //   icon: <IconNotification />,
    //   url: '/',
    // },
    {
      groupName: t('Documents'),
      icon: <IconOrders />,
      url: PATHS.DOCUMENT,
      isAdmin: false,
    },
    {
      groupName: t('Suppliers'),
      icon: <IconSuppliers />,
      url: PATHS.SUPPLIERS_LIST,
      isAdmin: true,
    },
    // {
    //   groupName: t('products'),
    //   icon: <IconProduct />,
    //   url: '/details',
    // },
    // {
    //   groupName: t('settings'),
    //   icon: <IconSetting />,
    //   url: '/',
    // },
  ];

  const toggleDrawer = (event: React.KeyboardEvent) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setIsClicked(!isClicked);
  };

  const list = () => (
    <Box
      sx={{ width: 310 }}
      role="presentation"
      onClick={() => setIsClicked(!isClicked)}
      onKeyDown={toggleDrawer}
    >
      <Box sx={{ padding: 2, backgroundColor: colors.darkGrey }}>
        <Box
          sx={{
            width: '48px',
            height: '48px',
            display: 'flex',
            borderRadius: '100%',
            marginBottom: '10px',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: colors.lightGrey,
          }}
        >
          <PersonOutlineIcon sx={{ color: colors.white }} />
        </Box>
        <Typography variant="body1" color="secondary">
          {auth.user?.active_supplier_details?.name}
        </Typography>
        <Typography variant="body2" color="secondary">
          {auth.user?.email}
        </Typography>
      </Box>
      <List>
        {drawerApplist
          .filter((item) => item.isAdmin === auth.user?.is_admin)
          .map((item) => (
            <Link
              key={item.groupName}
              to={item.url}
              style={{ textDecoration: 'none', color: colors.black }}
            >
              <ListItem button key={item.groupName} sx={{ height: '46px' }}>
                <ListItemIcon sx={{ height: '40px' }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.groupName} />
              </ListItem>
            </Link>
          ))}
        <Divider />
        <ListItem
          button
          key="Log Out"
          sx={{ height: '46px' }}
          onClick={() => {
            logout();
            navigate(PATHS.ROOT);
          }}
        >
          {/* TODO: remove the styles */}
          <ListItemIcon sx={{ height: '40px' }}>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={t('logout')} />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <Button
        style={{ marginLeft: 8 }}
        color="secondary"
        onClick={() => setIsClicked(!isClicked)}
      >
        <MenuIcon />
      </Button>
      <Drawer anchor="left" open={isClicked} onClose={toggleDrawer}>
        {list()}
      </Drawer>
    </>
  );
}
